import {
  arrayOf as arrayOfType,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  params, extend
} from '@thd-nucleus/data-sources';

import { cartInfoLiteDataModel } from './GetCartDataModel';

export const PromoCartProviderDataModel = extend({ itemPromotionValidity: params({
  navParam: stringType().isRequired(),
  storeSkuIds: arrayOfType(stringType().isRequired()).isRequired(),
}).arrayOf(shapeType({
  storeSkuId: stringType(),
  eligible: boolType(),
})) }, cartInfoLiteDataModel
);
