export const LIKE = 'like';
export const DISLIKE = 'dislike';

export const QUERY_NAME = 'recs';

export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';
export const LIKE_CLICK = 'instant-recs-like.click';
export const DISLIKE_CLICK = 'instant-recs-dislike.click';

// INSTANT RECS
export const THUMBS_UP_GRAY = 'thumbs-up-gray.svg';
export const THUMBS_UP_ORANGE = 'thumbs-up-orange.svg';
export const THUMBS_DOWN = 'thumbs-down-gray.svg';
export const HOME_DEPOT_DESIGN_CENTER = 'HDDC';

export const POD_IMAGE_SIZE = 300;