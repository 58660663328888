import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function SsoRedirectForm({ ssoResponse }) {
  useEffect(() => {
    document.getElementById('ssoLogonForm').submit();
  }, []);

  return (
    <div>
      <form
        action={ssoResponse.url}
        method="POST"
        id="ssoLogonForm"
        name="ssoLogonForm"
      >
        <input name="siteId" type="hidden" value={ssoResponse.siteId} />
        <input name="langId" type="hidden" value="en_US" />
        <input name="partnerUserId" type="hidden" value={ssoResponse.svocId} />
        <input
          name="accessToken"
          type="hidden"
          value={ssoResponse.accessToken}
        />
        <input
          name="processIndicator"
          type="hidden"
          value={ssoResponse.processIndicator}
        />
        <input
          name="partnerLoginFailureCount"
          type="hidden"
          value={ssoResponse.loginFailureCount}
        />
        <input
          name="partnerRedirectUrl"
          type="hidden"
          value={ssoResponse.redirectURL}
        />
        <input name="guuId" type="hidden" value={ssoResponse.guuid} />
        {ssoResponse.grantType && (
          <input
            name="grant_type"
            type="hidden"
            value={ssoResponse.grantType}
          />
        )}
        {ssoResponse.clientId && (
          <input name="client_id" type="hidden" value={ssoResponse.clientId} />
        )}
        {ssoResponse.scope && (
          <input name="scope" type="hidden" value={ssoResponse.scope} />
        )}
      </form>
    </div>
  );
}

SsoRedirectForm.propTypes = {
  ssoResponse: PropTypes.object.isRequired, // eslint-disable-line
};

export default SsoRedirectForm;
