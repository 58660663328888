import { useMemo } from 'react';

import { useMessage } from './useMessage';
import { usePromoCart } from './usePromoCart';
import { usePromoModel } from './usePromoModel';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  DRAWER_TYPES,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  MESSAGE_KEY
} from '../utils/constants';
import { getProductImage } from '../utils/promo-utils';
import { usePromoDrawerModel } from './usePromoDrawerModel';

export const useBogoImages = () => {
  const {
    bogoSelectedProduct,
    bogoItemInCart,
    firstListCartItems,
    firstListCartQuantity,
    secondListCartItems,
    secondListCartQuantity,
  } = usePromoCart();
  const {
    anchorItem,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    subExperience
  } = usePromoModel();
  const { drawerView } = usePromoDrawerModel();
  const bogoQualifyingImageBottomText = useMessage(MESSAGE_KEY.bogoQualifyingImageBottomText);
  const bogoRewardImageBottomText = useMessage(MESSAGE_KEY.bogoRewardImageBottomText);

  return useMemo(() => {
    let qualifyingImages = [];
    let rewardImages = [];

    switch (subExperience) {
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
      qualifyingImages.push({
        url: getProductImage(anchorItem),
        itemId: anchorItem.itemId,
        text: bogoQualifyingImageBottomText,
      });

      if (bogoItemInCart) {
        rewardImages.push({
          url: bogoItemInCart.image,
          itemId: bogoItemInCart.itemId,
          text: bogoRewardImageBottomText,
        });
      } else if (bogoSelectedProduct) {
        rewardImages.push({
          url: getProductImage(bogoSelectedProduct),
          itemId: bogoSelectedProduct.itemId,
          text: bogoRewardImageBottomText,
        });
      } else {
        rewardImages.push({
          text: bogoRewardImageBottomText,
        });
      }
      break;
    }
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff: {
      firstListCartItems.forEach(({ itemId, image, quantity }) => {
        [...Array(quantity)].forEach(() => {
          qualifyingImages.push({
            imageType: 'qualify',
            itemId,
            text: bogoQualifyingImageBottomText,
            url: image,
          });
        });
      });

      if (qualifyingImages.length < src1EligibilityCriteria.minPurchaseQuantity) {
        // is Under Qualifying Threshold
        qualifyingImages.push({
          imageType: 'qualify',
          itemId: anchorItem.itemId,
          preview: true,
          previewCTA: false,
          text: bogoQualifyingImageBottomText,
          url: firstListCartQuantity === 0 && getProductImage(anchorItem),
        });
        while (qualifyingImages.length < src1EligibilityCriteria.minPurchaseQuantity) {
          qualifyingImages.push({
            imageType: 'qualify',
            text: bogoQualifyingImageBottomText,
          });
        }
      } else if (qualifyingImages.length > src1EligibilityCriteria.minPurchaseQuantity) {
        // qualifying Threshold is Met
        qualifyingImages = qualifyingImages.slice(0, src1EligibilityCriteria.minPurchaseQuantity);
      }

      secondListCartItems.forEach(({ itemId, image, quantity }) => {
        [...Array(quantity)].forEach(() => {
          rewardImages.push({
            imageType: 'reward',
            itemId,
            text: bogoRewardImageBottomText,
            url: image,
            preview: false,
            previewCTA: false,
          });
        });
      });

      if (rewardImages.length < tgt1EligibilityCriteria.minPurchaseQuantity) {
        // is Under Reward Threshold
        while (rewardImages.length < tgt1EligibilityCriteria.minPurchaseQuantity) {
          rewardImages.push({
            imageType: 'reward',
            text: bogoRewardImageBottomText,
          });
        }
      } else if (rewardImages.length > tgt1EligibilityCriteria.minPurchaseQuantity) {
        // reward Threshold is Met
        rewardImages = rewardImages.slice(0, tgt1EligibilityCriteria.minPurchaseQuantity);
      }

      if (
        firstListCartItems.length >= src1EligibilityCriteria.minPurchaseQuantity
        || drawerView === DRAWER_TYPES.PROMO_LIST_2_DRAWER
      ) {

        if (qualifyingImages.length > firstListCartQuantity) {
          // prevent error array out of bound for rewardImages
          if (firstListCartQuantity > 1) {
            // there is more than 1 reward selected
            qualifyingImages[firstListCartQuantity - 1].previewCTA = true;
          }
          if (firstListCartQuantity < qualifyingImages.length) {
            // under Reward Threshold
            qualifyingImages[firstListCartQuantity].previewCTA = false;
          }
        }

        // qualifying Threshold is Met in cart
        if (rewardImages.length > secondListCartQuantity) {
          // prevent error array out of bound for rewardImages
          if (secondListCartQuantity > 1) {
            // there is more than 1 reward selected
            rewardImages[secondListCartQuantity - 1].preview = false;
          }
          if (secondListCartQuantity < rewardImages.length) {
            // under Reward Threshold
            rewardImages[secondListCartQuantity].preview = true;
          }
        }
      }

      break;
    }
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
      rewardImages.push({
        url: getProductImage(anchorItem),
        itemId: anchorItem.itemId,
        text: bogoRewardImageBottomText,
      });

      if (bogoItemInCart) {
        qualifyingImages.push({
          url: bogoItemInCart.image,
          itemId: bogoItemInCart.itemId,
          text: bogoQualifyingImageBottomText,
        });
      } else if (bogoSelectedProduct) {
        qualifyingImages.push({
          url: getProductImage(bogoSelectedProduct),
          itemId: bogoSelectedProduct.itemId,
          text: bogoQualifyingImageBottomText,
        });
      } else {
        qualifyingImages.push({
          text: bogoQualifyingImageBottomText,
        });
      }
      break;
    }
    default: {
      break;
    }
    }

    return {
      qualifyingImages,
      rewardImages,
    };
  }, [
    drawerView,
    anchorItem,
    bogoItemInCart,
    bogoQualifyingImageBottomText,
    bogoRewardImageBottomText,
    bogoSelectedProduct,
    firstListCartItems,
    firstListCartQuantity,
    secondListCartItems,
    secondListCartQuantity,
    subExperience,
    src1EligibilityCriteria.minPurchaseQuantity,
    tgt1EligibilityCriteria.minPurchaseQuantity
  ]);
};
