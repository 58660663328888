import React from 'react';
import {
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import PriceFormat from './PriceFormat';
import InstantRebates from './InstantRebates';
import SavingsCenterPromos from './SavingsCenterPromos';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-simple.style.scss';
import { LAYOUT_TYPES } from '../../utils/utils';

export const PriceSimple = ({
  basic,
  channel,
  disableRangePricing,
  displayEachUom,
  hideBadge,
  hideBulkPrice,
  hideLimitPerOrder,
  hidePromotions,
  hideRebates,
  hideSavingsText,
  omitDetails,
  onSavingsCenterToggle,
  skuModel,
  zipCode,
  disableOuterSpacing,
  isClearance
}) => {
  const { pricing = {} } = skuModel || {};

  // BRIO Skus
  const handleBRIOSku = () => {
    const isCustomKitchenCabinetsSku = pricing?.category === 'Custom Kitchen Cabinets';
    const quoteMessage = isCustomKitchenCabinetsSku ? '' : 'Get a quote during a FREE design Consultation';
    const howToBuyDetails = `Home Depot Installation ${isCustomKitchenCabinetsSku ? 'Available' : 'Required'}`;
    return (
      <div className="brio__wrapper">
        <div className="brio__quote-message">
          {quoteMessage}
        </div>
        <div className="brio__how-to-buy-details">
          {howToBuyDetails}
        </div>
      </div>
    );
  };

  // HIDE_PRICE/LIVE_GOODS
  const handleHidePrice = () => <div className="price__contact-message">Contact your local store for price</div>;

  // Limit per order
  const handleLimitPerOrder = ({
    pricingValues, priceBadge, uom, save, price, hasEcoRebates,
    bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    if (!!pricing.quantityLimit) {
      return (
        <div className="limit-per-order price__qty-limit">
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="price__was-price">
            <div>
              {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
              {helpers.getWasPrice(pricingValues)}
            </div>
            {helpers.getSavingsText(false, hideSavingsText, save)}
          </div>
          {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
          {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
          {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'price__qty-limit')}
        </div>
      );
    }
    return '';
  }

  // Unit price regular, Unit price with strike through
  const handleUnitPrice = ({
    alternatePrice, alternateUom, uom, price, priceBadge,
    save, pricingValues, unitPricingValues, bulkPrice, bulkPriceThresholdQty, hasEcoRebates
  }) => {
    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    const { unitWasPrice, unitNowPrice } = pricing;
    const pricePerUnit = unitWasPrice || unitNowPrice;
    if (pricePerUnit) {
      const uomIsSqFt = uom === 'sq. ft.';
      const priceToDisplay = uomIsSqFt ? price : alternatePrice.toString();
      const wasPriceToDisplay = uomIsSqFt ? unitPricingValues : pricingValues;
      const showDollarOff = !uomIsSqFt;
      return (
        <div id="unit-case-price">
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="price__was-price">
            <span>
              {uomIsSqFt && (
                <>
                  <span>{uom && helpers.parseUom(uom, displayEachUom)}</span> (<span className="u__bold">{helpers.handlePriceParsing(unitPricingValues ? alternatePrice : pricing.nowPrice, false)}</span>
                  <span> {pricing.uom && helpers.parseUom(pricing.uom, displayEachUom)}</span>)
                </>
              )}
              {!uomIsSqFt && (
                <>
                  <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                  {pricing.unitsPerCase > 1 && (
                    <span>
                      (<span className="u__bold">{helpers.handlePriceParsing(price, false)}</span>
                      <span> {uom && helpers.parseUom(uom, displayEachUom)}</span>)
                    </span>
                  )}
                </>
              )}
              {wasPriceToDisplay && (
                <div>
                  {helpers.getWasPrice(wasPriceToDisplay)}
                  {helpers.getSavingsText(true, hideSavingsText, save, showDollarOff)}
                </div>
              )}
            </span>
          </div>
          {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
          {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'price__qty-limit')}
          {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
        </div>
      );
    }

    return (
      <div id="unit-price">
        {pricing.unitOfMeasureCoverage && (
          <div className="price__unit-cover">
            {pricing.unitOfMeasureCoverage} ({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})
          </div>
        )}
        <PriceFormat
          price={alternatePrice.toString()}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        {!pricingValues && <span className="priceFormat__unit-alignment"> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>}
        {pricingValues &&
          <div className="price__was-price">
            <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(false, hideSavingsText, save)}
          </div>
        }
        {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
        {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
      </div>
    );
  };

  // Was price
  const handleWasPrice = ({
    pricingValues, save, priceBadge, uom
  }) => {

    return (
      <div id="was-price">
        <PriceFormat
          price={pricingValues.nowPrice}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price__was-price">
          {uom && <span>{`${helpers.parseUom(uom, displayEachUom)} `}</span>}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
      </div>
    );
  };

  // Starting At price
  const handleStartingAt = ({
    pricingValues, save, price, priceBadge, uom
  }) => (
    <div id="start-at">
      <div className="price__start-at">Starting at</div>
      <div>
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
      </div>
    </div>
  );

  // Map price
  const handleMapPrice = ({
    mapPrice, pricingValues, uom, priceBadge, pricingMessage, hasEcoRebates
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
    let priceToDisplay = '';
    if (mapPrice) {
      priceToDisplay = mapPrice.wasPrice;
    } else if (pricingValues) {
      priceToDisplay = pricingValues.wasPrice;
    }
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    return (
      <div>
      <div className="price__map-price">
        {(mapPrice || pricingValues) && (
          <div>
            <span className="price__message">
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
                hasStrikeThrough
              />
            </span>
            {uom && <span className="price__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
          </div>
        )}
        <Button link contentClass="price__tooltip" >
          <Tooltip
            channel={channel}
            closeButton
            content={tooltipContent}
            maxWidth={300}
            placement="bottom"
          >
            <div id={tooltipId}>{pricingMessage}</div>
          </Tooltip>
        </Button>
      </div>
    {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.SIMPLE)}
  </div>
    );
  };

  // Bulk price
  const handleBulkPrice = ({
    price, priceBadge, uom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="bulk-price">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price__was-price">
          {uom && (
            <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
          )}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
        {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
      </div>
    );
  };

  // Instant Rebate
  const handleInstantRebate = ({
    price, priceBadge, uom, pricingValues
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="instant-rebate">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {helpers.getWasPrice(pricingValues)}
        </div>
        {!hideRebates && (
          <InstantRebates
            storePromotion={pricing?.storePromotion}
            price={pricing}
            channel={channel}
          />
        )}
      </div>
    );
  };

  // Range Price
  const handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, save
  }) => (
    <div id="range-price">
      <div>
        <PriceFormat
          price={minPrice}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <span className="u__bold price__range"> - </span>
        <PriceFormat
          price={maxPrice}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
      </div>
      <div>
        {helpers.getWasPriceRange(wasMinPrice, wasMaxPrice)}
        {helpers.getSavingsText(true, hideSavingsText, save, false)}
      </div>
    </div>
  );

  // Eco Rebate
  const handleEcoRebates = ({
    price, priceBadge, uom, pricingValues, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty,
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="eco-rebate-price">
        {(bulkPrice && !hideBulkPrice) && handleBulkPrice({
          price, priceBadge, uom, displayEachUom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && handleMapPrice({
          mapPrice, price, priceBadge, uom, displayEachUom, pricingMessage
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
            />
            <div className="price__was-price">
              {uom && (
                <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
              )}
              {helpers.getWasPrice(pricingValues)}
              {helpers.getSavingsText(false, hideSavingsText, save)}
            </div>
          </>
        )}
        {helpers.getRebates(hideRebates, true, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
      </div>
    );
  }

  // No details
  const handleNoDetailsPrice = ({
    price, uom, pricingValues, save, priceBadge
  }) => (
    <div id="no-details-price">
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      <div className="price__was-price">
        {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
        {helpers.getWasPrice(pricingValues)}
        {helpers.getSavingsText(false, hideSavingsText, save)}
      </div>
    </div>
  );

  // Standard price
  const handleStandardPrice = ({
    price, priceBadge, uom
  }) => (
    <div id="standard-price">
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      {uom && <span className="price__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
    </div>
  );

  // Basic price
  const handleBasicPrice = ({ price }) => (
    <PriceFormat
      basic
      price={price}
      disableOuterSpacing={disableOuterSpacing}
    />
  );

  const renderByType = ({ _price, type }) => {
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return handleStandardPrice(_price);
      case PRICE_TYPES.BASIC:
        return handleBasicPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return handleWasPrice(_price);
      case PRICE_TYPES.STARTING_AT:
        return handleStartingAt(_price);
      case PRICE_TYPES.MAP_PRICE:
        return handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return handleUnitPrice(_price, pricing);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return handleLimitPerOrder(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return handleRangePrice(_price);
      case PRICE_TYPES.BRIO:
        return handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return handleNoDetailsPrice(_price);
      default: return null;
    }
  }

  const savingCenterPromoMessage = helpers.getSavingCenterPromoMessage(pricing.savingsCenterPromos);

  const wrapperClass = classNames('price__wrapper', {
    'price__wrapper__no-padding': disableOuterSpacing,
    '!sui-pt-0': isClearance // remove 5px pd top seen when Online Price is displayed
  });

  return (
    <div className={wrapperClass} data-testid="price-simple">
      {renderByType(helpers.getPricingObject({
        skuModel, displayEachUom, omitDetails, basic, disableRangePricing
      }))}
      {(!skuModel.preferredPriceFlag && !!savingCenterPromoMessage && !hidePromotions) && (
        <SavingsCenterPromos
          itemId={pricing.itemId}
          channel={channel}
          onSavingsCenterToggle={onSavingsCenterToggle}
          savingCenterPromoMessage={savingCenterPromoMessage}
        />
      )}
    </div>
  );
}

PriceSimple.propTypes = {
  /**
   * To show the basic price format
   */
  basic: bool,
  /**
   * device type
   */
  channel: string.isRequired,
  /**
   * disables the display of range pricing often seen in generic sku product pods
   */
  disableRangePricing: bool,
  /**
   * disables the "each" uom treatment
   */
  displayEachUom: bool,
  /**
   * suppresses the display of badges, such as bulk pricing
   */
  hideBadge: bool,
  /**
   * disables bulk pricing messaging
   */
  hideBulkPrice: bool,
  /**
   * suppresses the display of limit per order text
   */
  hideLimitPerOrder: bool,
  /**
   * disables display of the short description for promotions
   */
  hidePromotions: bool,
  /**
   * Hide Savings Text
   */
  hideSavingsText: bool,
  /**
   * Hide Rebate Messaging and prevent Rebate Calls
   */
  hideRebates: bool,
  /**
   * to display only a very simplified view of the price
   */
  omitDetails: bool,
  /**
   * callback triggered when savings center tooltip link is clicked
   */
  onSavingsCenterToggle: func,
  /**
   * the transformed price data
   */
  skuModel: shape({
    pricing: shape({
      itemId: oneOfType([number, string]),
      uom: string,
      unitsPerCase: number,
      wasPrice: oneOfType([number, string]),
      nowPrice: oneOfType([number, string]),
      specialBuyPrice: oneOfType([number, string]),
      dollarOff: oneOfType([number, string]),
      percentageOff: oneOfType([number, string]),
      specialBuySavings: oneOfType([number, string]),
      specialBuyDollarOff: oneOfType([number, string]),
      alternatePriceDisplay: bool,
      savingsCenterPromos: string,
      quantityLimit: oneOfType([number, string]),
      unitWasPrice: oneOfType([number, string]),
      storePromotion: shape(),
      unitOfMeasureCoverage: string
    }).isRequired
  }).isRequired,
  /**
   * zipcode used by ecorebates
   */
  zipCode: string,
  disableOuterSpacing: bool,
  isClearance: bool
};

PriceSimple.defaultProps = {
  basic: false,
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideRebates: false,
  hideSavingsText: false,
  omitDetails: false,
  onSavingsCenterToggle: () => { },
  zipCode: null,
  disableOuterSpacing: false,
  isClearance: false
};

