import React from 'react';
import PropTypes from 'prop-types';
import createTypography from '../utils/createTypography';

/**
 * The `Typography` component makes it easy to apply a default set of font weights and
 * font sizes in your application through the variant and weight props.
 *
 * Related components: [Link](#link), [CardTitle](#cardtitle), [TileText](#tiletext).
 *
 * Usage:
 *
 * ```jsx
 * import { Typography } from '@one-thd/sui-atomic-components';
 * ```
 */
const Typography = React.forwardRef((props, ref) => {
  const {
    align = 'inherit',
    color = 'primary',
    className,
    component,
    decoration,
    height,
    lineClamp,
    paragraph,
    uppercase,
    truncate,
    variant,
    weight,
    ...other
  } = props;

  const typography = createTypography({
    align,
    color,
    decoration,
    height,
    uppercase,
    truncate,
    lineClamp,
    variant,
    weight
  });

  const Component = component || (paragraph ? 'p' : typography.element) || 'span';

  return (
    <Component
      className={typography.classes}
      ref={ref}
      {...other}
    />
  );
});

Typography.displayName = 'Typography';

Typography.propTypes = {
  /**
   * Set the text-align on the component.
   * @default 'inherit'
   */
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   */
  component: PropTypes.elementType,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Applies the typography color.
   * @default 'primary'
   */
  color: PropTypes.oneOf([
    'primary',
    'subtle',
    'info',
    'success',
    'warning',
    'danger',
    'brand',
    'inverse',
    'inactive',
    'none'
  ]),
  /**
   * Applies the text decoration styles.
   */
  decoration: PropTypes.oneOf([
    'line-through',
    'underline'
  ]),
  /**
   * Applies the line height styles.
   */
  height: PropTypes.oneOf([
    'none',
    'tight',
    'snug',
    'normal',
    'loose'
  ]),
  /**
   * If `true`, the element will be a paragraph element.
   * @default false
   */
  paragraph: PropTypes.bool,
  /**
   * Applies the weight sub type.
   */
  weight: PropTypes.oneOf([
    'bold',
    'display',
    'regular'
  ]),
  /**
   * Applies the typography styles.
   * @default 'body-base'
   */
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body-base',
    'body-lg',
    'body-small',
    'body-xs'
  ]),
  /**
   * if true applies uppercase text transformation.
   */
  uppercase: PropTypes.bool,
  /**
   * If true, truncates the overflow text at the first line
   * @default false
   */
  truncate: PropTypes.bool,
  /**
   * Truncates the text to this amount of lines.
   * It could be defined as a number, which will be appied on any breakpoint,
   * or as an object defining a different value per breakpoint: `{default: '1', md: '3', lg: '6'}`
   */
  lineClamp: PropTypes.oneOfType([
    PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
    PropTypes.shape({
      default: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
      sm: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
      md: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
      lg: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
      xl: PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6']),
      '2xl': PropTypes.oneOf(['none', '1', '2', '3', '4', '5', '6'])
    })
  ])
};

Typography.defaultProps = {};

export { Typography };
