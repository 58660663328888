import React, {
  createContext,
  useMemo,
} from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOf,
  shape,
  string,
  func
} from 'prop-types';

import { useDataModel } from '@thd-nucleus/data-sources';
import { useConfigService, useStore } from '@thd-nucleus/experience-context';
import { FEATURE_SWITCH_KEYS } from '../utils/constants';

import {
  buildRefinedNValue,
  hasCompatiblePromotion,
  transformResponseToContextValues
} from '../utils/promo-model-utils';
import {
  getItemIdsForEachList,
  isTwoListUi,
} from '../utils/promo-utils';
import { PromoModelProviderDataModel } from '../models/PromoModelProviderDataModel';
import { PromotionProductsLoader } from '../components/PromotionProductsLoader/PromotionProductsLoader';
import { isFulfillable } from '../utils/promo-fulfillment-utils';
import { useNewRelicLogging } from '../hooks/useNewRelicLogging';

export const defaultModelContext = {
  allListProductsAndAnchor: new Map(),
  analyticsComponent: '',
  analyticsSharedSection: '',
  anchorItem: null,
  applianceDeliveryStore: null,
  bogoMaxQuantity: 1,
  displayableFirstListItemIds: [],
  displayableSecondListItemIds: [],
  endDate: null,
  expDate: null,
  isAnchorFulfillable: false,
  isAnchorAppliance: false,
  isAwaitingData: false,
  isBackwardsBogo: false,
  isBmsm: false,
  isBogo: false,
  isDollarThresholdBogo: false,
  isExpiring: false,
  isForwardsB1gy: false,
  isForwardsBxg1: false,
  isForwardsBogo: false,
  isMsb: false,
  isMultiSelect: false,
  listHasFulfillment: false,
  longDesc: '',
  originalNvalue: null,
  refinedNvalue: null,
  rewardTiers: [{
    maxAllowedRewardAmount: 0,
    maxPurchaseQuantity: 0,
    minPurchaseAmount: 0,
    minPurchaseQuantity: 0,
    rewardAmountPerItem: 0,
    rewardAmountPerOrder: 0,
    rewardPercent: 0,
  }],
  shortDesc: '',
  src1EligibilityCriteria: {},
  src1MinPurchaseAmount: 0,
  startDate: null,
  experienceTag: '',
  subExperience: '',
  tgt1EligibilityCriteria: {},
  fromBogoCard: null,
};

export const PromoModelContext = createContext(defaultModelContext);

export const PromoModelProvider = ({
  anchorItem,
  hasUserInteracted,
  experienceTag,
  subExperienceTag,
  isMultiSelect,
  maAvailability,
  children,
  type,
  onFail,
}) => {
  const store = useStore();
  const rewardDrawerMaxLength = useConfigService(FEATURE_SWITCH_KEYS.rewardDrawerMaxLength) || 48;
  const showProgressOnLoad = !!useConfigService(FEATURE_SWITCH_KEYS.showProgressOnLoad);
  const isWaitingForMaAvailability = maAvailability?.isLoading;
  const { brandRefinementId } = anchorItem.pricing?.conditionalPromotions?.[0] || {}; // review chaining
  const isWaitingForAnchorItemId = !anchorItem.itemId;
  const { sendBogoCardErrorsToNewRelic } = useNewRelicLogging();
  const { storeId, storeZip, membershipInformation } = store;

  const {
    called: promotionProductsCalled,
    data: promotionProductsData,
    loading: promotionProductsLoading
  } = useDataModel('promotionProducts', {
    variables: {
      itemId: anchorItem.itemId,
      pageSize: rewardDrawerMaxLength,
      // experienceTag, // this is for MA multi-promo
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    skip: isWaitingForMaAvailability || isWaitingForAnchorItemId,
    ssr: false,
  });

  const isWaitingForPromotionProducts = promotionProductsCalled && promotionProductsLoading;
  const promotion = promotionProductsData?.promotionProducts?.promotions?.[0];
  const originalNvalue = promotion?.nvalues?.[0];
  const refinedNvalue = buildRefinedNValue(originalNvalue, brandRefinementId);

  const itemIdsForEachList = !refinedNvalue
    ? getItemIdsForEachList(promotion)
    : null;

  let [firstListItemIds, secondListItemIds] = itemIdsForEachList || [];
  // bogo card only shows one list, but we need the anchor item data too
  // we dont have product query anchor item data cached, so we need to use it from search
  if (type === 'card') secondListItemIds = [anchorItem.itemId];
  const isWaitingForUser = !showProgressOnLoad && !hasUserInteracted && type !== 'card';
  // for BOGO card, we load reward items immediately, then wait for user interaction to request cart
  const skipFirstListRequest = isWaitingForUser || (!firstListItemIds?.length && !refinedNvalue);
  const skipSecondListRequest = skipFirstListRequest || !secondListItemIds?.length
    || (!isTwoListUi(promotion) && type !== 'card');

  const firstListVariables = { storeId };
  const secondListVariables = { storeId, itemIds: secondListItemIds };

  if (!refinedNvalue) {
    firstListVariables.itemIds = firstListItemIds;
  } else {
    firstListVariables.navParam = refinedNvalue;
    firstListVariables.pageSize = 6;
    firstListVariables.storefilter = 'ALL';
  }

  const {
    data: firstListData,
    loading: firstListLoading,
    error: firstSearchModelCallError
  } = useDataModel('searchModel', {
    variables: firstListVariables,
    skip: skipFirstListRequest,
  });

  const {
    data: secondListData,
    loading: secondListLoading,
    error: secondSearchModelCallError
  } = useDataModel('searchModel', {
    variables: secondListVariables,
    skip: skipSecondListRequest,
  });

  if (firstSearchModelCallError || secondSearchModelCallError) {
    let message;
    if (firstSearchModelCallError) {
      message = firstSearchModelCallError;
    } else if (secondSearchModelCallError) {
      message = secondSearchModelCallError;
    }
    const searchModelErrorMessage = `A search model call
    failed: ${message}`;
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-RewardFailedSearch',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: searchModelErrorMessage
    });
  }

  const firstListProducts = firstListData?.searchModel?.products;
  const secondListProducts = secondListData?.searchModel?.products;

  const isWaitingForSearch = (!skipFirstListRequest && firstListLoading)
    || (!skipSecondListRequest && secondListLoading);
  const isAwaitingData = (
    isWaitingForAnchorItemId
    || isWaitingForMaAvailability
    || isWaitingForPromotionProducts
    || isWaitingForUser
    || isWaitingForSearch
  );

  const contextValue = useMemo(() => {
    if (!promotion) {
      return {
        ...defaultModelContext,
        anchorItem,
      };
    }

    const {
      startDate,
      endDate,
      expDate,
      isExpiring,
      shortDesc,
      longDesc,
      allListProductsAndAnchor,
      hasAnchorFromSearch,
      hasSearchData,
      isBogo,
      isMsb,
      isBmsm,
      isForwardsBogo,
      isBackwardsBogo,
      isForwardsB1gy,
      isForwardsBxg1,
      isDollarThresholdBogo,
      analyticsComponent,
      analyticsSharedSection,
      displayableFirstListItemIds,
      displayableSecondListItemIds,
      listHasFulfillment,
      isAnchorFulfillable,
      isAnchorAppliance,
      subExperience,
      rewardTiers,
      src1MinPurchaseAmount,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria,
    } = transformResponseToContextValues({
      promotion,
      firstListProducts,
      secondListProducts,
      anchorItem,
      refinedNvalue,
      maAvailability,
    });

    return {
      refinedNvalue,
      originalNvalue,
      anchorItem: allListProductsAndAnchor.get(anchorItem.itemId),
      anchorItemId: anchorItem.itemId,
      startDate,
      endDate,
      expDate,
      isExpiring,
      shortDesc,
      longDesc,
      isAwaitingData,
      allListProductsAndAnchor,
      hasAnchorFromSearch,
      hasSearchData,
      isBogo,
      isMsb,
      isBmsm,
      isForwardsBogo,
      isBackwardsBogo,
      isForwardsB1gy,
      isForwardsBxg1,
      isDollarThresholdBogo,
      isMultiSelect,
      analyticsComponent,
      analyticsSharedSection,
      displayableFirstListItemIds,
      displayableSecondListItemIds,
      listHasFulfillment,
      isAnchorFulfillable,
      isAnchorAppliance,
      experienceTag,
      subExperience,
      rewardTiers,
      bogoMaxQuantity: 1, // TODO link to dynamic data
      applianceDeliveryStore: maAvailability?.applianceDeliveryStore,
      src1MinPurchaseAmount,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria,
      fromBogoCard: type === 'card',
    };
  }, [
    isAwaitingData,
    isMultiSelect,
    maAvailability,
    promotion,
    firstListProducts,
    secondListProducts,
    anchorItem,
    refinedNvalue,
    originalNvalue,
    experienceTag,
    type,
  ]);

  // nvalue promos do not paginate yet due to MA parts and services atc limitations.
  const hasFirstListItems = Boolean(contextValue.displayableFirstListItemIds?.length);
  const hasSecondListItems = Boolean(contextValue.displayableSecondListItemIds?.length);
  const hasEmptyList = (!hasFirstListItems || (isTwoListUi(promotion) && !hasSecondListItems));

  const anchorIsUnfulfillable = contextValue.anchorItem?.itemId
  && contextValue.anchorItem?.fulfillment && !isFulfillable(contextValue.anchorItem);
  const incompatiblePromotion = (!isWaitingForPromotionProducts && !promotion)
    || (!isAwaitingData && !hasCompatiblePromotion(promotion, type));
  const isEmptyListOfItems = !isAwaitingData && (hasEmptyList || !contextValue.listHasFulfillment);

  if (anchorIsUnfulfillable) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-Promotion Product',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'anchor item is unfulfillable'
    });
    if (typeof onFail === 'function') onFail(true);
    if (type === 'card') return null;
  }
  if (incompatiblePromotion) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-Incompatible',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'Incompatible promotion'
    });
    if (onFail) onFail(true);
    return null;
  }
  if (isEmptyListOfItems) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-AnchorFailedSearch',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'there are no items in the list'
    });
    if (onFail) onFail(true);
    if (type === 'card') return null;
    return null;
  }

  return (
    <PromoModelContext.Provider value={contextValue}>
      {(!promotion || !anchorItem.itemId) ? (
        <PromotionProductsLoader
          experienceTag={experienceTag}
          subExperienceTag={subExperienceTag}
          type={type}
        />
      ) : (
        <>
          {children}
        </>
      )}
    </PromoModelContext.Provider>
  );
};

PromoModelProvider.displayName = 'PromoModelProvider';

PromoModelProvider.propTypes = {
  anchorItem: shape({
    itemId: string.isRequired,
    identifiers: shape({
      productType: string,
    }),
    pricing: shape({
      conditionalPromotions: arrayOf(shape({
        brandRefinementId: string,
      })),
    }),
  }).isRequired,
  hasUserInteracted: bool,
  experienceTag: string,
  subExperienceTag: string,
  isMultiSelect: bool.isRequired,
  maAvailability: shape({
    isAvailable: bool.isRequired,
    isLoading: bool.isRequired,
    applianceDeliveryStore: string,
  }),
  children: node.isRequired,
  type: oneOf(['card', 'pod']).isRequired,
  onFail: func
};

PromoModelProvider.defaultProps = {
  experienceTag: null,
  hasUserInteracted: false,
  subExperienceTag: null,
  maAvailability: null,
  onFail: null
};

PromoModelProvider.dataModel = PromoModelProviderDataModel;
