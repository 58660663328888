import React from 'react';
import { string } from 'prop-types';

import { Placeholder } from '@thd-olt-component-react/core-ui';

import { isDollarThresholdBogo as isDollarThresholdBogoFn } from '../../../utils/promo-utils';
import { EXPERIENCE_TAGS } from '../../../utils/constants';

export const PodCtaLoader = ({ experienceTag, subExperienceTag }) => {
  const numRows = {
    [EXPERIENCE_TAGS.BOGO]: isDollarThresholdBogoFn({ subExperienceTag }) ? 4 : 9,
    [EXPERIENCE_TAGS.MSB]: 8,
    [EXPERIENCE_TAGS.BMSM]: 8,
  };

  return (
    <div data-component="PromotionProductsPodCtaLoader">
      {new Array(numRows[experienceTag] || 5).fill('').map((value, i) => (
        <Placeholder type="text" key={`row-${i}`} />
      ))}
    </div>
  );
};

PodCtaLoader.displayName = 'PodCtaLoader';

PodCtaLoader.propTypes = {
  experienceTag: string,
  subExperienceTag: string,
};

PodCtaLoader.defaultProps = {
  experienceTag: null,
  subExperienceTag: null,
};
