import React from 'react';
import {
  arrayOf,
  bool,
  number,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames/bind';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  Button,
  Typography,
  Card,
  CardBody,
  CardMedia,
  CardTitle,
  CardActions,
  CardContent,
} from '@one-thd/sui-atomic-components';
import { SpotlightB2b } from './SpotlightB2b';
import { HrefLink, RichText, VideoPlayerWrap } from './subcomponents';
import { akamaiImageResize } from './utils/akamaiImageOptimizer';
import { publish } from './utils/publisher';
import styles from './spotlight.scss';

const SpotlightRenderer = ({
  componentId,
  componentClass,
  componentPosition,
  data,
  lazyLoad,
  listOfLinks,
  orientation,
  proAnalyticsData,
  richText,
  showDescription,
  useB2bStyles,
}) => {

  const cx = classNames.bind(styles);

  let {
    cta, description, link, title, altText, proAnalyticsCampaign, proAnalyticsComponent,
    videoUrl, previewImage: dataComponentPreviewImage
  } = data || {};

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'Spotlight',
      component: 'Spotlight',
      position: componentPosition,
      type: 'content',
    },
  });

  const shouldRenderListOfLinks = listOfLinks?.length > 0 && listOfLinks[0].label;

  let imageUrl;
  const damDownloadedContentImage = dataComponentPreviewImage?.damDownloadedContent;
  if (damDownloadedContentImage?.url) {
    imageUrl = damDownloadedContentImage?.url;
  } else {
    imageUrl = dataComponentPreviewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl;
  }

  imageUrl = akamaiImageResize(imageUrl);
  const hasMedia = !!(videoUrl || imageUrl);

  const handleContentClick = () => {
    if (proAnalyticsData.isB2B) {
      // Input Data for widget should be collected from component data
      publish({
        widgetId: componentId,
        widgetTemplates: proAnalyticsData.templates,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  };
  if (useB2bStyles) {
    return (
      <SpotlightB2b
        componentId={componentId}
        componentClass={componentClass}
        componentPosition={componentPosition}
        proAnalyticsData={proAnalyticsData}
      />
    );
  }

  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component="Spotlight"
      data-component-name="Spotlight"
      data-component-position={componentPosition}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="spotlight--grid sui-grid sui-h-full sui-w-full"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Card orientation={orientation} className={cx('spotlight-card__link--hover-no-underline spotlight-card')}>
        {hasMedia && (
          <CardContent disablePadding initialSize={orientation === 'horizontal' ? '2/4' : null}>
            {videoUrl ? (
              <div data-contentful-entry-id={componentId} data-contentful-field-id="videoUrl">
                <VideoPlayerWrap videoUrl={videoUrl} />
              </div>
            ) : (
              <CardMedia
                src={imageUrl}
                aspect="wide"
                alt={altText || title}
                title={altText || title}
                loading={lazyLoad ? 'lazy' : ''}
                data-contentful-entry-id={componentId}
                data-contentful-field-id="image"
              />
            )}
          </CardContent>
        )}
        <CardContent disablePadding grow initialSize={(orientation === 'horizontal' && hasMedia) ? '2/4' : null}>
          <CardTitle header={title} data-contentful-entry-id={componentId} data-contentful-field-id="title" />
          <CardBody>
            {richText ? (
              <div data-contentful-entry-id={componentId} data-contentful-field-id="richTextContent">
                <RichText content={richText} />
              </div>
            ) : showDescription && description && (
              <Typography
                height="normal"
                weight="regular"
                variant="body-base"
                color="primary"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="description"
              >
                {description}
              </Typography>
            )}
          </CardBody>
          <CardActions className="sui-flex-col">
            {shouldRenderListOfLinks && (
              <div
                className="sui-flex sui-gap-2 sui-flex-col sui-mb-6 sui-mt-4 sui-w-full"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="linkList"
              >
                {listOfLinks?.map((linkItem, key) => {
                  return <HrefLink linkItem={linkItem} key={key} />;
                }
                )}
              </div>
            )}
            {link && cta && (
              <Button
                data-contentful-entry-id={componentId}
                data-contentful-field-id="cta"
                href={link}
                variant="secondary"
                onClick={handleContentClick}
                fullWidth={orientation === 'vertical' || !hasMedia}
              >
                {cta}
              </Button>
            )}
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

SpotlightRenderer.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  data: shape({}),
  lazyLoad: bool,
  listOfLinks: arrayOf(shape({})),
  orientation: string,
  proAnalyticsData: shape({
    isB2B: bool,
    templates: string,
  }),
  richText: shape({}),
  showDescription: bool,
  useB2bStyles: bool,
};

SpotlightRenderer.defaultProps = {
  componentPosition: 1,
  componentClass: '',
  data: {},
  lazyLoad: false,
  listOfLinks: [],
  orientation: 'vertical',
  proAnalyticsData: {},
  richText: null,
  showDescription: false,
  useB2bStyles: false,
};

export { SpotlightRenderer };