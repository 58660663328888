import React, { useCallback, useContext, useRef } from 'react';
import { oneOf, bool as boolType, string as stringType, number as numberType } from 'prop-types';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Calendar } from '@one-thd/sui-icons';
import { getCustomerData, getSkippers } from '../../utils/helpers';
import { todaysRecommendationsDataModel } from '../../dataModel';
import { FallbackCard } from '../../core/FallbackCard';
import { ProductCarousel } from '../ProductCarousel';

const TodaysRecommendations = (props) => {
  const {
    brandTitleMaxLine,
    customerID,
    customerType,
    hideBulkPrice,
    hideTitle,
    requestKey,
    showLoading,
    slidesPer,
    showFallback,
    noATCFulfillment,
    hideATC,
    entryId
  } = props;

  const { isLocalized, storeId, storeZip: zipCode } = useStore();

  const { channel } = useContext(ExperienceContext) || {};
  const innerQueryPrerequisites = useRef({ storeId, zipCode });

  const isMounted = useCallback(() => {
    return innerQueryPrerequisites.current.mounted;
  }, [innerQueryPrerequisites.current.mounted]);

  const { isB2BCustomer, mcvisID, userID } = getCustomerData();
  const isB2B = customerType === 'B2B' || isB2BCustomer;

  const defaultCustID = isB2B ? userID : mcvisID;
  const custID = customerID || defaultCustID;

  const customerTypeOptions = isB2B
    ? { apiName: 'products_b2b', columnFamily: 'b2b' }
    : { apiName: 'products', columnFamily: 'b2c' };

  const udsQueryOptions = {
    variables: {
      appId: channel === 'mobile' ? 'mobileweb' : 'desktop',
      key: requestKey,
      dataSource: customerTypeOptions?.apiName,
      storeId,
      tableName: 'todays_recommendations',
      // B2C custID should always be mcvisID. B2B will be unique customer id.
      userId: custID || null,
      schema: 'products',
      zipCode,
      ...getSkippers({}),
      ...customerTypeOptions,
    },
    skip: !isLocalized || storeId === '8119',
    ssr: false,
  };

  const { loading, data, error, variables } = useDataModel('uds', udsQueryOptions);

  if (data && isLocalized) {
    innerQueryPrerequisites.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true,
    };
  }

  if (error || (!data?.uds?.products?.length && !loading)) {
    if (showFallback) {
      return (
        <FallbackCard
          header="No Recommendations Yet"
          subheader="We&apos;ll provide suggestions as you shop"
          footerUrl="https://www.homedepot.com/SpecialBuy/SpecialBuyOfTheDay"
          footer="Shop Today&apos;s Specials"
          componentName="TodaysRecommendations"
          icon={Calendar}
        />
      );
    }

    return null;
  }

  const impressionData = {
    id: entryId || '',
    name: TodaysRecommendations.displayName,
    component: TodaysRecommendations.displayName,
    type: 'product',
    position: ''
  };

  return (
    <ImpressionProvider data={impressionData}>
      <div
        id={customerTypeOptions?.apiName}
        className="sui-py-0"
        data-type="container"
        data-component="TodaysRecommendations"
      >
        <meta data-prop="name" content={customerTypeOptions?.apiName} />
        <QueryProvider
          cacheKey="user-data-services-todays-recs"
          mounted={isMounted}
          defaultVariables={{
            storeId: innerQueryPrerequisites.current.storeId,
            zipCode: innerQueryPrerequisites.current.zipCode,
          }}
        >
          <ProductCarousel
            brandTitleMaxLine={brandTitleMaxLine}
            data={data?.uds}
            hideBulkPrice={hideBulkPrice}
            hideTitle={hideTitle}
            hideATC={hideATC}
            hideSavingsText={false}
            itemClass="fixed-item"
            loading={loading}
            showLoading={showLoading}
            storeId={storeId}
            zipCode={zipCode}
            slidesPer={slidesPer}
            scheme={customerTypeOptions?.apiName}
            noATCFulfillment={noATCFulfillment}
            hideControls={data?.uds?.products?.length <= 2}
          />
        </QueryProvider>
      </div>
    </ImpressionProvider>
  );
};

TodaysRecommendations.dataModel = todaysRecommendationsDataModel;

TodaysRecommendations.displayName = 'TodaysRecommendations';

TodaysRecommendations.propTypes = {
  // product pod takes only these prop values for brandTitleMaxLine
  brandTitleMaxLine: oneOf([2, 3, 4, null]),
  customerID: stringType,
  customerType: oneOf(['B2B', 'B2C']),
  hideBulkPrice: boolType,
  hideTitle: boolType,
  requestKey: stringType.isRequired,
  showLoading: boolType,
  slidesPer: numberType,
  showFallback: boolType,
  noATCFulfillment: boolType,
  hideATC: boolType,
  entryId: stringType
};

TodaysRecommendations.defaultProps = {
  brandTitleMaxLine: 2,
  customerID: null,
  customerType: 'B2C',
  hideBulkPrice: false,
  hideTitle: false,
  showLoading: false,
  slidesPer: null,
  showFallback: false,
  noATCFulfillment: null,
  hideATC: false,
  entryId: null
};

export { TodaysRecommendations };
