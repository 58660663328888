import { useMemo } from 'react';

import { useConfigService } from '@thd-nucleus/experience-context';
import { FEATURE_SWITCH_KEYS, MESSAGE_KEY, MESSAGE_STATE } from '../utils/constants';
import { formatPrice, formattedReward } from '../utils/promo-presentation-utils';
import { useMessageFn } from './useMessage';
import { usePromoMessage } from './usePromoMessage';
import { usePromoCart } from './usePromoCart';
import { usePromoModel } from './usePromoModel';

export const usePromoProgress = () => {
  const { rewardTiers } = usePromoModel();
  const { currentPromoTier, progressMade } = usePromoCart();
  const { messageState } = usePromoMessage();
  const progressBarEndLabelFn = useMessageFn(MESSAGE_KEY.progressBarEndLabel);
  const categorizedPromotionEnabled = useConfigService(
    FEATURE_SWITCH_KEYS.categorizedPromotionEnabled
  ) || false;

  return useMemo(() => {
    let progressValue = 0;

    let progressStatus = null;

    let tieredProgressValue = {};

    if (!categorizedPromotionEnabled) {
      progressValue = currentPromoTier <= 0 ? progressMade : 100;
      progressStatus = messageState === MESSAGE_STATE.success ? 'success' : null;
      tieredProgressValue = rewardTiers.map((rewardTier) => {

        const {
          fixedPriceTotal,
          minPurchaseQuantity,
          rewardAmountPerOrder,
          rewardPercent,
          tier
        } = rewardTier;
        const qualifyingAmount = minPurchaseQuantity;
        const rewardAmount = fixedPriceTotal
          ? formatPrice(fixedPriceTotal, true)
          : formattedReward({
            rewardPercent,
            rewardAmountPerOrder
          }, true);

        const endLabel = progressBarEndLabelFn({
          qualifyingAmount,
          rewardAmount,
        });

        let value = 0;
        if (tier === currentPromoTier) {
          value = progressMade;
        } else if (tier < currentPromoTier) {
          value = 100;
        }

        return Object.freeze({
          endLabel,
          value,
        });
      });
    } else {
      // categorizedPromotionEnabled fs on
    }

    return Object.freeze({
      progressValue,
      progressStatus,
      tieredProgressValue,
    });
  }, [
    categorizedPromotionEnabled,
    currentPromoTier,
    messageState,
    progressBarEndLabelFn,
    progressMade,
    rewardTiers
  ]);
};
