import React from 'react';
import { shape, string, array } from 'prop-types';
import { adServerClients, BANNER, bannerTypes } from '../../constants/constants';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { get } from '../../utils/axios';
import { triggerNewRelic } from '../../utils/helpers';
import { injectRevJetScript, getCreativeId, onAdTagContainerClick } from '../../utils/revjet';
import { newRelicConstants } from '../../constants';

function getHeroBannerPosition() {
  const defaultPosition = 2;
  const value = typeof window !== 'undefined' ? window.cookieUtils?.readBrowserCookie('xtt-tntHeroBannerPos') : null;
  return value ? value - 1 : defaultPosition;
}

const buildHeroBannerRequest = (
  { adServer, isGuest, experienceContext, browserId }
) => {
  const bannerChannel = experienceContext?.channel === 'desktop' ? 'desktop' : 'mobileweb';
  const bannerSchema = isGuest ? 'hp_sponsored_auth' : 'hp_sponsored';
  const customerType = experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  const heroBannerPosition = getHeroBannerPosition();
  const heroRequestConfig = {
    schema: bannerSchema,
    experienceContext,
    carouselType: null,
    bannerType: 'hero',
    schemaId: 'hp_sponsored__hero',
  };

  const params = {
    version: 'model2',
    adServer,
    appid: bannerChannel,
    customerType,
    browserid: browserId,
    schema: bannerSchema,
    show: BANNER,
    bannerType: bannerTypes.HERO,
    bannerPosition: heroBannerPosition,
  };
  return { heroRequestConfig, heroBannerPosition, params };
};

const insertHeroBanner = ({
  cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
}) => {
  initImpressionWatchers();

  let viewBeacon = data?.banner?.onViewBeacon;
  viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';
  let creativeId = getCreativeId(data?.banner?.bannerURL);

  let tempChild = (
    <SponsoredHeroBanner
      cx={cx}
      data={data}
      newCarousel={newCarousel}
      viewBeacon={viewBeacon}
      creativeId={creativeId}
      classNames={classNames}
    />
  );

  // We do not want to set the banner position
  // to the first and last index.
  if (heroBannerPosition > 0 && heroBannerPosition < newCarousel?.length - 1) {
    newCarousel.splice(heroBannerPosition, 0, tempChild);
    injectRevJetScript({ data, requestConfig: heroRequestConfig });
  }
};

const getHeroBanner = ({
  cx, data, heroBannerPosition, heroRequestConfig, classNames
}) => {
  initImpressionWatchers();
  injectRevJetScript({ data, requestConfig: heroRequestConfig });

  let viewBeacon = data?.banner?.onViewBeacon;
  viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';
  let creativeId = getCreativeId(data?.banner?.bannerURL);

  return {
    HeroBanner: (
      <SponsoredHeroBanner
        cx={cx}
        data={data}
        viewBeacon={viewBeacon}
        creativeId={creativeId}
        classNames={classNames}
      />
    ),
    heroBannerPosition,
  };

};

async function getSponsoredHeroBanner({
  adServer = adServerClients.PIQ, newCarousel, cx, classNames, isGuest, experienceContext, browserId, returnSponsoredHeroOnly
}) {

  const { heroRequestConfig, heroBannerPosition, params } = buildHeroBannerRequest({
    adServer, isGuest, experienceContext, browserId
  });
  try {
    const response = await get({ params });
    triggerNewRelic(newRelicConstants.HERO_BANNER, `API-${response.status}`);
    if (response.status === 200 && response?.data) {
      const { data } = response;
      if (returnSponsoredHeroOnly) {
        return (getHeroBanner({
          cx, data, heroBannerPosition, heroRequestConfig, classNames
        }));
      }
      (
        insertHeroBanner({
          cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
        })
      );
    }

  } catch (error) {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('error-boundary.error', {
        error,
        id: newRelicConstants.HERO_BANNER
      });
    }
  }
  return newCarousel;
}

const SponsoredHeroBanner = (props) => {
  let{
    cx,
    data,
    newCarousel,
    viewBeacon,
    creativeId,
    classNames
  } = props;
  return (
    <div
      id="hp_sponsored__hero_container"
      className={cx ? cx(classNames) : classNames}
      onClick={onAdTagContainerClick}
      data-onview-beacon-nucleus={viewBeacon}
      data-campaignid={data?.banner?.campaignId}
      data-placementid={data?.banner?.placementId}
      data-slotid={data?.banner?.slotId}
      data-creativeid={creativeId}
      data-testid="sponsored_hero_banner"
      style={{ cursor: 'pointer' }}
      role="presentation"
    >
      <div
        id="hp_sponsored__hero"
        key={`item-${newCarousel.length}`}
        className={cx ? cx(classNames) : classNames}
        style={{ pointerEvents: 'none' }}
      >
        {newCarousel[0] ? newCarousel[0] : null}
      </div>
    </div>
  );
};

SponsoredHeroBanner.propTypes = {
  cx: string,
  data: shape({
    banner: shape({
      campaignId: string,
      placementId: string,
      slotId: string
    })
  }),
  newCarousel: array,
  viewBeacon: string,
  creativeId: string,
  classNames: string
};

SponsoredHeroBanner.defaultProps = {
  cx: '',
  data: {},
  newCarousel: [],
  viewBeacon: '',
  creativeId: '',
  classNames: ''
};

export { getSponsoredHeroBanner };
