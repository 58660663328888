import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getTarget } from '../utils';

/**
 * The `HeroButton` component comes with two variants, "primary" (b2c orange) and "dark".
 *
 * The Dark variant is the new bespoke button variant for the Event Hero.
 * - This will be triggered in the future by data from contentful, currently under construction in a separate story.
 * TODO - Once the Contentful data work is completed we'll need to remove the logic inside `Hero.js` && `StoryHero.js`.
 */

const HeroButton = forwardRef((props, ref) => {

  const { children, href, variant } = props;
  let colortheme;

  switch (variant) {
  case 'eventB2c':
    colortheme = 'sui-text-inverse sui-bg-inverse hover:sui-bg-button-secondary hover:sui-text-primary';
    break;
  case 'defaultB2b':
    colortheme = 'sui-text-inverse sui-bg-button-primary hover:sui-bg-button-secondary hover:sui-text-primary';
    break;
  default:
    // default case is for defaultB2c && eventB2b
    colortheme = 'sui-text-inverse sui-bg-button-primary hover:sui-bg-inverse hover:sui-text-inverse';
  }

  const darkClasses = useMemo(() => {
    return classNames(
      'sui-relative sui-inline-flex sui-items-center sui-justify-center sui-align-middle',
      'sui-box-border sui-rounded-base sui-border-0 sui-outline-none sui-outline-0',
      'sui-cursor-pointer sui-tap-highlight-transparent sui-select-none sui-appearance-none sui-no-underline',
      'sui-px-4 sui-h-11 sui-w-fit',
      'focus-visible:sui-bg-button-focus focus-visible:sui-text-primary',
      'sui-font-bold sui-text-base sui-leading-normal sui-tracking-normal sui-normal-case sui-line-clamp-unset',
      'hover:sui-no-underline',
      colortheme
    );
  }, [variant]);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={darkClasses}
      href={href}
      ref={ref}
      target={getTarget(href)}
    >
      {children}
    </a>
  );
});

HeroButton.displayName = 'HeroButton';

HeroButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['eventB2c', 'eventB2b', 'defaultB2b', 'defaultB2c']),
};

HeroButton.defaultProps = {
  variant: 'defaultB2c'
};

export { HeroButton };
