import React, { forwardRef } from 'react';
import { node, oneOf, string } from 'prop-types';
import classNames from 'classnames';

// TODO Work with Stencil team on allowing non direct children Buttons within their ButtonGroup

/**
 * A simple button grouping component. This is for cases where the Stencil
 * ButtonGroup won't work (such as Button components not being direct children).
 *
 * @param {object} props Any props not explicitly listed will be applied to the root element
 * @param {React.ReactNode} props.children
 * @param {string | null} [props.className] Additional classes to append
 * @param {string} [props.component] Which tag to use for the root element (default: "div")
 * @param {'horizontal' | 'vertical'} [props.orientation] Orientation of the grouping
 */
export const ButtonGroup = forwardRef(({
  children,
  className,
  component,
  orientation,
  ...rest
}, ref) => {
  const Tag = component;
  const groupClassName = classNames(
    'sui-flex sui-items-center sui-gap-4 sm:sui-gap-4 sui-w-full',
    { 'sui-flex-col': orientation === 'vertical' },
    className
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tag className={groupClassName} ref={ref} {...rest}>
      {children}
    </Tag>
  );
});

ButtonGroup.propTypes = {
  children: node.isRequired,
  className: string,
  component: string,
  orientation: oneOf(['horizontal', 'vertical'])
};

ButtonGroup.defaultProps = {
  className: null,
  component: 'div',
  orientation: 'horizontal'
};
