import {
  alias, params, string, extend, arrayOf, shape, bool, number, customType, client
} from '@thd-nucleus/data-sources';
import { ProductPod } from '@thd-olt-component-react/product-pod';
import { wrappedProduct } from './core/RecommendationsProductPodCarousel';
import { udsWrappedProduct } from './uds/ProductCarousel';
import { wrappedProduct1 } from './core/ProductPodV7Carousel';
import { DiscoveryZonesPod } from './discovery-zones/DiscoveryZonesPod';

const productModel = extend({}, {
  itemId: string(),
  dataSource: string(),
}, ProductPod.dataModel.product);

export const dataModel = extend({
  recsProducts:
    alias('products').params({
      itemIds: arrayOf(string().isRequired()).isRequired(),
      dataSource: string()
    }).arrayOf(productModel),
});
const queryParams = {
  apiName: string().isRequired(),
  appId: string(),
  key: string().isRequired(),
  storeId: string(),
};

const metadata = {
  apiName: string(),
  title: string(),
  modelName: string(),
  fallbackModelName: string(),
  type: string(),
  version: string(),
};

const collectionsMetaData = {
  collectionTitle1: string(),
  collectionTitle2: string(),
  collectionPlpUrl: string(),
  collectionImageUrl: string(),
  collectionDesc: string(),
  collectionName: string()
};

export const dynamicRecsDataModel = extend({
  dts: params({
    anchorId: string().isRequired(),
    model: string().isRequired(),
    storeId: string(),
    appId: string().isRequired(),
    key: string(),
    endpointType: string().isRequired()
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      model: string(),
      version: string(),
      fallbackModelName: string(),
      type: string(),
      ...collectionsMetaData,
    }),
    categories: arrayOf(shape({
      category: string(),
      itemIds: arrayOf(string()),
      categoryGuid: string()
    })),
    products: arrayOf(wrappedProduct1)
  })
}, {
  recs: params({
    anchorId: string().isRequired(),
    apiName: string().isRequired(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    }),
    storeId: string(),
    serviceType: string(),
    appId: string(),
    key: string(),
    nvalue: string(),
    maxResults: string(),
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      version: string(),
      modelName: string(),
      fallbackModelName: string(),
      type: string(),
      ...collectionsMetaData,
    }),
    categories: arrayOf(shape({
      category: string(),
      itemIds: arrayOf(string()),
      categoryGuid: string()
    })),
    products: arrayOf(wrappedProduct1)
  })
});

export const rvDataModel = {
  uds: params({
    userId: string().isRequired(),
    ...queryParams,
    unique: bool(),
    // showPrice: bool()
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(wrappedProduct1)
  })
};

const trendingNearYouWrappedImage = {
  url: string(),
  type: string(),
  subType: string(),
  sizes: arrayOf(string()),
  __typename: string()
};

const trendingNearYouWrappedProduct = {
  product: shape({
    categoryUrl: string(),
    categoryName: string(),
    identifiers: shape({
      canonicalUrl: string()
    }),
    itemId: string(),
    media: shape({
      images: arrayOf(
        trendingNearYouWrappedImage
      ),
      __typename: string()
    })
  })
};

export const trendingNearYouDataModel = {
  uds: params({
    apiName: string().isRequired(),
    appId: string().isRequired(),
    key: string().isRequired(),
    storeId: string().isRequired(),
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(trendingNearYouWrappedProduct)
  })
};

export const todaysRecommendationsDataModel = {
  uds: params({
    ...queryParams,
    columnFamily: string(),
    schema: string(),
    tableName: string(),
    userId: string().isRequired(),
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(udsWrappedProduct)
  })
};

export const reorderDataModel = {
  uds: params({
    ...queryParams,
    cType: string(),
    exItems: string(),
    filter: string(),
    maxProducts: number(),
    model: string(),
    showPrice: bool(),
    tableName: string(),
    userId: string().isRequired()
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(udsWrappedProduct)
  }),
};

export const priceDropDataModel = {
  uds: params({
    ...queryParams,
    userId: string().isRequired()
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(udsWrappedProduct)
  }),
};

const wrappedArticle = {
  guid: string(),
  title: string(),
  articleType: string(),
  imageUrl: string(),
  guideUrl: string(),
  difficulty: string(),
  youtubeUrl: string(),
};

export const articleGuidesDataModel = {
  uds: params({
    apiName: string().isRequired(),
    key: string().isRequired(),
    userId: string(),
  }).shape({
    metadata: shape({
      apiName: string(),
    }),
    articles: arrayOf(wrappedArticle),
  }),
};

export const supplementalRecsdataModel = extend({
  dts: params({
    anchorId: string().isRequired(),
    model: string().isRequired(),
    appId: string().isRequired(),
    key: string(),
    storeId: string(),
    endpointType: string().isRequired()
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(wrappedProduct1)
  })
}, {
  recs: params({
    anchorId: string().isRequired(),
    apiName: string().isRequired(),
    appId: string(),
    key: string(),
    storeId: string(),
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(wrappedProduct1)
  })
});

export const discoveryZonesModel = {
  searchModel: params({
    navParam: string()
  }).shape({
    discoveryZones: client(arrayOf(shape({
      products: params({ dataSource: string() }).arrayOf(shape(DiscoveryZonesPod.dataModel.product)),
      metadata: shape({
        zone: string(),
        zoneTitle: string()
      })
    })).skip('skipDiscoveryZones', true))
  })
};

export const pipRecsModel = {
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      recommendationFlags: shape({
        visualNavigation: bool(),
        pipCollections: bool(),
        ACC: bool(),
        collections: bool(),
        batItems: bool(),
        packages: bool(),
        frequentlyBoughtTogether: bool(),
        bundles: bool(),
      }),
    }),
    identifiers: shape({
      productType: string(),
    }),
    availabilityType: shape({
      discontinued: bool(),
      type: string(),
    }),
    fulfillment: params({ storeId: string() }).shape({
      backordered: bool(),
      fulfillmentOptions: arrayOf(
        shape({
          type: string(),
          fulfillable: bool(),
          services: arrayOf(
            shape({
              type: string(),
              locations: arrayOf(
                shape({
                  isAnchor: bool(),
                  inventory: shape({
                    isLimitedQuantity: bool(),
                    isOutOfStock: bool(),
                  }),
                })
              ),
            })
          ),
        })
      ),
    }),
    media: shape({
      image: shape({
        url: string(),
      }).client(),
    }),
  })
};