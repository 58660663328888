import {
  arrayOf as arrayOfType,
  bool as boolType,
  client,
  customType,
  extend,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';

import { CallToActionDataModel } from './CallToActionDataModel';
import { PromoDrawerDataModel } from './PromoDrawerDataModel';

export const PromoModelProviderDataModel = extend(
  {
    searchModel: params({
      navParam: stringType(),
      storeId: stringType(),
      itemIds: arrayOfType(stringType()),
      storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE']),
    }).shape({
      products: params({
        pageSize: numberType(),
      }).arrayOf(shapeType(extend(
        {
          itemId: stringType(),
          dataSources: stringType(),
          fulfillment: client(params({ storeId: stringType() }).shape({
            fulfillmentOptions: arrayOfType(shapeType({
              fulfillable: boolType(),
              type: stringType(),
              services: arrayOfType(shapeType({
                type: stringType(),
                locations: arrayOfType(shapeType({
                  isAnchor: boolType(),
                  locationId: stringType(),
                  inventory: shapeType({
                    isOutOfStock: boolType(),
                    quantity: numberType(),
                  }),
                })),
              })),
            })),
          })),
          identifiers: shapeType({
            brandName: stringType(),
            productType: stringType(),
            productLabel: stringType(),
            canonicalUrl: stringType(),
          }),
          media: shapeType({
            image: shapeType({
              url: stringType(),
            }).client(),
            images: arrayOfType(shapeType({
              url: stringType(),
              sizes: arrayOfType(stringType()),
              type: stringType(),
              subType: stringType(),
            })),
          }),
          pricing: params({ storeId: stringType() }).shape({
            value: numberType({ float: true }),
          }),
          // should be able to remove these ...
          details: shapeType({
            description: stringType(),
            descriptiveAttributes: arrayOfType(shapeType({
              value: stringType(),
            }))
          }),
          reviews: shapeType({
            ratingsReviews: shapeType({
              averageRating: stringType(),
              totalReviews: stringType(),
            }),
          }),
          info: shapeType({
            isBrioSku: boolType(),
            isCustomKitchenCabinet: boolType(),
            quantityLimit: numberType(),
          }),
        },
        // we explicitly listed the properties the PromoModelProvider needs for its transformations,
        // but need to extend the requirements for the components that read from the cache
        CallToActionDataModel.product,
        PromoDrawerDataModel.product,
      ))),
    }),
  },
  {
    promotionProducts: params({
      itemId: stringType().isRequired(),
      pageSize: numberType(),
      loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
        svocID: stringType(),
        programTiers: arrayOfType(
          shapeType({
            tier: stringType(),
            program: stringType(),
          }),
        ),
      }),
    }).shape({
      promotions: arrayOfType(shapeType({
        anchorItemGroups: arrayOfType(stringType()),
        anchorItemId: stringType(),
        experienceTag: stringType(),
        nvalues: arrayOfType(stringType()),
        promotionId: stringType(),
        subExperienceTag: stringType(),
        eligibilityCriteria: arrayOfType(shapeType({
          itemGroup: stringType(),
          itemIds: arrayOfType(stringType()),
          minPurchaseAmount: numberType({ float: true }),
          minPurchaseQuantity: numberType({ float: true }),
          searchReport: shapeType({
            pageSize: numberType(),
            startIndex: numberType(),
            totalProducts: numberType(),
          }),
        })),
        dates: shapeType({
          end: stringType(),
          start: stringType(),
        }),
        description: shapeType({
          longDesc: stringType(),
          shortDesc: stringType(),
        }),
        reward: shapeType({
          tiers: arrayOfType(shapeType({
            maxAllowedRewardAmount: numberType({ float: true }),
            maxPurchaseQuantity: numberType({ float: true }),
            minPurchaseAmount: numberType({ float: true }),
            minPurchaseQuantity: numberType({ float: true }),
            rewardAmountPerItem: numberType({ float: true }),
            rewardAmountPerOrder: numberType({ float: true }),
            rewardFixedPrice: numberType({ float: true }),
            rewardPercent: numberType({ float: true }),
          })),
        }),
      })),
    }),
  },
);
