import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';

import { useImpression } from '@thd-olt-component-react/impression';

import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardMedia,
  CardTitle,
  Typography
} from '~/@one-thd/sui-atomic-components';

const StoreWorkshopsComponent = ({ entryID, isCard }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('store-workshops.ready');
  }, []);

  const { ref, clickID } = useImpression({
    data: {
      id: entryID,
      name: 'StoreWorkshops',
      component: 'StoreWorkshops',
      position: 1,
      type: 'content',
    },
  });

  return (
    <Card
      ref={ref}
      clickid={clickID}
      className="sui-flex sui-justify-center sui-h-full"
      data-component="StoreWorkshops"
    >
      <CardTitle
        header={(
          <Typography variant="h3">
            Free In-Store Kids Workshops
          </Typography>
        )}
      />
      <CardBody>
        <CardMedia
          alt="Kids Workshops"
          aspect="wide"
          src="https://assets.thdstatic.com/images/v1/workshops/2024_Kids_Workshop_May_BloomingFrame_L_V%20Medium.jpeg"
        />
        <div
          data-testid="card-content-wrapper"
          className={!isCard ? 'store-workshop-half' : 'store-workshop-card'}
        >
          <div className="sui-pt-4 sui-pb-2">
            <Typography
              component="h4"
              variant="body-lg"
              weight="bold"
            >
              Engaging Activities for Your Future Doers
            </Typography>
          </div>
          <Typography variant="body-base">
            Join us the first Saturday of every month, 9 a.m. - 12 noon. While supplies last.
          </Typography>
        </div>
      </CardBody>
      <CardActions>
        <Button href="/kids" variant="text">
          View Upcoming Kids Workshops and Register
        </Button>
      </CardActions>
    </Card>
  );
};

StoreWorkshopsComponent.displayName = 'StoreWorkshops';

export { StoreWorkshopsComponent };

StoreWorkshopsComponent.propTypes = {
  entryID: string,
  isCard: bool,
};

StoreWorkshopsComponent.defaultProps = {
  entryID: '',
  isCard: undefined,
};
