import { useState, useCallback, useRef, useEffect } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useAddToCart, useUpdateCart, useDeleteLineItem } from '@thd-olt-component-react/cart-common-data';
import {
  cartFunctions, isValidRawCart, updateCartCountInHeader
} from '../utils/promo-cart-utils';
import { FEATURE_SWITCH_KEYS } from '../utils/constants';
import { useNewRelicLogging } from './useNewRelicLogging';
import { useGetCart } from './useGetCart';

const { fetchCartHelper, updateCartHelper, addToCartHelper } = cartFunctions;

export const useCart = ({ initializeCall = true }) => {
  const isMounted = useRef(false);
  const [currentCartModel, setCurrentCartModel] = useState();
  const [frozenCartModel, setFrozenCartModel] = useState();
  const [addToCartHandler] = useAddToCart();
  const { getCartItems, cartItemsResponse } = useGetCart();
  const { updateItemQuantity } = useUpdateCart();
  const [deleteItems] = useDeleteLineItem();

  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;

  // If a user adds/updates items in cart before initial cart call comes back
  const initialFetchComplete = useRef(false);
  const [isCartLoading, setIsCartLoading] = useState(false);

  const handleReturnedCartModel = (newCartModel) => {
    if (isMounted.current) {
      initialFetchComplete.current = true;
      setCurrentCartModel(newCartModel);
    }
  };

  const { sendErrorsToNewRelic, sendDataToNewRelic } = useNewRelicLogging();

  useEffect(() => {
    isMounted.current = true;
    // listen to generic add to cart events and handle the results
    const handleAtcSuccess = ({ eventName, output }) => {
      const { data = {}, CartModel } = output || {};
      const { addToCart: cartModel = CartModel } = data;
      if (eventName === 'success' && isValidRawCart(cartModel)) {
        handleReturnedCartModel(cartModel);
      }
    };

    window.LIFE_CYCLE_EVENT_BUS.on('add-to-cart.success', handleAtcSuccess);

    return () => {
      isMounted.current = false;
      window.LIFE_CYCLE_EVENT_BUS.off('add-to-cart.success', handleAtcSuccess);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (initializeCall && !initialFetchComplete.current) {
        setIsCartLoading(true);
        getCartItems();
      }
    };
    const fetchDataNonGraphQL = () => {
      if (initializeCall && !initialFetchComplete.current) {
        setIsCartLoading(true);
        fetchCartHelper()
          .then((newCartModel) => {
            if (!initialFetchComplete.current) {
              handleReturnedCartModel(newCartModel);
            }
          })
          .finally(() => {
            if (isMounted.current) setIsCartLoading(false);
          });
      }
    };
    if (isCartGraphQLEnabled) fetchData();
    else fetchDataNonGraphQL();
  }, [initializeCall, isCartGraphQLEnabled, getCartItems]);

  const fetchCart = useCallback(() => {
    if (isMounted.current) {
      setIsCartLoading(true);
      try {
        if (isCartGraphQLEnabled) {
          setIsCartLoading(true);
          getCartItems();
          return cartItemsResponse;

        }
        // Non-GraphQL cart processing
        return fetchCartHelper()
          .then((newCartModel) => {
            handleReturnedCartModel(newCartModel);
            const props = { actionName: 'PromotionProductsCart', callType: 'fetchCart' };
            sendDataToNewRelic(props);
            return newCartModel;
          })
          .finally(() => {
            if (isMounted.current) setIsCartLoading(false);
          });
      } catch (error) {
        const props = { actionName: 'PromotionProductsCartError', errorMessage: error.message, callType: 'fetchCart' };
        sendErrorsToNewRelic(props);
        setIsCartLoading(false);
        return Promise.reject(error);
      } finally {
        if (isMounted.current) setIsCartLoading(false);
      }

    } else {
      return Promise.reject(new Error('useCart is unmounted'));
    }
  }, [cartItemsResponse, sendErrorsToNewRelic, sendDataToNewRelic, isCartGraphQLEnabled, getCartItems]);

  useEffect(() => {
    if (isCartGraphQLEnabled) {
      const processCartResponse = () => {
        const rawCartModel = cartItemsResponse?.data?.cartInfoLite;
        if (!initialFetchComplete.current && rawCartModel) {
          handleReturnedCartModel(rawCartModel);
          setIsCartLoading(false);
        }
      };
      processCartResponse();
    }
  }, [cartItemsResponse, isCartGraphQLEnabled, fetchCart]);

  // should always have currentCartModel in dep array
  useEffect(() => {
    if (!frozenCartModel) setIsCartLoading(false);
  }, [currentCartModel, frozenCartModel]);

  const addToCart = useCallback((cartRequest) => {
    setIsCartLoading(true);
    try {
      const cartCallHandler = isCartGraphQLEnabled ? addToCartHandler(cartRequest) : addToCartHelper(cartRequest);
      return cartCallHandler
        .then((newCartModel) => {
          const cartResponse = isCartGraphQLEnabled ? newCartModel?.data?.addToCart : newCartModel;
          handleReturnedCartModel(cartResponse);
          updateCartCountInHeader(cartResponse);
          window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-cart.success', { CartModel: newCartModel });
          const props = { actionName: 'PromotionProductsCart', callType: 'addToCart' };
          sendDataToNewRelic(props);
          if (isMounted.current) setIsCartLoading(false);

          return newCartModel;
        })
        .catch((error) => {
          const props = {
            actionName: 'PromotionProductsCartError',
            errorMessage: error.message,
            callType: 'addToCart'
          };
          sendErrorsToNewRelic(props);
          initialFetchComplete.current = false;
          return fetchCart();
        });
    } catch (error) {
      const props = { actionName: 'PromotionProductsCartError', errorMessage: error.message, callType: 'addToCart' };
      sendErrorsToNewRelic(props);
      setIsCartLoading(false);
      return Promise.reject(error);
    }
  }, [addToCartHandler, fetchCart, sendErrorsToNewRelic, sendDataToNewRelic, isCartGraphQLEnabled]);

  const updateCart = useCallback((cartRequest) => {
    setIsCartLoading(true);
    try {
      const cartCallHandler = isCartGraphQLEnabled
        ? updateItemQuantity(cartRequest?.newQuantity, cartRequest?.item)
        : updateCartHelper(cartRequest);
      return cartCallHandler
        .then((newCartModel) => {
          const cartResponse = isCartGraphQLEnabled ? newCartModel?.data?.updateCart : newCartModel;
          handleReturnedCartModel(cartResponse);
          updateCartCountInHeader(cartResponse);
          window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-cart.success', { CartModel: newCartModel });
          const props = { actionName: 'PromotionProductsCart', callType: 'update' };
          sendDataToNewRelic(props);
          if (isMounted.current) setIsCartLoading(false);
          return newCartModel;
        })
        .catch((error) => {
          const props = { actionName: 'PromotionProductsCartError', errorMessage: error.message, callType: 'update' };
          sendErrorsToNewRelic(props);
          initialFetchComplete.current = false;
          return fetchCart();
        });
    } catch (error) {
      const props = { actionName: 'PromotionProductsCartError', errorMessage: error.message, callType: 'update' };
      sendErrorsToNewRelic(props);
      setIsCartLoading(false);
      return Promise.reject(error);

    }
  }, [fetchCart, sendErrorsToNewRelic, sendDataToNewRelic, isCartGraphQLEnabled, updateItemQuantity]);

  const deleteCart = useCallback((id) => {
    setIsCartLoading(true);
    try {
      const cartCallHandler = deleteItems([id]);
      return cartCallHandler
        .then((newCartModel) => {
          handleReturnedCartModel(newCartModel.data.deleteItem);
          updateCartCountInHeader(newCartModel.data.deleteItem);
          if (isMounted.current) setIsCartLoading(false);
          return newCartModel.data.deleteItem;
        })
        .catch((error) => {
          initialFetchComplete.current = false;
          return fetchCart();
        });
    } catch (error) {
      setIsCartLoading(false);
      return Promise.reject(error);

    }

  }, [
    fetchCart,
    deleteItems,
  ]);

  // when consumer calls freezeCart it takes the current value of cart and stores it in state,
  // it also returns a function that will "unfreeze" the cart by resetting it to null
  const freezeCart = useCallback(() => {
    if (isMounted.current) setFrozenCartModel(currentCartModel);

    return () => {
      if (isMounted.current) setFrozenCartModel(null);
    };
  }, [currentCartModel]);

  return {
    // if cart is "frozen" return the frozen value of cart, otherwise return the latest
    CartModel: frozenCartModel || currentCartModel,
    addToCart,
    updateCart,
    deleteCart,
    isCartLoading,
    setIsCartLoading,
    freezeCart,
  };
};
