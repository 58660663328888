import React, { useContext, useState, useMemo } from 'react';
import { instanceOf, string, bool } from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Col, Image, Overlay, Row
} from '@thd-olt-component-react/core-ui';
import { RatingMeter } from '@one-thd/sui-atomic-components';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Voting } from '../Voting/Voting.component';
import ReviewHelper from './helpers/ReviewHelper';
import { ReviewMediaCarousel } from './components/media-carousel';
import { usePresentation } from '../../context/PresentationProvider';
import { OverlayPage } from '../OverlayPage/OverlayPage';
import { BadgeDefinitions } from './components/BadgeDefinitions/BadgeDefinitions';
import { UserProfile } from '../UserProfile/UserProfile';
import { badgeLinks } from '../../constants';
import { badgesFilterLogic } from '../../helpers';
import * as analytics from '../../ratings-reviews-analytics';
import './Review.styles.scss';
import { ProductAttribution } from '../ProductAttribution/ProductAttribution.component';

const overlayPageContent = BadgeDefinitions;

const badgeToClassConverter = (type) => {
  const classes = {
    DIY: 'ratings-reviews__badge-icons--diy',
    PRO: 'ratings-reviews__badge-icons--toolbox',
    TOP1CONTRIBUTOR: 'ratings-reviews__badge-icons--1',
    TOP10CONTRIBUTOR: 'ratings-reviews__badge-icons--10',
    TOP25CONTRIBUTOR: 'ratings-reviews__badge-icons--25',
    TOP50CONTRIBUTOR: 'ratings-reviews__badge-icons--50',
    TOP100CONTRIBUTOR: 'ratings-reviews__badge-icons--100',
    TOP250CONTRIBUTOR: 'ratings-reviews__badge-icons--250',
    TOP500CONTRIBUTOR: 'ratings-reviews__badge-icons--500',
    TOP1000CONTRIBUTOR: 'ratings-reviews__badge-icons--1k',
    EXPERT: 'ratings-reviews__badge-icons--star-outline',
    COMMUNITYASSOCIATE: 'ratings-reviews__badge-icons--',
    HOMEDEPOTASSOCIATE: 'ratings-reviews__badge-icons--associate',
    INCENTIVIZEDREVIEW: 'ratings-reviews__badge-icons--community',
    EARLYREVIEWERINCENTIVE: 'ratings-reviews__badge-icons--early_reviewer_program',
    VERIFIEDPURCHASER: 'ratings-reviews__badge-icons--recommended'
  };
  return classes[type];
};

const badgeToTextConverter = (type) => {
  const classes = {
    DIY: 'DIY',
    PRO: 'Pro',
    TOP1CONTRIBUTOR: '#1 Contributor',
    TOP10CONTRIBUTOR: 'Top 10 Contributor',
    TOP25CONTRIBUTOR: 'Top 25 Contributor',
    TOP50CONTRIBUTOR: 'Top 50 Contributor',
    TOP100CONTRIBUTOR: 'Top 100 Contributor',
    TOP250CONTRIBUTOR: 'Top 250 Contributor',
    TOP500CONTRIBUTOR: 'Top 500 Contributor',
    TOP1000CONTRIBUTOR: 'Top 1000 Contributor',
    EXPERT: 'Expert',
    COMMUNITYASSOCIATE: 'Community Associate',
    HOMEDEPOTASSOCIATE: 'Home Depot Associate',
    INCENTIVIZEDREVIEW: 'Seed Program Review',
    EARLYREVIEWERINCENTIVE: 'The Home Depot Reviewer Program',
    VERIFIEDPURCHASER: 'Verified Purchase'
  };
  return classes[type];
};

/** THD Review component. */
export const Review = ({
  apiKey, review, searchTerm, shortenReviews, category
}) => {
  // TODO: Fixme
  const media = review.videos ? review.photos.concat(review.videos) : review.photos;
  const [showMore, setShowMore] = useState(false);
  const [responseShowMore, setResponseShowMore] = useState(false);
  const {
    id, reviewText, selected, userNickname, authorId, productId, isSyndicated
  } = review;
  const { isServer, hosts, isConsumerApp } = useContext(ExperienceContext);
  const { useCondensedLayout, itemId } = usePresentation();
  const searchText = review.searchText || searchTerm;
  const serverHost = (hosts || {}).api;
  const hostName = isServer ? serverHost : '';
  const isCategoryStore = category.toLowerCase() === 'store';
  const reviewTextClasses = classNames('review-content-body', {
    'review-content-body--condensed': useCondensedLayout,
    'review-content-body--truncate': !showMore && ReviewHelper.shouldTruncate(reviewText),
    'review-content-body--expanded': showMore && ReviewHelper.shouldTruncate(reviewText)
  });
  const badgesOrder = useMemo(() => badgesFilterLogic(review.badgesOrder), [review.badgesOrder]);

  const reviewResponseClasses = classNames('review-client-response__content', {
    'review-client-response__content--collapsed': !responseShowMore
  });

  const reviewResponseTimeClasses = classNames('review-client-response__time', {
    'review-client-response__time--collapsed': !responseShowMore
  });

  const reviewItemClasses = classNames('review_item', {
    'review_item--condensed': useCondensedLayout
  });

  const reviewUserClasses = classNames('review-user__wrapper', {
    'review-user__wrapper--condensed': useCondensedLayout
  });

  const reviewStatusIconsClasses = classNames('review-status-icons__list', {
    'review-status-icons__list--condensed': useCondensedLayout
  });

  const syndicationSectionClasses = classNames('syndication-section', {
    'syndication-section--condensed': useCondensedLayout
  });

  const carouselSectionClasses = classNames({
    'carousel-section': review.isSyndicated,
    'carousel-section--condensed': useCondensedLayout
  });

  const columnSize = (nominalColSize, condensedColSize) => (useCondensedLayout ? condensedColSize : nominalColSize);

  const badgeWrapper = classNames('review-user__badge-wrapper', {
    'review-user__badge-wrapper--condensed': useCondensedLayout
  });

  const [overlayPageOpen, setOverlayPageOpen] = useState(false);
  // const [overlayPageContent, setOverlayPageContent] = useState(BadgeDefinitions);
  const [showUserOverlay, setShowUserOverlay] = useState(false);

  const openOverlayPage = () => {
    setOverlayPageOpen(true);
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'badge',
    });
  };

  const closeOverlayPage = () => {
    setOverlayPageOpen(false);
  };

  const handleUserOverlay = () => {
    setShowUserOverlay(true);
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'profile',
    });
  };

  return (
    <div key={`review_${review.id}`} className={reviewItemClasses}>
      <Row>
        <Col xs={columnSize(8, 12)} flatten={useCondensedLayout}>
          <Row>
            <Col xs="12">
              <span className="review-content__date">
                {ReviewHelper.formatSubmissionDate(review.submissionTime)}
              </span>
              <RatingMeter
                className="sui-p-0 sui-justify-start"
                value={review.rating}
                RatingProps={{
                  color: 'brand'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="review-content">
              <div className="">
                <span className="review-content__title">
                  {ReviewHelper.getHighlightedText(id, review.title, searchTerm)}
                </span>
                {shortenReviews && ReviewHelper.shouldTruncate(reviewText) ? (
                  // eslint-disable-next-line
                  <div
                    style={{
                      maxHeight: showMore
                        ? ReviewHelper.getCalculatedReviewHeight(reviewText) + 'px'
                        : '67px'
                    }}
                    className={reviewTextClasses}
                    id={`review_${id}`}
                    onClick={() => setShowMore(!showMore)}
                    data-testid="shortenReviews"
                  >
                    {ReviewHelper.getHighlightedText(id, reviewText, searchText)}
                  </div>
                ) : (
                  <div className="review-content-body">
                    {ReviewHelper.getHighlightedText(id, reviewText, searchText)}
                  </div>
                )}
                <div className="review-content__no-padding">
                  <span>by </span>
                  <button type="button" onClick={handleUserOverlay}>{review.userNickname}</button>
                </div>
                {(itemId !== productId && !isCategoryStore) && (
                  <QueryProvider cacheKey="product-attribution">
                    <ProductAttribution itemId={productId} />
                  </QueryProvider>
                )}
                {review.clientResponseText && (
                  <div className="review-client-response">
                    <div className="review-client-response__header">
                      <span className="review-client-response__title">
                        Response from {review.clientResponseName}
                        <button
                          className="review-client-response__toggle"
                          onClick={() => setResponseShowMore(!responseShowMore)}
                          type="button"
                        >
                          {responseShowMore ? 'Hide' : 'Show'}
                        </button>
                      </span>
                      <span className={reviewResponseTimeClasses}>
                        {ReviewHelper.formatSubmissionDate(review.clientResponseDate)}
                      </span>
                    </div>
                    <div
                      className={reviewResponseClasses}
                      // eslint-disable-next-line
                      dangerouslySetInnerHTML={{ __html: review.clientResponseText }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={columnSize(4, 12)} className={reviewUserClasses} flatten={useCondensedLayout}>
          <div className="review-user">
            <ul className={badgeWrapper}>
              {badgesOrder.map((key, index) => {
                return (
                  // TODO: make properly interactive
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={`badge_${review.id}_${index}`}
                    className="review-badge"
                  >
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <span
                      className="review-badge__content"
                      onClick={openOverlayPage}
                    >
                      <span
                        key={`review_${review.id}_badge_${index}`}
                        className={badgeToClassConverter(key.toUpperCase())}
                      />
                      <span className="ratings-reviews__badge-text--full">
                        {badgeToTextConverter(key.toUpperCase())}
                      </span>
                    </span>

                    {badgeLinks[key.toUpperCase()] && (
                      <a
                        className="review-rating-other-flags__what-text"
                        href={badgeLinks[key.toUpperCase()]}
                        target={isConsumerApp ? '_self' : '_blank'}
                        rel="noopener noreferrer"
                      >
                        (What&apos;s this?)
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
            {review.verifiedRecommendedValues.length > 0 ? (
              <div className="review-status-icons">
                <ul className={reviewStatusIconsClasses}>
                  <li key={`review_${review.id}_recommended`} className="review-status-icons__item">
                    <span className="ratings-reviews__badge-icons--recommended" />
                    <span className="ratings-reviews__badge-text--full">
                      {review.verifiedRecommendedValues}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
            <Col className={carouselSectionClasses}>
              <ReviewMediaCarousel media={media} review={review} />
            </Col>
            {review.isSyndicated && (
              <Col className={syndicationSectionClasses}>
                <span>
                  <Image src={review.syndicationSource.LogoImageUrl} alt="logo" lazy />
                  Customer review from {review.syndicationSource.Name}
                </span>
              </Col>
            )}
          </div>
        </Col>
        <Col xs="12" flatten className="helpful__wrapper">
          {!useCondensedLayout && (
            <Voting
              apiKey={apiKey}
              hostName={hostName}
              reviewId={review.id}
              isSyndicated={isSyndicated}
              totalNegativeFeedbackCount={review.TotalNegativeFeedbackCount}
              totalPositiveFeedbackCount={review.totalPositiveFeedbackCount}
              version="v2"
            />
          )}
        </Col>
      </Row>
      <OverlayPage
        overlayPageTitle="Home Depot Contributor Badges"
        OverlayPageContent={<BadgeDefinitions />}
        overlayPageOpen={overlayPageOpen}
        closeOverlayPage={closeOverlayPage}
      />
      <Overlay
        onClose={() => setShowUserOverlay(false)}
        open={showUserOverlay}
        positionedCloseButton
        closeButton
        medium
      >
        <UserProfile
          showUserOverlay={showUserOverlay}
          userNickname={userNickname}
          authorId={authorId}
        />
      </Overlay>
      <div className="clear" />
    </div>
  );
};

Review.displayName = 'Review';

Review.propTypes = {
  apiKey: string.isRequired,
  review: instanceOf(Object).isRequired,
  searchTerm: string,
  shortenReviews: bool,
  category: string
};

Review.defaultProps = {
  searchTerm: '',
  shortenReviews: false,
  category: ''
};
