import React, {
  useCallback, useContext, useState, useEffect, useRef
} from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';
import { Stepper } from '@one-thd/sui-atomic-components';

import { useSetTimeout } from '../../hooks/useSetTimeout';
import { FEATURE_SWITCH_KEYS } from '../../utils/constants';
import {
  buildUpdateCartRequest, generatePromoCartItemsObj, buildAddToCartRequest, calculateFulfillmentTypeOption
} from '../../utils/promo-cart-utils';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoCart } from '../../hooks/usePromoCart';

export const PromoQuantity = ({ itemId, setWarningText }) => {
  const { deliveryZip } = useContext(ExperienceContext);
  const {
    allListProductsAndAnchor,
    displayableFirstListItemIds,
    isForwardsB1gy,
    isForwardsBxg1,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria
  } = usePromoModel();
  const {
    promoCartItemsObj,
    CartModel,
    addToCart,
    updateCart,
    isCartLoading,
    freezeCart,
    firstListCartQuantity,
    secondListCartQuantity
  } = usePromoCart();
  const storeId = useStoreId();
  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;
  const product = allListProductsAndAnchor.get(itemId);
  const inCartQuantity = promoCartItemsObj?.[itemId]?.quantity || 0;
  const quantityLimit = product?.info?.quantityLimit || 0;
  const hasQuantityLimit = quantityLimit > 0; // quantityLimit comes back as 0 when there is no limit

  const isMounted = useRef(false);
  const inputFocused = useRef(false);
  const [inputValue, setInputValue] = useState(inCartQuantity);
  const [quantityTried, setQuantityTried] = useState(null);
  const [visible, setVisible] = useState(false);

  const QUANTITY_MIN = 0;
  const isQualifyingItem = displayableFirstListItemIds?.includes(itemId);
  const src1MinPurchaseQuantity = src1EligibilityCriteria?.minPurchaseQuantity;
  const tgt1MinPurchaseQuantity = tgt1EligibilityCriteria?.minPurchaseQuantity;
  const qualifyingItemMaxQty = (src1MinPurchaseQuantity - firstListCartQuantity)
    ? src1MinPurchaseQuantity : promoCartItemsObj?.[itemId]?.quantity;
  const rewardItemMaxQty = (tgt1MinPurchaseQuantity - secondListCartQuantity)
    ? tgt1MinPurchaseQuantity : promoCartItemsObj?.[itemId]?.quantity;
  const bxgyMaxQty = Number(isQualifyingItem ? qualifyingItemMaxQty : rewardItemMaxQty);
  const QUANTITY_MAX = isForwardsB1gy || isForwardsBxg1 ? bxgyMaxQty : 9999;

  const visibleClasses = classNames('sui-transition-opacity sui-duration-700', {
    'sui-opacity-0': !visible
  });

  useSetTimeout(() => {
    setVisible(true);
  }, 200);

  useEffect(() => {
    isMounted.current = true;

    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    setInputValue(inCartQuantity);
  }, [inCartQuantity]);

  const onCartQuantityChange = useCallback((value, forceUpdate) => {
    if (isCartLoading) return;

    let parsedValue = value;
    if (hasQuantityLimit && value > quantityLimit) {
      parsedValue = quantityLimit;
    } else if (value < QUANTITY_MIN) {
      parsedValue = QUANTITY_MIN;
    } else if (value > QUANTITY_MAX) {
      parsedValue = QUANTITY_MAX;
    }

    setInputValue(parsedValue);

    const hasDesiredQuantity = promoCartItemsObj?.[itemId]?.quantity === parsedValue;
    if (!forceUpdate && (hasDesiredQuantity || inputFocused.current)) {
      return;
    }

    const maxPurchasableQuantity = hasQuantityLimit ? Math.min(quantityLimit, parsedValue) : parsedValue;
    const CartRequestGraphQL = {
      newQuantity: maxPurchasableQuantity,
      item: promoCartItemsObj[itemId].itemDetails
    };

    const { CartRequest } = buildUpdateCartRequest({
      product,
      promoItemModel: promoCartItemsObj[itemId],
      desiredQuantity: maxPurchasableQuantity,
      storeId,
      deliveryZip,
    });
    const unFreezeCart = freezeCart();
    const CartRequestData = isCartGraphQLEnabled ? CartRequestGraphQL : CartRequest;
    const dataCheck = isCartGraphQLEnabled ? CartRequestGraphQL?.item : CartRequest.itemDetails.length;
    const updatePromise = dataCheck
      ? updateCart(CartRequestData)
      : Promise.resolve(CartModel);
    updatePromise
      .then((newCartModel) => {
        const promoCartItems = generatePromoCartItemsObj({
          itemModels: isCartGraphQLEnabled
            ? newCartModel?.data?.updateCart?.items || newCartModel?.data?.cartInfoLite?.items
            : newCartModel?.itemModels,
          allListProductsAndAnchor,
          isCartGraphQLEnabled,
          deliveryZip,
          storeId
        });
        const promoItemModel = promoCartItems[itemId];
        const desiredQuantity = maxPurchasableQuantity - (promoItemModel?.quantity || 0);
        // If after updating the already added fulfillments there is still more quantities to fulfill, add to cart
        if (desiredQuantity > 0) {
          const cartRequest = buildAddToCartRequest({
            desiredQuantity, promoItemModel, product, storeId, deliveryZip, isCartGraphQLEnabled
          });
          const fulfillmentOptionCount = calculateFulfillmentTypeOption(
            cartRequest.cartRequest, cartRequest.CartRequest
          );
          const fulfillmentRequestOption = isCartGraphQLEnabled
            ? cartRequest.cartRequest : cartRequest.CartRequest;
          if (fulfillmentOptionCount?.length) return addToCart(fulfillmentRequestOption);
        }

        return Promise.resolve(newCartModel);
      })
      .then((newCartModel) => {
        if (isMounted.current) {
          const promoCartItems = generatePromoCartItemsObj({
            itemModels: isCartGraphQLEnabled
              ? newCartModel?.data?.updateCart?.items || newCartModel?.data?.cartInfoLite?.items
              : newCartModel?.itemModels,
            allListProductsAndAnchor,
            isCartGraphQLEnabled,
            deliveryZip,
            storeId
          });
          const promoItemModel = promoCartItems[itemId];

          if (maxPurchasableQuantity > promoItemModel?.quantity) {
            // If quantity requested is larger than quantity in cart, there must have been limited fulfillment
            setWarningText(` There's only ${promoItemModel.quantity} in stock`);
            setInputValue(promoItemModel.quantity);
          } else if (hasQuantityLimit && quantityLimit < parsedValue) {
            // If limit on product is less than initial desired quantity, we know that there is a product limit
            setWarningText(` Limit ${quantityLimit} per order`);
          } else if (tgt1MinPurchaseQuantity >= secondListCartQuantity) {
            setWarningText(` Reached reward qty limit,  ${tgt1MinPurchaseQuantity} per promo`);
          }
        }
      })
      .finally(() => {
        unFreezeCart();
        if (isMounted.current) setQuantityTried(!quantityTried);
      });
  }, [
    QUANTITY_MAX,
    isCartGraphQLEnabled,
    secondListCartQuantity,
    tgt1MinPurchaseQuantity,
    addToCart,
    allListProductsAndAnchor,
    CartModel,
    deliveryZip,
    freezeCart,
    hasQuantityLimit,
    isCartLoading,
    itemId,
    product,
    promoCartItemsObj,
    quantityLimit,
    quantityTried,
    setWarningText,
    storeId,
    updateCart
  ]);

  return (
    <form
      className={visibleClasses}
      onSubmit={(event) => {
        event.preventDefault();
        inputFocused.current = false;
        const parsedRawInput = parseInt(inputValue || 0, 10);
        onCartQuantityChange(parsedRawInput, true);
      }}
    >
      <Stepper
        value={inputValue}
        disabled={isCartLoading}
        min={QUANTITY_MIN}
        max={hasQuantityLimit ? quantityLimit : QUANTITY_MAX}
        onBlur={() => {
          inputFocused.current = false;
          const parsedRawInput = parseInt(inputValue || 0, 10);
          onCartQuantityChange(parsedRawInput);
        }}
        onChange={onCartQuantityChange}
        onFocus={() => { inputFocused.current = true; }}
      />
    </form>
  );
};

PromoQuantity.displayName = 'PromoQuantity';

PromoQuantity.propTypes = {
  itemId: string.isRequired,
  setWarningText: func,
};

PromoQuantity.defaultProps = {
  setWarningText: () => { },
};
