/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext
} from 'react';
import {
  string,
  number,
  arrayOf,
  shape,
  element,
  bool,
} from 'prop-types';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getHydrationOptions, generateLayoutComponentClasses } from './utils';
import { componentsWithDefaultAspectRatios } from '../ComponentDictionaryEntries/Entries';
import styles from './layout-renderer.module.scss';

/**
 * Renders component if found in `components` list by name.
 *
 * Starts adding `Hydrator` wrapper for components below the fold
 *
 * Hydrator Exclusions:
 * `ComponentSelector` is not wrapped w/ `Hydrator` due to occurrences
 * where it gets "stuck" w/ placeholders.
*/
function renderComponent({
  componentProps,
  components,
  componentId,
  index,
  channel
}) {
  const hydrationOptions = getHydrationOptions(channel);
  const componentName = componentProps?.__typename;
  if (!components || !components[componentName]) return null;
  const Component = components[componentName];

  if (index > 6) {
    const { components: subComponents } = componentProps;

    const hasComponentSelector = Array.isArray(subComponents)
      ? subComponents?.some((node) => node?.__typename === 'ComponentSelector')
      : false;

    if (!hasComponentSelector) {
      return (
        <Hydrator
          id={`${componentId}-php-hydrator-${index}`}
          className={styles['u-height-100']}
          scrollBuffer={hydrationOptions.SCROLL_BUFFER}
          delay={hydrationOptions.HOMEPAGE_DELAY_STANDARD}
          placeholder={hydrationOptions.PLACEHOLDER}
        >
          <Component {...componentProps} />
        </Hydrator>
      );
    }
  }

  return <Component {...componentProps} />;
}

function getComponentPropsData(data, components, componentSelectorData) {
  const dataCopy = { ...data };

  delete dataCopy.sizes;
  delete dataCopy.originalPosition;
  delete dataCopy.styles;

  dataCopy.children = dataCopy?.children?.map(
    (child, layoutRendererPrerenderedChildIndex) => {

      const childCopy = { ...child };

      delete childCopy.sizes;
      delete childCopy.originalPosition;
      delete childCopy.styles;

      childCopy.layoutRendererPrerenderedChildIndex = layoutRendererPrerenderedChildIndex;

      if (childCopy.__typename === 'Image' && !childCopy.alt) {
        childCopy.alt = '';
      }

      if (childCopy?.customRenderId === 'ComponentSelector') {
        childCopy.variationIds = componentSelectorData.variationIds;
        childCopy.renderDefault = componentSelectorData.renderDefault;
        childCopy.mboxes = componentSelectorData.mboxes;
        childCopy.campaignIds = componentSelectorData.campaignIds;
        childCopy.innervateTags = componentSelectorData.innervateTags;
      }

      if (dataCopy.customRenderId in componentsWithDefaultAspectRatios) {
        childCopy.useDefaultAspectRatio = true;
      }

      if (dataCopy.customRenderId === 'B2B Marketing Gallery') {
        childCopy.useB2bStyles = true;
      }

      const renderId = child.customRenderId || child.__typename;

      if (!components[renderId]) {
        return null;
      }

      const Component = components[renderId];

      /* eslint-disable react/jsx-props-no-spreading */
      return <Component {...childCopy} />;
    },
  );

  return dataCopy;
}

export const LayoutSection = ({ layoutComponent, index, components, componentSelectorData }) => {
  const componentProps = getComponentPropsData(
    layoutComponent,
    components,
    componentSelectorData,
  );

  const componentId = layoutComponent?.id;

  const { channel } = useContext(ExperienceContext);

  const Component = renderComponent({
    componentProps,
    components,
    componentId,
    index,
    channel,
  }
  );

  if (!Component) {
    return (<></>);
  }
  const { minWidthMedium, maxWidthMedium, minWidthLarge } = layoutComponent.renderWidths;
  const layoutComponentClasses = generateLayoutComponentClasses(minWidthMedium, maxWidthMedium, minWidthLarge);

  return (
    <React.Fragment key={index}>
      <div
        className={layoutComponentClasses}
        key={componentId}
        data-testid="layout-section"
      >
        {Component}
      </div>
    </React.Fragment>
  );
};

LayoutSection.propTypes = {
  layoutComponent: shape({
    id: string,
    customRenderId: string,
    renderWidths: shape({
      minWidthMedium: number,
      maxWidthMedium: number,
      minWidthLarge: number,
    }),
  }).isRequired,
  index: number.isRequired,
  components: shape({
    [string]: element,
  }).isRequired,
  componentSelectorData: shape({
    variationIds: arrayOf(shape({ [string]: element })),
    renderDefault: bool,
    mboxes: arrayOf(string),
    campaignIds: arrayOf(shape({ [string]: element })),
    innervateTags: arrayOf(shape({ [string]: element }))
  }).isRequired,
};