/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, {
  useEffect, useContext
} from 'react';
import {
  string,
  number,
  arrayOf,
  shape,
  element,
  any,
  bool,
  func,
  oneOf,
} from 'prop-types';
import { useVariationIds } from '@thd-olt-component-react/personalization-utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Layout } from './Layout';
import styles from './layout-renderer.module.scss';
import useLayoutOrchestration from '../hooks/useLayoutOrchestration';

function selectMetadata(data, ssr) {
  return data?.seo && ssr ? data.seo : undefined;
}

const LayoutRenderer = ({
  layoutOperations,
  componentOptions,
  components,
  layoutDataModel,
  contentfulPreviewId,
  layoutClass,
  slug,
  loadingLayout: LoadingLayout,
  onContentInformationApiError,
  ssr,
  renderMetadata,
  customerType,
}) => {
  let variables;
  if (contentfulPreviewId) {
    variables = {
      id: contentfulPreviewId,
      layoutClass,
    };
  } else {
    variables = {
      slug,
    };
  }

  const layoutOptions = {
    componentOptions,
    myComponents: components,
  };

  if (layoutOperations?.length > 0) {
    layoutOptions.layoutOperations = layoutOperations;
  }

  const {
    data: layoutData,
    loading,
    error,
  } = useLayoutOrchestration({
    variables,
    layoutDataModel,
    layoutOptions,
    ssr,
    customerType,
  });

  const { channel } = useContext(ExperienceContext);

  const mboxes = layoutData?.mboxes || [];
  const campaignIds = layoutData?.campaignIds || [];
  const innervateTags = layoutData?.innervateTags || [];
  const isReady = layoutData?.isReady;

  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    innervateTags,
    isReady,
    channel,
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('layout-renderer.ready');
  }, []);

  if (error.server || error.client) {
    return onContentInformationApiError(error);
  }

  let heroCarouselData;

  if (layoutData?.components) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < layoutData.components.length; i++) {
      if (layoutData.components[i]?.customRenderId === 'Hero Carousel') {
        heroCarouselData = layoutData.components[i];
      }
    }
  }

  return (
    <>
      {layoutData?.seo && ssr
        ? renderMetadata(
          selectMetadata(layoutData, ssr),
          slug,
          heroCarouselData,
        )
        : <></>}
      <div>
        <div className={`${styles['layout-renderer']} sui-max-w-screen-2xl sui-m-auto sui-pb-12`}>
          {layoutData ? (
            <Layout
              components={components}
              layoutData={layoutData}
              componentSelectorData={{
                variationIds,
                renderDefault,
                mboxes: layoutData?.mboxes,
                campaignIds: layoutData?.campaignIds,
                innervateTags: layoutData?.innervateTags
              }}
              loading={loading}
              LoadingLayout={LoadingLayout}
            />
          ) : <></>}
        </div>
      </div>
    </>
  );
};

LayoutRenderer.propTypes = {
  components: shape({
    [string]: element,
  }).isRequired,
  layoutOperations: arrayOf(string),
  componentOptions: shape({
    [string]: shape({
      locked: shape({
        xPos: number,
        yPos: number,
      }),
      sizes: shape({
        minWidthMedium: {
          min: number,
          preferred: number,
          max: number,
        },
        minWidthLarge: {
          min: number,
          preferred: number,
          max: number,
        },
      }),
    }),
  }),
  slug: string,
  // eslint-disable-next-line react/forbid-prop-types
  layoutDataModel: any,
  loadingLayout: element,
  contentfulPreviewId: string,
  // eslint-disable-next-line react/forbid-prop-types
  layoutClass: oneOf(['PersonalizedHomePage', 'EducationalLayout']),
  onContentInformationApiError: func,
  ssr: bool,
  renderMetadata: func,
  customerType: string,
};

LayoutRenderer.defaultProps = {
  layoutOperations: [],
  componentOptions: {},
  layoutDataModel: {},
  slug: null,
  contentfulPreviewId: null,
  layoutClass: null,
  loadingLayout: null,
  onContentInformationApiError: () => null,
  ssr: false,
  renderMetadata: () => null,
  customerType: null
};

LayoutRenderer.displayName = 'LayoutRenderer';

export { LayoutRenderer, selectMetadata };
