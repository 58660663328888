import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Card } from '@one-thd/sui-atomic-components';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from './context';

export function SpecialBuyOfTheDay({ id }) {

  return (
    <ErrorBoundary name="sbotd">
      <QueryProvider cacheKey={`sbotd-php-${id}`}>
        <Card
          id={`sbotd-php-${id}`}
          className="sui-h-full"
          CardContentProps={{
            className: 'sui-h-full sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-4'
          }}
        >
          <SpecialBuyBanner specialBuyType="sbotd" entryId={id} />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

SpecialBuyOfTheDay.propTypes = {
  id: string,
};

SpecialBuyOfTheDay.defaultProps = {
  id: undefined,
};
