/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardTitle,
  CardMedia,
  CardBody,
  CardActions,
  Typography,
  Link,
  Button,
  ButtonGroup,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter
} from '@one-thd/sui-atomic-components';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { CARD_IMAGE } from '../../utils/constants';

const LinkAccounts = ({ linkAccounts, consumerCard, commercialCard, isMobile }) => {
  // On click term & condition -- open Drawer
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);

  const analyticsFeatureName = () => {
    if (commercialCard && consumerCard) {
      return 'plcc-link-accounts-multi-card';
    } if (commercialCard && !consumerCard) {
      return 'plcc-link-accounts-commercial';
    }
    return 'plcc-link-accounts';
  };

  const linkAccountClickHandler = () => {
    clickEvent(analyticsFeatureName());
    linkAccounts();
  };

  useEffect(() => {
    impressionEvent(analyticsFeatureName());
  }, []);

  const CardStack = () => {
    const cardStackClassName = consumerCard
      ? 'sui-relative sui-pb-8'
      : 'sui-relative sui-pb-4';
    if (commercialCard) {
      return (
        <div className={cardStackClassName}>
          <CardMedia
            className="sui-object-fill sui-w-10/12"
            src={CARD_IMAGE.PLNP_HOMEDEPOT}
            alt="Home Depot Commercial Credit Card"
            aspect="wide"
          />
          <CardMedia
            className="sui-object-fill sui-absolute sui-top-4 sui-left-4 sui-w-10/12"
            src={CARD_IMAGE.PLCR_HOMEDEPOT}
            alt="Home Depot Commercial Revolving Credit Card"
            aspect="wide"
          />
          {consumerCard && (
            <CardMedia
              className="sui-object-fill sui-absolute sui-top-8 sui-left-8 sui-w-10/12"
              src={CARD_IMAGE.PLCN_HOMEDEPOT}
              alt="Home Depot Consumer Credit Card"
              aspect="wide"
            />
          )}
        </div>
      );
    }
    return (
      <CardMedia
        className="sui-object-fill"
        src={CARD_IMAGE.PLCN_HOMEDEPOT}
        alt="Home Depot Consumer Credit Card"
        aspect="wide"
      />
    );
  };

  const CreditCardDetails = () => {
    return (
      <>
        <div className="sui-flex sui-justify-center sui-content-center sui-bg-subtle sui-px-10 sui-py-6">
          <CardStack />
        </div>
        <CardBody>
          <div className="sui-flex sui-flex-col sui-gap-4">
            <Typography variant="h6" weight="bold">
              Pay & Manage From One Account
            </Typography>
            <Typography variant="body-base">
              Make managing your card easier by linking your Citibank and Home Depot accounts.
              Once connected, you’ll be able to{' '}<span>pay your card and view your balance</span>{' '}
              from your HomeDepot.com account.
            </Typography>
            <Typography variant="body-xs" color="subtle">
              By clicking “Link Accounts” you agree to our{' '}
              <span className="sui-align-text-bottom">
                <Link
                  component="button"
                  onClick={handleOpenDrawer}
                  variant="body-xs"
                >
                  terms and conditions
                </Link>
              </span>{' '}
              and our{' '}
              <Link href="https://www.homedepot.com/c/Privacy_Security" target="_blank" variant="body-xs">
                privacy and security
              </Link>{' '}
              statement.
            </Typography>
          </div>
        </CardBody>
      </>
    );
  };

  return (
    <CardContent
      data-component="LinkAccounts"
      data-testid={openDrawer ? 'LinkAccountsModalOpen' : 'LinkAccountsModalClose'}
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">Home Depot Credit Cards</Typography>
        )}
      />
      <CreditCardDetails />
      <CardActions>
        <Button variant="secondary" fullWidth onClick={linkAccountClickHandler}>Link Accounts</Button>
      </CardActions>
      <Drawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        position="right"
      >
        <DrawerHeader title="Terms & Conditions" onClose={handleCloseDrawer} />
        <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
          <DrawerBody>
            <div className="sui-flex sui-flex-col sui-gap-4">
              <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                <div className="sui-flex sui-justify-center sui-content-center sui-px-10 sui-py-6">
                  <CardStack />
                </div>
              </div>
              <div className="sui-flex sui-flex-col sui-gap-2">
                <Typography variant="body-base" weight="bold">Pay & Manage From One Account</Typography>
                <Typography variant="body-base">
                  Make managing your card easier by linking your Citibank and Home Depot accounts.
                  Once connected, you’ll be able to{' '}<span>pay your card and view your balance</span>{' '}
                  from your HomeDepot.com account.
                </Typography>
              </div>
              <div className="sui-grow">
                <Typography variant="body-lg" weight="bold">Terms & Conditions</Typography>
                <ul className="sui-flex sui-flex-col sui-list-none sui-mt-2 sui-gap-2">
                  <li className="sui-flex sui-gap-2px">
                    <Typography variant="body-base" weight="bold">**</Typography>
                    <Typography variant="body-base" weight="bold">
                      By clicking “Link Accounts”, I acknowledge that I am linking my “My
                      Account” login and password to my Home Depot Consumer Credit Card
                      account. I understand this will allow anyone that has access to my
                      “My Account” account information on homedepot.com complete access to
                      my Home Depot Consumer Credit Card account. I understand that at any
                      time, I can unlink these accounts by changing my settings in my “My
                      Account” Account Profile on homedepot.com.
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <ButtonGroup orientation="vertical">
              <Button variant="primary" onClick={linkAccountClickHandler}>Link Accounts</Button>
              <Button variant="secondary" onClick={handleCloseDrawer}>Not Now</Button>
            </ButtonGroup>
          </DrawerFooter>
        </div>
      </Drawer>
    </CardContent>
  );
};

LinkAccounts.propTypes = {
  linkAccounts: PropTypes.func.isRequired,
  consumerCard: PropTypes.bool,
  commercialCard: PropTypes.bool,
  isMobile: PropTypes.bool,
};
LinkAccounts.defaultProps = {
  consumerCard: true,
  commercialCard: false,
  isMobile: false,
};

export default LinkAccounts;
