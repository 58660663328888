import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Card } from '@one-thd/sui-atomic-components';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function SpecialBuyOfTheWeek({ id }) {
  return (
    <ErrorBoundary name="sbotw">
      <QueryProvider cacheKey={`${id}-sbotw`}>
        <Card
          id={`sbotw-php-${id}`}
          className="sui-h-full"
          CardContentProps={{
            className: 'sui-h-full sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-4'
          }}
        >
          <SpecialBuyBanner specialBuyType="pro" entryId={id} />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

SpecialBuyOfTheWeek.propTypes = {
  id: string,
};

SpecialBuyOfTheWeek.defaultProps = {
  id: undefined,
};
