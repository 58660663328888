import React from 'react';
import { PromotionCardContainer as PromotionCardComponent } from '@thd-olt-component-react/promotion-card';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { string } from 'prop-types';

export function PromotionCard({ id }) {

  return (
    <ErrorBoundary name="promotion-card">
      <QueryProvider key={id} cacheKey="promotion-card">
        <PromotionCardComponent componentId={id} />
      </QueryProvider>
    </ErrorBoundary>
  );
}

PromotionCard.propTypes = {
  id: string.isRequired
};

PromotionCard.defaultProps = {};
