import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { PriceDrop as PriceDropComponent } from '@thd-olt-component-react/thd-recs-containers';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function PriceDrop({ REQUEST_KEY, id, brandTitleMaxLine, slidesPer }) {
  const { channel } = useContext(ExperienceContext);

  const priceDropCardTitle = 'New Lower Prices on Your Items';

  return (
    <ErrorBoundary name="pricedrop-recognized-homepage">
      <QueryProvider cacheKey="pricedrop-recognized-homepage">
        <Card
          id={`price-drop-php-${id}`}
          className="sui-h-full sui-leading-none"
          CardContentProps={{ className: 'sui-flex sui-flex-col sui-w-full sui-h-full sui-gap-4 sui-p-4' }}
        >
          <CardTitle header={
            <Typography variant="h3">{priceDropCardTitle}</Typography>
          }
          />
          <PriceDropComponent
            hideTitle
            showFallback
            showLoading
            requestKey={REQUEST_KEY}
            brandTitleMaxLine={brandTitleMaxLine}
            slidesPer={slidesPer}
            entryId={id}
            containerName={priceDropCardTitle}
          />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

PriceDrop.propTypes = {
  REQUEST_KEY: string,
  brandTitleMaxLine: number,
  slidesPer: number,
  id: string,
};

PriceDrop.defaultProps = {
  REQUEST_KEY: undefined,
  brandTitleMaxLine: undefined,
  slidesPer: undefined,
  id: undefined,
};
