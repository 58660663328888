import React from 'react';
import { func } from 'prop-types';
import { DrawerBody, DrawerFooter, Typography } from '@one-thd/sui-atomic-components';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useMessage } from '../../../hooks/useMessage';
import { usePromoDrawerModel } from '../../../hooks/usePromoDrawerModel';
import { QuickviewOverviewDrawerDataModel } from '../../../models/QuickviewOverviewDrawerDataModel';
import { MESSAGE_KEY, PRODUCT_CTA_TYPE } from '../../../utils/constants';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';
import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';
import { SeeFullDetailsButton } from '../../SeeFullDetailsButton/SeeFullDetailsButton';
import { PromoSelectCta } from '../../PromoSelectCta/PromoSelectCta';
import { AddToCartCta } from '../../AddToCartCta/AddToCartCta';
import { useProduct } from '../../../hooks/useProduct';
import { usePromoModel } from '../../../hooks/usePromoModel';

export const QuickviewOverviewDrawer = ({ onBack, onClose }) => {
  const { quickviewItemDisplayPosition, quickviewItemId } = usePromoDrawerModel();
  const {
    isSoldOut,
    productCtaType,
    selected,
    selectProduct
  } = useProduct(quickviewItemId, quickviewItemDisplayPosition);
  const { type, anchorItemId } = usePromoModel();
  const title = useMessage(MESSAGE_KEY.title);
  const isBogoCardAnchorSku = type !== 'card' && quickviewItemId !== anchorItemId;
  const showAddToCartCta = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.addToCart;
  const showPromoSelectButton = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.select;

  const { data } = useDataModel('product', {
    variables: {
      itemId: quickviewItemId,
    },
  });

  const description = data?.product?.details?.description || '';
  const descriptiveAttributes = data?.product?.details?.descriptiveAttributes || [];

  const showDescriptiveAttributes = descriptiveAttributes.length > 0;

  return (
    <>
      <PromoDrawerHeader
        onBack={onBack}
        onClose={onClose}
        title={title}
      />
      <DrawerBody>
        <article className="sui-flex sui-flex-col sui-gap-4">
          <Typography>{description}</Typography>
          {showDescriptiveAttributes && (
            <ul className="sui-pl-4 sui-pr-1 sui-flex sui-flex-col sui-gap-2">
              {descriptiveAttributes.map((highlight, i) => (
                <li
                  key={`promo-quickview-overview-highlights__highlight-${i}`}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: highlight.value }}
                />
              ))}
            </ul>
          )}
        </article>
      </DrawerBody>
      <DrawerFooter data-testid="quickview-drawer-footer">
        <ButtonGroup>
          <SeeFullDetailsButton fullWidth itemId={quickviewItemId} />
          {showPromoSelectButton && (
            <PromoSelectCta
              isSoldOut={isSoldOut}
              itemId={quickviewItemId}
              onSelect={selectProduct}
              selected={selected}
            />
          )}
          {showAddToCartCta && (
            <AddToCartCta
              align="center"
              displayPosition={quickviewItemDisplayPosition}
              itemId={quickviewItemId}
            />
          )}
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

QuickviewOverviewDrawer.displayName = 'QuickviewOverviewDrawer';

QuickviewOverviewDrawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired
};

QuickviewOverviewDrawer.dataModel = QuickviewOverviewDrawerDataModel;
