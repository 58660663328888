export const useNewRelicLogging = () => {
  const sendDataToNewRelic = (props) => {
    const {
      actionName, callType, experienceTag, subExperience, anchorItemId
    } = props;
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      callType,
      experienceTag,
      subExperience,
      anchorItemId
      // promotionId, // TODO: promo-expansion
    });
  };
  const sendBogoCardDataToNewRelic = (props) => {
    const {
      appName, actionName, customerType,
      // eslint-disable-next-line no-shadow
      segment, experience, subExperience, anchorItemId, rewardItemId
    } = props;
    const graphqlVariables = {
      mcvisId: typeof window !== 'undefined'
        ? window?.THDCustomer?.default?.mcvisID : null,
      anchorItemSku: anchorItemId,
      rewardItemSku: rewardItemId
    };
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      appName,
      customerType,
      experience,
      subExperience,
      segment,
      ...graphqlVariables,
      anchorItemId
      // promotionId, // TODO: promo-expansion
    });
  };
  const sendErrorsToNewRelic = (props) => {
    const {
      actionName, errorMessage, callType,
      experienceTag, subExperience, anchorItemId
    } = props;
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      error: errorMessage,
      callType,
      experienceTag,
      subExperience,
      anchorItemId
      // promotionId, // TODO: promo-expansion
    });
  };

  const sendBogoCardErrorsToNewRelic = (props) => {
    const {
      actionName, errorMessage, appName, customerType,
      segment, experience,
    } = props;
    const graphqlVariables = {
      mcvisId: typeof window !== 'undefined'
        ? window?.THDCustomer?.default?.mcvisID : null,
      anchorItemSku: null
    };
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      error: errorMessage,
      appName,
      customerType,
      experience,
      segment,
      ...graphqlVariables,
      // promotionId, // TODO: promo-expansion
    });
  };

  return { sendDataToNewRelic, sendErrorsToNewRelic, sendBogoCardDataToNewRelic, sendBogoCardErrorsToNewRelic };
};
