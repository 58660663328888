import React, { useCallback, useContext, useRef } from 'react';
import {
  oneOf,
  bool as boolType,
  number as numberType,
  string as stringType
} from 'prop-types';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Orders } from '@one-thd/sui-icons';
import { ProductCarousel } from '../ProductCarousel';
import { getCustomerData, getSkippers, getAppId } from '../../utils/helpers';
import { priceDropDataModel } from '../../dataModel';
import { FallbackCard } from '../../core/FallbackCard';

const PriceDrop = (props) => {
  const {
    brandTitleMaxLine,
    customerID,
    customerIsLoggedIn,
    hideTitle,
    requestKey,
    showLoading,
    slidesPer,
    showFallback,
    hideATC,
    noATCFulfillment,
    hideSavingsText,
    hideBulkPrice,
    entryId,
    containerName,
  } = props;

  const { isLocalized, storeId, storeZip: zipCode } = useStore();
  const { channel, isConsumerApp } = useContext(ExperienceContext) || {};
  const innerQueryPrerequisites = useRef({ storeId, zipCode });

  const {
    customerType: isLoggedIn, mcvisID, svocID
  } = getCustomerData();

  const custIsLoggedIn = customerIsLoggedIn || isLoggedIn;
  const custID = customerID || (custIsLoggedIn ? svocID : mcvisID);

  const apiName = 'pricedrop';

  const udsQueryOptions = {
    variables: {
      apiName,
      appId: getAppId(channel, isConsumerApp),
      dataSource: apiName,
      key: requestKey,
      storeId,
      userId: custID || '',
      zipCode,
      ...getSkippers({}),
    },
    ssr: false,
  };

  const { loading, data, error, variables } = useDataModel('uds', udsQueryOptions);

  if (data && isLocalized) {
    innerQueryPrerequisites.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true,
    };
  }

  if (error || (!data?.uds?.products?.length && !loading)) {
    if (showFallback) {
      return (
        <FallbackCard
          header="No Lower Prices On Your Items Right Now"
          subheader="Save more items to track when the prices drop,
          or check back later to see if any of your current saved items have dropped in price"
          componentName="PriceDrop"
          icon={Orders}
        />
      );
    }

    return null;
  }

  const impressionData = {
    id: entryId || '',
    name: containerName || '',
    component: PriceDrop.displayName,
    type: 'product',
    position: '',
    category: ''
  };

  return (
    <ImpressionProvider data={impressionData}>
      <div id={apiName} data-type="container" data-component="PriceDrop">
        <meta data-prop="name" content={apiName} />
        <ProductCarousel
          data={data?.uds}
          hideBulkPrice={hideBulkPrice}
          brandTitleMaxLine={brandTitleMaxLine}
          hideTitle={hideTitle}
          hideATC={hideATC}
          hideSavingsText={hideSavingsText}
          itemClass="fixed-item"
          loading={loading}
          showLoading={showLoading}
          storeId={storeId}
          zipCode={zipCode}
          slidesPer={slidesPer}
          scheme={apiName}
          noATCFulfillment={noATCFulfillment}
          hideControls={data?.uds?.products?.length <= 2}
        />
      </div>
    </ImpressionProvider>
  );
};

PriceDrop.dataModel = priceDropDataModel;

PriceDrop.displayName = 'PriceDrop';

PriceDrop.propTypes = {
  // product pod takes only these prop values for brandTitleMaxLine
  brandTitleMaxLine: oneOf([2, 3, 4, null]),
  // svocid or mcvisid
  customerID: stringType,
  customerIsLoggedIn: stringType,
  hideTitle: boolType,
  requestKey: stringType.isRequired,
  showLoading: boolType,
  slidesPer: numberType,
  showFallback: boolType,
  hideATC: boolType,
  noATCFulfillment: boolType,
  hideSavingsText: boolType,
  hideBulkPrice: boolType,
  entryId: stringType,
  containerName: stringType
};

PriceDrop.defaultProps = {
  brandTitleMaxLine: 2,
  customerID: null,
  customerIsLoggedIn: null,
  hideTitle: false,
  showLoading: false,
  slidesPer: 2.2,
  showFallback: false,
  hideATC: true,
  noATCFulfillment: null,
  hideSavingsText: false,
  hideBulkPrice: true,
  entryId: null,
  containerName: null
};

export { PriceDrop };