/* eslint-disable consistent-return */
import ComponentMetadata, { DEFAULT_SIZES } from '../ComponentDictionaryEntries/Entries';

const getSizesMetadata = (type) => {
  return ComponentMetadata[`${type}Metadata`]?.sizes || DEFAULT_SIZES;
};

export const getComponent = (item, index, key) => {
  const {
    id, exclude, __typename, components, position, customRenderId
  } = item || {};
  const type = __typename || key;
  const { xPos = 0, yPos = 0, weight = 0.1 } = position || {};
  const itemCopy = JSON.parse(JSON.stringify(item));

  let componentProps = {
    ...itemCopy,
    id: `${id || index + type + 'Component'}`,
    __typename: type, // we keep this for now as a fallback, in case we don't have a render func for `customRenderId`
    customRenderId: customRenderId || type,
    exclude,
    position: {
      weight,
      xPos,
      yPos,
    },
    sizes: getSizesMetadata(__typename),
  };

  if (!componentProps.__typename) return null;

  if (components && components.length > 0) {
    componentProps.children = components.map((component) => getComponent(component, index));
  }

  return componentProps;
};

export const getComponentsFromItems = (items, idx) => {
  let arrOfData = [];

  items.forEach((component, index) => {
    const itemCopy = JSON.parse(JSON.stringify(component));
    const {
      id, position, exclude, __typename, components, customRenderId
    } = component || {};
    const { xPos = 0, yPos = 0, weight = 0.1 } = position || {};

    let componentProps = {
      ...itemCopy,
      id: `${id || index + __typename + 'Component'}`,
      __typename,
      customRenderId: customRenderId || __typename,
      exclude,
      position: {
        weight,
        xPos,
        yPos,
      },
      sizes: getSizesMetadata(__typename),
    };

    if (components && components.length > 0) {
      componentProps.children = components.map((comp) => getComponent(comp, idx));
    }

    arrOfData.push({
      ...componentProps,
    });
  });

  return arrOfData;
};

export const transformDataFromContentful = (arr = [], rawData) => {
  let arrOfData = arr;
  if (!rawData) return null;
  Object.entries(rawData).forEach(([key, value], idx) => {
    if (!value) return null;
    if (typeof value === 'string') {
      arrOfData.push(
        getComponent(
          {
            value,
            id: `${key}`,
            __typename: key,
          },
          idx,
          key,
        ),
      );
      return;
    }

    if (value?.components) {
      const val = getComponent(value, idx, key);
      if (val) {
        arrOfData.push(val);
      }
    } else if (Array.isArray(value)) {
      arrOfData.push(...getComponentsFromItems(value, idx));
    } else if (key === 'internalMarketingBanner') {
      // to make internalMarketingBanner the actual customRenderId
      const newVal = { ...value, customRenderId: key };
      arrOfData.push(getComponent(newVal, idx, key));
    } else {
      transformDataFromContentful(arrOfData, value);
    }
  });
  return arrOfData;
};
