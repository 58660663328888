import React, { useContext, useMemo } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classnames from 'classnames';

import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';

import { AddItemsToCartDataModel } from '../../models/AddItemsToCartDataModel';
import { useMessage } from '../../hooks/useMessage';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { MA, MESSAGE_KEY, FEATURE_SWITCH_KEYS } from '../../utils/constants';
import {
  buildAddToCartRequest,
  buildAddToCartOverlayOptions,
  calculateFulfillmentTypeOption
} from '../../utils/promo-cart-utils';
import { noop } from '../../utils/promo-utils';

export const AddMultipleToCartButton = ({
  fullWidth,
  itemIds,
  onComplete: onCompleteProp,
  showIcon,
  simpleATCMessage
}) => {
  const { channel, deliveryZip } = useContext(ExperienceContext);
  const {
    bogoSelectedProduct,
    promoCartItemsObj,
    isCartLoading,
    setIsCartLoading,
    setSelectedItemsModel,
  } = usePromoCart();
  const {
    allListProductsAndAnchor,
    analyticsSharedSection,
    isMultiSelect,
    applianceDeliveryStore,
    isBogo,
  } = usePromoModel();
  const storeId = useStoreId();
  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;
  const addToCartButtonText = useMessage(
    MESSAGE_KEY.addToCartButton,
    simpleATCMessage || isBogo ? null : itemIds.length
  );

  const cartReqParams = itemIds.map((itemId) => {
    if (!allListProductsAndAnchor.get(itemId)) return null;
    const { cartRequest, CartRequest } = buildAddToCartRequest({
      product: allListProductsAndAnchor.get(itemId),
      promoItemModel: promoCartItemsObj[itemId],
      desiredQuantity: 1,
      storeId,
      applianceDeliveryStore,
      deliveryZip,
      isCartGraphQLEnabled
    });

    return calculateFulfillmentTypeOption(cartRequest, CartRequest);
  }).filter((itemDetail) => !!itemDetail).flat(1);

  const bogoCartItemDetails = useMemo(() => {
    if (!bogoSelectedProduct) return [];

    const { cartRequest, CartRequest } = buildAddToCartRequest({
      product: bogoSelectedProduct,
      promoItemModel: promoCartItemsObj[bogoSelectedProduct.itemId],
      desiredQuantity: 1,
      storeId,
      deliveryZip,
      isCartGraphQLEnabled
    });

    return calculateFulfillmentTypeOption(cartRequest, CartRequest);
  }, [bogoSelectedProduct, promoCartItemsObj, storeId, deliveryZip, isCartGraphQLEnabled]);

  const addToCartOptions = useMemo(() => {
    const bogoItemDetails = bogoCartItemDetails[0];
    let itemsOverride = {};

    if (bogoItemDetails) {
      if (isCartGraphQLEnabled) {
        itemsOverride = {
          items: {
            itemId: bogoItemDetails.itemId,
            quantity: bogoItemDetails.quantity,
          }
        };
      } else {
        itemsOverride = {
          itemDetails: {
            itemId: bogoItemDetails.itemId,
            fulfillmentLocation: bogoItemDetails.fulfillmentLocation,
            fulfillmentMethod: bogoItemDetails.fulfillmentMethod,
            quantity: bogoItemDetails.quantity,
          }
        };
      }
    }

    const overrides = {
      ...itemsOverride,
      sharedSection: analyticsSharedSection,
      component: 'add both',
      ...(isCartGraphQLEnabled && itemIds.some((itemId) => {
        return allListProductsAndAnchor.get(itemId).identifiers?.productType === MA;
      }) && { filterItem: false }
      )
    };

    return buildAddToCartOverlayOptions(channel, overrides);
  }, [
    bogoCartItemDetails,
    analyticsSharedSection,
    channel,
    allListProductsAndAnchor,
    isCartGraphQLEnabled,
    itemIds
  ]);

  const onClick = () => {
    const hasNonApplianceItem = itemIds.some((itemId) => {
      return allListProductsAndAnchor.get(itemId).identifiers.productType !== MA;
    });

    if (!isMultiSelect && hasNonApplianceItem) setIsCartLoading(true);
  };

  const onComplete = () => {
    setSelectedItemsModel([]);
    setIsCartLoading(false);
    onCompleteProp();
  };

  const addToCartButtonClasses = classnames({ 'sui-w-full': fullWidth });

  // Wrapper div is due to an issue within AddToCart where there is another unnecessary wrapper div
  return (
    <div className={addToCartButtonClasses}>
      <AddToCart
        cartReqParams={cartReqParams}
        cartOptions={addToCartOptions}
        disabled={isCartLoading || !itemIds.length}
        fullWidth={fullWidth}
        onClick={onClick}
        onError={onComplete}
        onFail={onComplete}
        onSuccess={onComplete}
        showIcon={showIcon}
      >
        {addToCartButtonText}
      </AddToCart>
    </div>
  );
};

AddMultipleToCartButton.displayName = 'AddMultipleToCartButton';

AddMultipleToCartButton.propTypes = {
  fullWidth: bool,
  itemIds: arrayOf(string),
  onComplete: func,
  showIcon: bool,
  simpleATCMessage: bool,
};

AddMultipleToCartButton.defaultProps = {
  fullWidth: true,
  itemIds: [],
  onComplete: noop,
  showIcon: true,
  simpleATCMessage: false,
};

AddMultipleToCartButton.dataModel = AddItemsToCartDataModel;
