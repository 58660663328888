import React from 'react';
import { bool, string } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';

import { useMessage } from '../../hooks/useMessage';
import { usePromoModel } from '../../hooks/usePromoModel';
import { MESSAGE_KEY } from '../../utils/constants';

export const SeeFullDetailsButton = ({ fullWidth, itemId }) => {
  const { allListProductsAndAnchor } = usePromoModel();
  const seeFullDetailsButtonText = useMessage(MESSAGE_KEY.seeFullDetailsButton);
  const product = allListProductsAndAnchor.get(itemId);
  const url = product.identifiers.canonicalUrl || '';

  return (
    <Button
      fullWidth={fullWidth}
      href={url}
      variant="secondary"
    >
      {seeFullDetailsButtonText}
    </Button>
  );
};

SeeFullDetailsButton.displayName = 'SeeFullDetailsButton';

SeeFullDetailsButton.propTypes = {
  fullWidth: bool,
  itemId: string.isRequired,
};

SeeFullDetailsButton.defaultProps = {
  fullWidth: false
};
