import * as React from 'react';
import { Alert, Typography, Button } from '@one-thd/sui-atomic-components';
import { PacDrawer, LoadingPacDrawer } from '../../common/Drawer/PacDrawer/PacDrawer';
import { DetailsMarkdown } from '../../common/Drawer/DetailsMarkdown';
import { ExpireLine } from '../../common/Drawer/ExpireLine';
import { DrawerBarcode } from '../../common/Drawer/Barcode';
import { PacDrawerContext } from '../../contexts/PacDrawerContext';
import { findPerkByPerkId } from '../../common/utils/ContextUtils';
import { windowExists } from '../../common/utils/AccountUtils';
import { formatRewardTitle, getRewardAttributes, getHostURL, getCookieProperty } from '../../common/utils/DrawerUtils';
import { getImageURL } from '../../common/utils/cardUtils';
import { useFetchActivatePerks } from '../../hooks';
import { HEADER_TEXT, SUBTITLE_TEXT, FOOTER_BUTTON_TEXT, SELECTION_CONSTANTS } from '../../core/DrawerConstants';

export const ErrorActivationDrawer = ({ refreshFn, handleOnClose, handleOnBack }) => {
  // TODO: [Kips] Pass in title, header, and icon based on .earned
  const { setOpen } = React.useContext(PacDrawerContext) ?? {};
  const header = SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.HEADER;
  const title = SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.TITLE;
  const iconImgUrl = getImageURL(SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.ICON_PATH);
  const onClose = () => setOpen(false);
  const Refresh = () => <button type="button" className="sui-underline" onClick={refreshFn}>Refresh</button>;
  const Content = () => (
    <>
      <Typography variant="body-base" weight="bold">{SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.HEADER}</Typography>
      <div className="sui-h-6" />
      <Alert status="error">{SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.MSG}<br /><Refresh /></Alert>
      <div className="sui-h-20" />
    </>
  );
  return (
    <PacDrawer
      headerText={header}
      onClose={handleOnClose || onClose}
      OnBack={handleOnBack}
      iconImgUrl={iconImgUrl}
      title={title}
      Content={Content}
    />
  );
};

const useCheckAndFetchOffers = () => {
  const { infoOffers } = React.useContext(PacDrawerContext);
  const checkAndFetchPerks = React.useCallback(() => {
    if (!infoOffers.infoOffersData) infoOffers.getOffersResponse();
  }, []);
  return { checkAndFetchPerks };
};

export const UnclaimedOfferDrawer = (props) => {
  const { infoOffers, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { checkAndFetchPerks } = useCheckAndFetchOffers();
  const { eventOutput: eventOutputFromProps, handleOnClose, handleOnBack } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const { perkObj } = eventOutputSource ?? {};
  const { programId: programIdFromEvent, perkId } = perkObj ?? {};
  const programId = programIdFromEvent ?? infoOffers?.infoOffersData?.program?.programId;

  React.useEffect(() => {
    if (!programId) checkAndFetchPerks();
  }, []);

  const perkActivationRequest = React.useMemo(() => ({
    perkId,
    programId,
  }), [programId]);
  const {
    isLoadingActivatePerks,
    activatePerksError,
    activatePerksData,
    getActivateResponse
  } = useFetchActivatePerks({ perkActivationRequest });

  React.useEffect(() => {
    if (programIdFromEvent || (programId && !infoOffers?.isLoadingInfoOffers)) getActivateResponse();
  }, [programId, infoOffers?.isLoadingInfoOffers]);

  const isLoading = (!programId && infoOffers?.isLoadingInfoOffers) || (programId && isLoadingActivatePerks);
  const hasInfoOffersError = !infoOffers?.isLoadingInfoOffers
    && (infoOffers?.infoOffersError || !perkActivationRequest.programId);
  const hasActivatePerksError = !isLoadingActivatePerks && (activatePerksError || !activatePerksData?.paymentId);

  if (isLoading) return <LoadingPacDrawer headerText="New Offer Unlocked" />;
  if (hasInfoOffersError) {
    return (
      <ErrorActivationDrawer
        handleOnClose={handleOnClose}
        handleOnBack={handleOnBack}
        refreshFn={infoOffers?.refreshInfoOffers}
      />
    );
  }
  if (hasActivatePerksError) {
    return (
      <ErrorActivationDrawer
        handleOnClose={handleOnClose}
        handleOnBack={handleOnBack}
        refreshFn={getActivateResponse}
      />
    );
  }

  const activatedOffer = {
    ...perkObj,
    perkStatus: 'ACTIVE',
    paymentId: activatePerksData?.paymentId,
    activationTime: activatePerksData?.activationTime,
    expirationTime: activatePerksData?.expirationTime,
  };

  if (windowExists()) {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.selection-confirmation-drawer__close',
      {},
    );
  }

  return (
    <RewardBarcodeDrawer
      perkObj={activatedOffer}
      eventOutput={eventOutputSource}
      isNewlyActivated
    />
  );
};

export const RewardBarcodeDrawer = (props) => {
  const {
    open, setOpen, info, infoPerks, infoOffers, eventOutput
  } = React.useContext(PacDrawerContext) ?? {};

  const { perkObj: perkObjFromProps, eventOutput: eventOutputFromProps, isNewlyActivated } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const isOpen = open || eventOutputSource?.drawerOpen;

  const {
    perkObj, perkId, perkList, tierList, onBack, onClose, onNewlyActivated
  } = eventOutputSource ?? {};

  let perk = perkObjFromProps ?? perkObj;
  if (!perk && perkId && (perkList || !infoOffers?.isLoadingInfoOffers)) {
    perk = findPerkByPerkId(perkId, perkList ?? infoOffers?.nonProgramPerks, 'OFFER');
  }
  if (!perk && perkId && (tierList || !infoOffers?.isLoadingInfoPerks)) {
    perk = findPerkByPerkId(perkId, tierList ?? infoPerks?.infoPerksData?.program?.tiers, 'PERK');
  }

  React.useEffect(() => {
    if (windowExists()) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('loyalty-benefits.reward-barcode-drawer__open', {});
    }
  }, []);

  const handleOnClose = () => {
    setOpen(false);
    if (isNewlyActivated) onNewlyActivated();
    if (onClose) onClose();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.reward-barcode-drawer__close',
      {},
    );
  };
  const handleOnBack = () => {
    setOpen(false);
    if (isNewlyActivated) onNewlyActivated();
    if (onBack) onBack();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.reward-barcode-drawer__back',
      {},
    );
  };

  if (perk?.offerType === 'OFFER' && perk?.perkStatus === 'UNCLAIMED') {
    return (
      <UnclaimedOfferDrawer
        eventOutput={eventOutputSource}
        handleOnBack={handleOnBack}
        handleOnClose={handleOnClose}
      />
    );
  }

  const currentReward = perk;
  const { isPerk, isGiftCard } = getRewardAttributes(currentReward);
  const header = isPerk ? HEADER_TEXT.PERK_DETAILS : HEADER_TEXT.OFFER;
  const title = formatRewardTitle(currentReward);
  const subTitle = isPerk ? SUBTITLE_TEXT.ACTIVE_PERK : SUBTITLE_TEXT.OFFER;
  const cartCount = getCookieProperty('THD_PERSIST', 'C6');
  const showToCheckoutButton = cartCount > 0 && currentReward && isGiftCard;

  const content = () => {
    return (
      <div>
        <DrawerBarcode currentRewardDetails={currentReward} isOpen={isOpen} />
        {currentReward && (
          <>
            <div className="sui-mb-8">
              <ExpireLine expiration={currentReward?.expirationTime} />
            </div>
            <DetailsMarkdown markdownText={currentReward?.perkDescription} />
          </>
        )}
      </div>
    );
  };

  const toCheckoutButton = () => {
    return (
      <Button fullWidth href={getHostURL('cart')}>
        {FOOTER_BUTTON_TEXT.PXD}
      </Button>
    );
  };

  return (
    <PacDrawer
      headerText={header}
      iconImgUrl={currentReward?.perkImageUrl}
      title={currentReward ? title : null}
      subTitle={currentReward ? subTitle : null}
      onBack={onBack ? handleOnBack : null}
      onClose={handleOnClose}
      Content={content}
      Footer={showToCheckoutButton ? toCheckoutButton : null}
    />
  );
};
