import React from 'react';
import { arrayOf, func, node, string } from 'prop-types';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';

import { PromoPod } from '../PromoPod/PromoPod';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { noop } from '../../utils/promo-utils';
import { PromoListDataModel } from '../../models/PromoListDataModel';
import { PromoPodDataModel } from '../../models/PromoPodDataModel';
import './PromoList.scss';

const HoistingWrapper = ({ children }) => <>{children}</>;
HoistingWrapper.propTypes = { children: node.isRequired };
HoistingWrapper.dataModel = extend(PromoPodDataModel);

export const PromoList = ({
  itemIds,
  listText,
  onProductPodClick,
}) => {
  const storeId = useStoreId();
  const { type } = usePromoPresentation();
  const {
    anchorItem,
    isAwaitingData,
    hasAnchorFromSearch,
    isBmsm,
    isCategorizedPromotion
  } = usePromoModel();
  const showAnchorAtTop = type === 'card' || isBmsm && !isCategorizedPromotion;
  const showSpinner = isAwaitingData;

  return (
    <HoistingWrapper>
      <article
        className="promo-list sui-flex sui-flex-col sui-gap-1 sui-w-auto sui-overflow-y-auto"
        data-testid="promo-list"
      >
        {showAnchorAtTop && (
          <PromoPod
            displayPosition={0}
            itemId={anchorItem?.itemId}
            key={anchorItem?.itemId}
            onProductPodClick={onProductPodClick}
            hideCta={type === 'card'}
          />
        )}
        {listText && (
          <div data-testid="promo-list-text">
            {listText}
          </div>
        )}
        {showSpinner ? (
          <div className="sui-m-5 sui-justify-center sui-items-center sui-flex sui-h-auto sui-w-auto">
            <span className="promo-spinner" />
          </div>
        ) : (
          <div className="sui-flex sui-flex-col sui-gap-1 sui--ml-2">
            {itemIds.map((itemId, i) => {
              const shouldUseCatalogForAnchor = Boolean(
                itemId === anchorItem?.itemId && !hasAnchorFromSearch
              );
              return shouldUseCatalogForAnchor
                ? (
                  <QueryProvider
                    // must line up with cache for the anchor item
                    cacheKey="promotion-anchor-item"
                    defaultVariables={{ storeId }}
                  >
                    <PromoPod
                      displayPosition={showAnchorAtTop ? i + 2 : i + 1}
                      itemId={itemId}
                      key={itemId}
                      onProductPodClick={onProductPodClick}
                    />
                  </QueryProvider>
                ) : (
                  <PromoPod
                    displayPosition={showAnchorAtTop ? i + 2 : i + 1}
                    itemId={itemId}
                    key={itemId}
                    onProductPodClick={onProductPodClick}
                  />
                );
            })}
          </div>
        )}
      </article>
    </HoistingWrapper>
  );
};

PromoList.displayName = 'PromoList';

PromoList.propTypes = {
  itemIds: arrayOf(string),
  listText: node,
  onProductPodClick: func
};

PromoList.defaultProps = {
  itemIds: [],
  listText: null,
  onProductPodClick: noop
};

PromoList.dataModel = PromoListDataModel;
