/* eslint-disable no-restricted-syntax */
import { useDataModel } from '@thd-nucleus/data-sources';
import { formatLayoutData } from '../Orchestration/layoutUtils';
import { transformDataFromContentful } from '../Orchestration/dataUtils';
import { isReadyUtil } from '../isReadyUtil';

const useLayoutOrchestration = ({
  variables,
  layoutDataModel,
  layoutOptions,
  ssr,
  customerType
}) => {
  const { myComponents, componentOptions } = layoutOptions;
  let queryName;
  if (!variables.slug) {
    queryName = 'layout';
  } else {
    queryName = 'layouts';
  }

  // Server SIDE DATA MODEL
  const contentfulServerSideLayouts = useDataModel(queryName, {
    variables,
    skip: !variables.slug && !variables.layoutClass,
    ssr: true,
    dataModel: layoutDataModel.server,
  });

  // CLIENT SIDE DATA MODEL
  const contentfulClientSideLayouts = useDataModel(queryName, {
    variables,
    skip: customerType === 'b2c-guest' || (!variables.slug && !variables.layoutClass),
    ssr: false,
    dataModel: layoutDataModel.client,
  });

  const contentfulServerSideLoading = contentfulServerSideLayouts.loading;
  const contentfulServerSideError = contentfulServerSideLayouts.error;
  const contentfulServerSideSEO = contentfulServerSideLayouts.data?.layouts?.seo;

  const contentfulClientSideLoading = contentfulClientSideLayouts.loading;
  const contentfulClientSideError = contentfulClientSideLayouts.error;
  const contentfulClientSideSEO = contentfulClientSideLayouts.data?.layouts?.seo;

  let combinedLayoutData;
  if (contentfulServerSideLayouts.data && contentfulClientSideLayouts.data) {
    combinedLayoutData = !variables.slug
      ? { ...contentfulServerSideLayouts.data?.layout, ...contentfulClientSideLayouts.data?.layout }
      : { ...contentfulServerSideLayouts.data?.layouts?.content, ...contentfulClientSideLayouts.data.layouts?.content };
  }

  const contentfulComponents = (combinedLayoutData
    || (!variables.slug
      ? contentfulServerSideLayouts?.data?.layout
      : contentfulServerSideLayouts?.data?.layouts?.content)
    || (!variables.slug
      ? contentfulClientSideLayouts?.data?.layout
      : contentfulClientSideLayouts?.data?.layouts?.content)
  );

  const {
    mboxes,
    campaignIds,
    innervateTags,
    isReady
  } = isReadyUtil({ combinedLayoutData, contentfulComponents, customerType });

  const layout = contentfulComponents
    ? transformDataFromContentful([], contentfulComponents)
    : null;

  if (!layout) {
    return {
      data: null,
      loading: { server: contentfulServerSideLoading, client: contentfulClientSideLoading },
      error: { server: contentfulServerSideError, client: contentfulClientSideError },
    };
  }

  const formattedComponents = formatLayoutData({
    allComponents: layout,
    myComponents,
    componentOptions,
  });

  return {
    data: {
      components: formattedComponents,
      seo: { ...contentfulServerSideSEO, ...contentfulClientSideSEO } || null,
      mboxes,
      campaignIds,
      innervateTags,
      isReady,
    },
    loading: { server: contentfulServerSideLoading, client: contentfulClientSideLoading },
    error: { server: contentfulServerSideError, client: contentfulClientSideError },
  };
};

export default useLayoutOrchestration;
