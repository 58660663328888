import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import {
  useDataModel, params, string, number, shape, arrayOf, bool, extend, customType
} from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { PriceProject } from './partials/PriceProject';
import Sku from '../models/Sku';
import { PriceSimple } from './partials/PriceSimple';
import { PriceDetailed } from './partials/PriceDetailed';
import { PriceMinimal } from './partials/PriceMinimal';
import { PreferredPricingBadge } from './partials/PreferredPricingBadge';
import { ExchangePricingBadge } from './partials/ExchangePricingBadge';
import helpers, { EXCHANGE_PROMOTION_TAG } from '../helpers/price-helper';
import '../styles/price-global.style.scss';
import { PromoCountdownTimer } from './partials/subcomponents/countdownTimer/PromoCountdownTimer';
import { LAYOUT_TYPES, isItemOnClearance } from '../utils/utils';
import { ClearancePricingBadge } from './partials/ClearancePricingBadge';

const isValidProps = (props) => {
  const { itemId, product: propProductData } = props;
  const propPricingData = propProductData?.pricing;
  return Boolean(propPricingData || /^\d{9}$/.test(itemId));
};

const isValidData = (sku, hideForProductTypes, hideWhenZero) => {
  const { _product, pricing } = sku;
  const { wasPrice, nowPrice } = pricing;
  const hasZeroPrice = nowPrice === 0 && wasPrice === 0;

  if (hideWhenZero && hasZeroPrice) return false;

  return Boolean(
    _product && !(hideForProductTypes.includes(_product.info?.productSubType?.name))
  );
};

const Price = (props) => {
  const { channel, deliveryZip } = useContext(ExperienceContext);
  const {
    storeId: defaultStoreId,
    storeZip: defaultStoreZip,
    isLocalized,
    storeName
  } = useStore();
  const {
    basic,
    disableRangePricing,
    displayEachUom,
    hideBadge,
    hideBulkPrice,
    hideLimitPerOrder,
    hidePromotions,
    hideSavingsText,
    hideRebates,
    hideForProductTypes,
    itemId,
    large,
    type,
    omitDetails,
    onSavingsCenterToggle,
    showPreferredPricingBadge,
    showPreferredPricingBadgeToolTip,
    showProjectPrice,
    product: propProductData,
    quantity,
    skip,
    storeId: storeIdProp,
    zipCode: zipCodeProp,
    clsRemediation,
    hideWhenZero,
    disableOuterSpacing,
    isCartPrice,
    isListView
  } = props;
  const {
    placeholders,
    preservePlaceholders
  } = clsRemediation || {};

  const { configuratorPricing } = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const { sqFtCoverage } = useLifeCycleEventBus('flooringPicker.flooringPicker_pricing_event');
  const { toggleOn: flooringPickerToggleOn } = useLifeCycleEventBus('flooringPicker.flooringPicker_toggle', true);

  const layout = helpers.getLayout(type, large, flooringPickerToggleOn, showProjectPrice);

  const hasValidProps = isValidProps(props);
  const align = helpers.getAlignment(channel, layout);
  const zipCode = zipCodeProp || deliveryZip || defaultStoreZip;
  const storeId = storeIdProp || defaultStoreId;

  const { data, loading } = useDataModel('product', {
    ssr: !skip,
    skip: !hasValidProps || Boolean(propProductData),
    variables: {
      itemId,
      storeId
    }
  });

  const [hasDataFullyLoaded, setHasDataFullyLoaded] = useState(false);

  useEffect(() => {
    setHasDataFullyLoaded(!loading && isLocalized);
  }, [loading, isLocalized]);

  // use configurator pricing, fallback to product pricing
  const productData = configuratorPricing?.itemId === itemId
    ? configuratorPricing : (propProductData || data?.product);

  const disableRangePrice = configuratorPricing?.itemId === itemId
    ? configuratorPricing?.disableRangePricing : disableRangePricing;

  const priceOmitDetails = configuratorPricing?.itemId === itemId ? configuratorPricing?.omitDetails : omitDetails;

  const skuModel = new Sku(productData, sqFtCoverage);

  const hasValidData = isValidData(skuModel, hideForProductTypes, hideWhenZero);

  const isPreferredPricing = (showPreferredPricingBadge && skuModel.preferredPriceFlag)
    || productData?.pricing?.showPreferredPricingBadge;
  const isPreferredPricingTooltip = showPreferredPricingBadgeToolTip
    || productData?.pricing?.showPreferredPricingBadgeToolTip;
  const isExchangePromotion = productData?.pricing?.promotion?.promotionTag === EXCHANGE_PROMOTION_TAG
    || skuModel?._product?.pricing?.showExchangePricing;
  const isDailySpecial = typeof productData?.pricing?.dates?.start === 'string'
    && typeof productData?.pricing?.dates?.end === 'string';

  const store = helpers.getLocalStoreName(productData) || storeName;

  // (1) invalidate props
  if (!hasValidProps) {
    return null;
  }

  // (2) invalidate data
  if (!hasValidData) {
    if (!placeholders) {
      return null;
    }

    if (hasDataFullyLoaded && !preservePlaceholders) {
      return null;
    }
  }

  const priceClasses = classNames({
    'price--hidden': !hasValidData || !hasDataFullyLoaded
  });

  const onlinePriceTextClasses = classNames(
    'sui-font-normal sui-leading-4 sui-px-[5px] sui-pt-2',
    {
      'sui-mb-2': layout === LAYOUT_TYPES.PROJECT
    }
  );

  const showOnlinePriceText = () => {
    return (
      <p className={onlinePriceTextClasses}>Online Price</p>
    );
  };

  return (
    <div className={priceClasses} data-component="Price">
      {!isCartPrice && (isItemOnClearance(productData?.fulfillment) && productData?.pricing?.clearance)
        && showOnlinePriceText()}

      {isPreferredPricing && !isListView && (
        <PreferredPricingBadge
          channel={channel}
          type={layout}
          showPreferredPricingBadgeToolTip={isPreferredPricingTooltip}
          align={align}
        />
      )}
      {isExchangePromotion && (
        <ExchangePricingBadge
          padLeft={layout === LAYOUT_TYPES.DETAILED}
          align={align}
        />
      )}
      {(layout === LAYOUT_TYPES.DETAILED) && (
        <PriceDetailed
          channel={channel}
          disableRangePricing={disableRangePrice}
          showStartingAt={configuratorPricing?.showStartingAt}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          skuModel={skuModel}
          storeId={storeId}
          zipCode={zipCode}
          disableOuterSpacing={disableOuterSpacing}
          isClearance={!isCartPrice && (isItemOnClearance(productData?.fulfillment) && productData?.pricing?.clearance)}
        />
      )}
      {(layout === LAYOUT_TYPES.SIMPLE) && (
        <PriceSimple
          basic={basic}
          channel={channel}
          disableRangePricing={disableRangePricing}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hidePromotions={hidePromotions}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={priceOmitDetails}
          onSavingsCenterToggle={onSavingsCenterToggle}
          skuModel={skuModel}
          zipCode={zipCode}
          disableOuterSpacing={disableOuterSpacing}
          isClearance={!isCartPrice && (isItemOnClearance(productData?.fulfillment) && productData?.pricing?.clearance)}
        />
      )}
      {(layout === LAYOUT_TYPES.MINIMAL) && (
        <PriceMinimal
          skuModel={skuModel}
          zipCode={zipCode}
          quantity={quantity}
          align={align}
        />
      )}
      {(layout === LAYOUT_TYPES.PROJECT) && (
        <PriceProject
          type={type}
          large={large}
          channel={channel}
          disableRangePricing={disableRangePrice}
          showStartingAt={configuratorPricing?.showStartingAt}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          onSavingsCenterToggle={onSavingsCenterToggle}
          skuModel={skuModel}
          storeId={storeId}
          zipCode={zipCode}
          disableOuterSpacing={disableOuterSpacing}
          isClearance={!isCartPrice && (isItemOnClearance(productData?.fulfillment) && productData?.pricing?.clearance)}
        />
      )}

      {isPreferredPricing && isListView && (
        <PreferredPricingBadge
          channel={channel}
          type={layout}
          showPreferredPricingBadgeToolTip={isPreferredPricingTooltip}
          align={align}
        />
      )}

      {isDailySpecial && <PromoCountdownTimer end={productData?.pricing?.dates?.end} align={align} />}
      {!isCartPrice && (isItemOnClearance(productData?.fulfillment) && productData?.pricing?.clearance)
        && (
          <ClearancePricingBadge
            storeName={store}
            originalPrice={productData?.pricing?.original
              ? productData?.pricing?.original : productData?.pricing?.value}
            clearanceValue={productData?.pricing?.clearance?.value}
            dollarOff={productData?.pricing?.clearance?.dollarOff}
            percentageOff={productData?.pricing?.clearance?.percentageOff}
            sku={productData?.itemId}
            unitsClearancePrice={productData?.pricing?.clearance?.unitsClearancePrice}
            caseUnitOfMeasure={productData?.pricing?.alternate?.unit?.caseUnitOfMeasure}
            unitsOriginalPrice={productData?.pricing?.alternate?.unit?.unitsOriginalPrice
              || productData?.pricing?.alternate?.unit?.value}
            alternatePriceDisplay={productData?.pricing?.alternatePriceDisplay}
          />
        )}
    </div>
  );
};

Price.displayName = 'Price';

Price.propTypes = {
  /**
   * To show the basic price format
   */
  basic: PropTypes.bool,
  /**
   * these props control CLS by manipulating placeholder logic
   */
  clsRemediation: PropTypes.shape({
    placeholders: PropTypes.bool,
    preservePlaceholders: PropTypes.bool
  }),
  /**
   * disables the display of range pricing often seen in generic sku product pods
   */
  disableRangePricing: PropTypes.bool,
  /**
   * disables the "each" uom treatment
   */
  displayEachUom: PropTypes.bool,
  /**
   * suppresses the display of badges, such as bulk pricing
   */
  hideBadge: PropTypes.bool,
  /**
   * disables bulk pricing messaging
   */
  hideBulkPrice: PropTypes.bool,
  /**
   * suppresses the display of limit per order text
   */
  hideLimitPerOrder: PropTypes.bool,
  /**
   * disables display of the short description for promotions
   */
  hidePromotions: PropTypes.bool,
  /**
   * Hide Savings Text
   */
  hideSavingsText: PropTypes.bool,
  /**
   * Hide Rebate Messaging and prevent Rebate Calls
   */
  hideRebates: PropTypes.bool,
  /**
   * specifies product types for which to hide the price. i.e. Merchandise, major_appliances, etc
   */
  hideForProductTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * OmsId for the item
   */
  itemId: PropTypes.string.isRequired,
  /**
   * Determines whether to display larger or smaller Price Component Variant
   */
  large: PropTypes.bool,
  /**
   * to display only a very simplified view of the price
   */
  omitDetails: PropTypes.bool,
  /**
   * callback triggered when savings center tooltip link is clicked
   */
  onSavingsCenterToggle: PropTypes.func,
  /**
   * "product" query data. must contain pricing information
   */
  product: PropTypes.shape({
    pricing: PropTypes.shape({}).isRequired
  }),
  /**
   * to show preferred pricing badge for B2B customers when preferred pricing is available
   */
  showPreferredPricingBadge: PropTypes.bool,
  /**
   * to show the tooltip that describes preferred pricing
   */
  showPreferredPricingBadgeToolTip: PropTypes.bool,
  /**
   * to show an esitmated price for your project based on areas saved in PIPCalculator/FlooringCalculator
   */
  showProjectPrice: PropTypes.bool,
  /**
   * forces SSR to skip requesting price data
   */
  skip: PropTypes.bool,
  /**
   * to override the customers localized store when requesting pricing info
   */
  storeId: PropTypes.string,
  /**
   * 'detailed': PIP style treatment, 'simple': PLP style, 'minimal': Cart style
  */
  type: PropTypes.oneOf([...Object.values(LAYOUT_TYPES), '']),
  /**
  * zipCode is used for ecorebates. Providing it as prop overrides the customer's selected deliveryZip
  */
  zipCode: PropTypes.string,
  /**
   * hides the price when it is 0. Will leverage clsRemediation when provided
   */
  hideWhenZero: PropTypes.bool,
  /**
   * hides the price when it is 0. Will leverage clsRemediation when provided
   */
  quantity: PropTypes.number,
  /**
   * removes the padding from PriceDetailed, PriceSimple and removes the left margin from large currency symbol
   */
  disableOuterSpacing: PropTypes.bool,
  isCartPrice: PropTypes.bool,
  isListView: PropTypes.bool
};

Price.defaultProps = {
  basic: false,
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  },
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideSavingsText: false,
  hideRebates: false,
  hideForProductTypes: [],
  large: true,
  omitDetails: false,
  onSavingsCenterToggle: () => {},
  product: null,
  showPreferredPricingBadge: false,
  showPreferredPricingBadgeToolTip: false,
  showProjectPrice: false,
  skip: false,
  storeId: null,
  zipCode: null,
  hideWhenZero: false,
  quantity: 1,
  type: '',
  disableOuterSpacing: false,
  isCartPrice: false,
  isListView: false
};

export const PriceClearanceDataModel = {
  product: params({
    itemId: string().isRequired(),
    dataSource: string(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      clearance: shape({
        value: number({ float: true }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true }),
        unitsClearancePrice: number({ float: true })
      })
    })
  })
};

Price.dataModel = extend({
  product: params({
    itemId: string().isRequired(),
    dataSource: string(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    })
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      itemId: string()
    }),
    info: shape({
      hidePrice: bool(),
      ecoRebate: bool(),
      quantityLimit: number(),
      categoryHierarchy: arrayOf(string()),
      sskMin: string(),
      sskMax: string(),
      unitOfMeasureCoverage: string(),
      wasMaxPriceRange: number({ float: true }),
      wasMinPriceRange: number({ float: true }),
      productSubType: shape({
        name: string()
      })
    }),
    pricing: params({ storeId: string() }).shape({
      alternatePriceDisplay: bool(),
      alternate: shape({
        bulk: shape({
          pricePerUnit: number({ float: true }),
          thresholdQuantity: number(),
          value: number({ float: true })
        }),
        unit: {
          caseUnitOfMeasure: string(),
          unitsOriginalPrice: number({ float: true }),
          unitsPerCase: number({ float: true }),
          value: number({ float: true }),
        },
      }),
      original: number({ float: true }),
      mapAboveOriginalPrice: bool(),
      message: string(),
      preferredPriceFlag: bool(),
      promotion: shape({
        type: string(),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true }),
        promotionTag: string(),
        savingsCenter: string(),
        savingsCenterPromos: string(),
        specialBuySavings: string(),
        specialBuyDollarOff: number({ float: true }),
        specialBuyPercentageOff: number({ float: true })
      }),
      specialBuy: number({ float: true }),
      unitOfMeasure: string(),
      value: number({ float: true }),
    }),
    taxonomy: shape({
      breadCrumbs: arrayOf(shape({
        label: string()
      }))
    })
  })
});

export default Price;
