import React from 'react';
import classnames from 'classnames';
import { ProgressBar, Typography } from '@one-thd/sui-atomic-components';

import { useMessage } from '../../hooks/useMessage';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoProgress } from '../../hooks/usePromoProgress';
import { MESSAGE_KEY } from '../../utils/constants';
import { BmsmTiers } from '../BmsmTiers/BmsmTiers';
import { SegmentedProgressBar } from '../SegmentedProgressBar/SegmentedProgressBar';

export const InfoContainer = () => {
  const { promoCartQuantity } = usePromoCart();
  const { isBmsm, isMsb } = usePromoModel();
  const { progressStatus, progressValue, tieredProgressValue } = usePromoProgress();
  const progressBarEndLabel = useMessage(MESSAGE_KEY.progressBarEndLabel);
  const progressBarStartLabel = useMessage(MESSAGE_KEY.progressBarStartLabel);
  const progressBarStatusText = useMessage(MESSAGE_KEY.progressBarStatusText);

  const hasCartQuantity = Boolean(promoCartQuantity);
  const fullWidth = isMsb || isBmsm;
  const showTiers = isBmsm && !hasCartQuantity;
  const showSingleProgressBar = isMsb;
  const showSegmentedProgressBar = isBmsm && hasCartQuantity;
  const showProgressBar = showSingleProgressBar || showSegmentedProgressBar;

  const infoContainerClasses = classnames(
    'sui-flex sui-flex-col sui-justify-center sui-box-border sui-relative',
    {
      'sui-w-full': fullWidth,
    }
  );

  return (
    <div className={infoContainerClasses} data-testid="info-container">
      {showProgressBar && (
        <div className="sui-flex sui-flex-col sui-gap-2" data-testid="promo-progress">
          {showSingleProgressBar && (
            <>
              <ProgressBar
                data-testid="single-segment-progress-bar"
                endLabel={progressBarEndLabel}
                startLabel={progressBarStartLabel}
                status={progressStatus}
                value={progressValue}
              />
              <>
                <Typography
                  align="left"
                  color={progressStatus === 'success' ? 'success' : null}
                  variant="body-xs"
                >
                  {progressBarStatusText}
                </Typography>
              </>
            </>
          )}
          {showSegmentedProgressBar && (
            <>
              <Typography
                align="left"
                color={progressStatus === 'success' ? 'success' : null}
                component="span"
                variant="h6"
              >
                {progressBarStatusText}
              </Typography>
              <SegmentedProgressBar
                data-testid="multiple-segment-progress-bar"
                value={tieredProgressValue}
              />
            </>
          )}
        </div>
      )}
      {showTiers && <BmsmTiers />}
    </div>
  );
};

InfoContainer.displayName = 'InfoContainer';
