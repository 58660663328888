import { MAJOR_APPLIANCE } from '../core/constants';

export const getProductPageStatus = (location) => {
  return (location.indexOf('/p/') > -1);
};

export const getCustomerData = () => {
  const customer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;
  if (!customer) return {};
  const {
    customerType, guestUserID, isLoggedIn, isB2BCustomer, mcvisID, segment, svocID, userID
  } = customer;
  return {
    customerType, guestUserID, isLoggedIn, isB2BCustomer, mcvisID, segment, svocID, userID
  };
};

export const getSkippers = ({ skipSubscribeAndSave = true }) => {
  return {
    skipSpecificationGroup: true,
    skipKPF: true,
    skipInstallServices: true,
    skipSubscribeAndSave
  };
};

export const isAppliance = (product = {}) => {
  return !!(product?.identifiers?.productType?.toUpperCase() === MAJOR_APPLIANCE);
};

export const isLoading = (data, loading) => {
  if (loading || data === undefined || data === null) {
    return true;
  }

  return false;
};

export const isSearchLoading = (data, searchData, multiData, loading, multiLoading) => {
  if (loading || multiLoading || (!data && !!searchData?.recs)
    || (!data && !searchData?.recs && (!!multiData?.recs || multiData === undefined))
    || (!data && searchData === undefined && multiData === undefined)) {
    return true;
  }

  return false;
};

export const isRecsLoading = (dataState, data, fallBackRecsData, loading, fallBackRecsLoading, tntTest) => {
  if (tntTest) {
    if (loading || fallBackRecsLoading || (!dataState && !!data?.recs)
      || (!dataState && !data?.recs && (!!fallBackRecsData?.recs || fallBackRecsData === undefined))
    || (!dataState && data === undefined && fallBackRecsData === undefined)) {
      return true;
    }
  } else if (loading || (!dataState && !!data?.recs)) {
    return true;
  }

  return false;
};

export const getAppId = (device, app) => {
  let appId = '';
  if (app) {
    appId = 'consumerApp';
  } else if (device === 'mobile') {
    appId = 'mobileweb';
  } else {
    appId = 'desktop';
  }
  return appId;
};

export const impressionContainerNameParser = (title) => {
  if (title && typeof title === 'string') {
    const name = title.replace(/ /g, '');
    return name;
  }
  return '';
};
