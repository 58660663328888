import React, { useCallback, useContext, useRef } from 'react';
import {
  oneOf,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType
} from 'prop-types';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { BuyAgain } from '@one-thd/sui-icons';
import { getCustomerData, getSkippers } from '../../utils/helpers';
import { ProductCarousel } from '../ProductCarousel';
import { reorderDataModel } from '../../dataModel';
import { FallbackCard } from '../../core/FallbackCard';

const ReorderRecommendations = (props) => {
  const {
    brandTitleMaxLine,
    customEventObject,
    customerID,
    customerIsLoggedIn,
    customerSegment,
    customerType,
    excludeItems,
    filter,
    hideTitle,
    maxProducts,
    requestKey,
    showLoading,
    slidesPer,
    showFallback,
    hideATC,
    noATCFulfillment,
    entryId,
    storefrontSvocId
  } = props;

  const { isLocalized, storeId, storeZip: zipCode } = useStore();
  const { channel } = useContext(ExperienceContext) || {};
  const innerQueryPrerequisites = useRef({ storeId, zipCode });
  const isMounted = useCallback(() => {
    return innerQueryPrerequisites.current.mounted;
  }, [innerQueryPrerequisites.current.mounted]);

  const {
    customerType: cType, isB2BCustomer, isLoggedIn, mcvisID, segment, svocID
  } = getCustomerData();

  const custIsLoggedIn = customerIsLoggedIn || isLoggedIn;
  const custID = customerID || custIsLoggedIn ? svocID : (storefrontSvocId || mcvisID);
  const custSegment = customerSegment || segment;
  const custType = customerType || cType;

  const b2bVariables = { cSeg: custSegment };
  const apiName = 'reorder';

  const udsQueryOptions = {
    variables: {
      apiName,
      appId: channel === 'mobile' ? 'mobileweb' : 'desktop',
      cType: custType,
      exItems: excludeItems,
      dataSource: apiName,
      filter,
      key: requestKey,
      maxProducts,
      model: isB2BCustomer ? 'model2' : 'model1',
      showPrice: true,
      storeId,
      tableName: 'reorder', // B2C non-auth: thda.m or mcvisidd, B2C and B2B auth: svocid
      userId: custID || '',
      zipCode,
      ...getSkippers({ skipSubscribeAndSave: false }),
      ...(isB2BCustomer && { ...b2bVariables }),
    },
    skip: !isLocalized || storeId === '8119',
    ssr: false,
  };

  const { loading, data, error, variables } = useDataModel('uds', udsQueryOptions);
  if (data && isLocalized) {
    innerQueryPrerequisites.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true,
    };
  }

  if (error || (!data?.uds?.products?.length && !loading)) {
    if (showFallback) {
      return (
        <FallbackCard
          header="No Items Recently Bought"
          subheader="Never run out of your favorites—save 5% on subscription items"
          footerUrl="https://www.homedepot.com/c/subscription_service"
          footer="Explore Subscriptions"
          icon={BuyAgain}
          componentName="ReorderRecommendations"
        />
      );
    }

    return null;
  }

  const impressionData = {
    id: entryId || '',
    name: ReorderRecommendations.displayName,
    component: ReorderRecommendations.displayName,
    type: 'product',
    position: ''
  };

  return (
    <ImpressionProvider data={impressionData}>
      <div id={apiName} className="sui-py-0" data-type="container" data-component="ReorderRecommendations">
        <meta data-prop="name" content={apiName} />
        <QueryProvider
          cacheKey="user-data-services-reorder"
          mounted={isMounted}
          defaultVariables={{
            storeId: innerQueryPrerequisites.current.storeId,
            zipCode: innerQueryPrerequisites.current.zipCode,
          }}
        >
          <ProductCarousel
            data={data?.uds}
            customEventObject={customEventObject}
            hideTitle={hideTitle}
            hideATC={hideATC}
            hideRating
            showSubscribeAndSave
            itemClass="fixed-item"
            loading={loading}
            brandTitleMaxLine={brandTitleMaxLine}
            showLoading={showLoading}
            storeId={storeId}
            zipCode={zipCode}
            slidesPer={slidesPer}
            scheme={apiName}
            noATCFulfillment={noATCFulfillment}
            hideControls={data?.uds?.products?.length <= 2}
          />
        </QueryProvider>
      </div>
    </ImpressionProvider>
  );
};

ReorderRecommendations.dataModel = reorderDataModel;

ReorderRecommendations.displayName = 'ReorderRecommendations';

ReorderRecommendations.propTypes = {
  // product pod takes only these prop values for brandTitleMaxLine
  brandTitleMaxLine: oneOf([2, 3, 4, null]),
  customEventObject: shapeType({
    component: stringType,
    customEvent: stringType
  }),
  // svocid or mcvisid
  customerID: stringType,
  customerIsLoggedIn: stringType,
  customerSegment: stringType,
  customerType: stringType,
  excludeItems: stringType,
  filter: stringType,
  hideTitle: boolType,
  maxProducts: numberType,
  requestKey: stringType.isRequired,
  showLoading: boolType,
  slidesPer: numberType,
  showFallback: boolType,
  hideATC: boolType,
  noATCFulfillment: boolType,
  entryId: stringType,
  storefrontSvocId: stringType,
};

ReorderRecommendations.defaultProps = {
  brandTitleMaxLine: 2,
  customEventObject: null,
  customerID: null,
  customerIsLoggedIn: null,
  customerSegment: null,
  customerType: null,
  excludeItems: '',
  filter: '',
  hideTitle: false,
  maxProducts: 20,
  showLoading: false,
  slidesPer: null,
  showFallback: false,
  hideATC: false,
  noATCFulfillment: null,
  entryId: null,
  storefrontSvocId: null,
};

export { ReorderRecommendations };
