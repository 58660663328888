import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Hero } from '@thd-olt-component-react/hero';
import {
  useHelmet, LinkTag, MetaTag, ScriptTag, TitleTag
} from '@thd-nucleus/thd-helmet';
import {
  useDataModel,
  params,
  arrayOf,
  number,
  string,
  fragment,
  shape,
  extend,
  customType,
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const HD_URL = 'https://www.homedepot.com';

const homepageMetadataHandler = ({ data: { heroImage }, props }) => {
  let tags = [];
  let {
    description, title, siteName, canonicalURL, facebookAdmins, robots, type, alternate
  } = props.metadata;

  const content = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.homedepot.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.homedepot.com/s/{search_term_string}?NCNI-5&gsitesearch',
      'query-input': 'required name=search_term_string',
    },
  };

  const organizationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'The Home Depot',
    alternateName: 'Home Depot',
    url: 'https://www.homedepot.com/',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '1-800-466-3337',
      contactType: 'customer service'
    },
    sameAs: [
      'https://www.facebook.com/homedepot/ ',
      'https://twitter.com/HomeDepot ',
      'https://www.instagram.com/homedepot/ ',
      'https://www.youtube.com/homedepot ',
      'https://www.linkedin.com/company/the-home-depot',
      'https://www.pinterest.com/homedepot/ '
    ],
  };

  alternate = [
    { link: 'https://www.homedepot.com/', lang: 'en' },
    { link: 'https://www.homedepot.ca/en/home.html/', lang: 'en-ca' },
    { link: 'https://www.homedepot.ca/fr/accueil.html', lang: 'fr-ca' },
    { link: 'https://www.homedepot.com.mx/', lang: 'es-mx' },
    { link: 'https://www.homedepot.com/', lang: 'x-default' },
  ];

  tags.push(new ScriptTag({ content, id: 'site-search' }));
  tags.push(new ScriptTag({ content: organizationSchema, id: 'site-organization' }));

  const fonts = [
    { url: 'https://assets.thdstatic.com/fonts/helvetica/HelveticaNeueLTW01-55Roman.woff2', format: 'font/woff2' },
    { url: 'https://assets.thdstatic.com/fonts/helvetica/HelveticaNeueLTW01-75Bold.woff2', format: 'font/woff2' },
    { url: 'https://assets.thdstatic.com/fonts/helvetica/HelveticaNeueLTW01-77BoldCn.woff2', format: 'font/woff2' },
    { url: 'https://assets.thdstatic.com/fonts/helvetica/HelveticaNeueLTW01-45Light.woff2', format: 'font/woff2' },
  ];

  fonts.forEach(({ url, format }) => {
    tags.push(new LinkTag({
      rel: 'preload', as: 'font', href: url, type: format, crossOrigin: true, id: `${url} ${format}`
    }));
  });

  if (heroImage?.heroImageUrl && heroImage?.heroImageId) {
    tags.push(
      new LinkTag({
        rel: 'preload',
        as: 'image',
        href: heroImage.heroImageUrl,
        id: heroImage.heroImageId,
        fetchpriority: 'high',
      })
    );
  }

  if (description) {
    tags.push(new MetaTag({ name: 'description', content: description, id: 'description' }));
    tags.push(
      new MetaTag({ property: 'og:description', content: description, id: 'ogDescription' })
    );
  }
  if (title) {
    tags.push(new MetaTag({ property: 'og:title', content: title, id: 'ogTitle' }));
    tags.push(new TitleTag(title));
  }
  if (siteName) {
    tags.push(new MetaTag({ property: 'og:site_name', content: siteName, id: 'ogSiteName' }));
  }
  if (type) {
    tags.push(new MetaTag({ property: 'og:type', content: type, id: 'ogType' }));
  }
  if (canonicalURL) {
    tags.push(
      new MetaTag({ property: 'og:url', content: `${HD_URL}${canonicalURL || ''}`, id: 'ogUrl' })
    );
    tags.push(
      new LinkTag({ rel: 'canonical', href: `${HD_URL}${canonicalURL || ''}`, id: 'canonical' })
    );
  }
  if (facebookAdmins) {
    tags.push(new MetaTag({ property: 'fb:admins', content: facebookAdmins, id: 'fbAdmins' }));
  }
  if (robots) {
    tags.push(new MetaTag({ name: 'robots', content: robots, id: 'robots' }));
  }
  if (alternate) {
    alternate.forEach(({ link, lang }) => {
      tags.push(
        new LinkTag({ rel: 'alternate', href: link, hreflang: lang, id: `alternate-${link}` })
      );
    });
    tags.push(
      new LinkTag({
        rel: 'alternate',
        href: 'android-app://com.thehomedepot/homedepot/view/homepage',
        id: 'alternate-android-app://com.thehomedepot/homedepot/view/homepage',
      })
    );
  }
  return tags;
};

const webPExtension = (url) => {
  let paramChar = '?';

  if (url?.indexOf('?') !== -1) {
    paramChar = '&';
  }

  return url + paramChar + 'fm=webp';
};

function selectPreferredImage({ damImage }) {
  let preferredImage;

  if (damImage?.damContentSelector) {
    preferredImage = damImage.damContentSelector.assetData?.[0]?.selectedImageUrl;
  }

  if (preferredImage) {
    return preferredImage;
  }

  if (damImage?.damDownloadedContent) {
    preferredImage = webPExtension(damImage.damDownloadedContent.url);
  }

  return preferredImage;
}

const getFirstImageUrlByViewport = (homepageHeroData, viewportSize) => {
  if (!homepageHeroData) return null;

  let firstHeroCarouselChild = homepageHeroData[0];

  if (firstHeroCarouselChild.__typename === 'ComponentSelector') {
    firstHeroCarouselChild = firstHeroCarouselChild.defaultComponent.content;
  }

  let imageSrcUrl;

  if (viewportSize === 'large') {
    imageSrcUrl = selectPreferredImage({
      damImage: firstHeroCarouselChild?.previewImage,
    });
  }

  if (viewportSize === 'small') {
    imageSrcUrl = selectPreferredImage({
      damImage: firstHeroCarouselChild?.mobilePreviewImage,
    });
  }

  return imageSrcUrl;
};

const HomepageMetadata = (props) => {
  const { pageID, preloadHeroImages, heroCarouselData } = props;
  const { channel } = useContext(ExperienceContext);
  const queryOptions = {
    variables: {
      slug: pageID,
    },
    skip: !preloadHeroImages,
  };

  const { data: homepageMetadataHeroData } = useDataModel('layouts', queryOptions);

  const heroImageSize = channel === 'desktop' ? 'large' : 'small';
  const heroImageId = channel === 'desktop' ? 'preloadHeroLargeImage' : 'preloadHeroSmallImage';

  let heroImageUrl;

  if (heroCarouselData?.components) {
    heroImageUrl = getFirstImageUrlByViewport(
      heroCarouselData.components,
      heroImageSize,
      'heroCarouselData'
    );
  } else if (homepageMetadataHeroData?.layouts?.content?.heroCarousel?.components) {
    heroImageUrl = getFirstImageUrlByViewport(
      homepageMetadataHeroData.layouts.content.heroCarousel.components,
      heroImageSize,
    );
  }

  useHelmet(
    'homepageMetadataHandler',
    { props, data: { heroImage: { heroImageUrl, heroImageId } } },
    homepageMetadataHandler,
    [heroImageUrl, heroImageId]
  );

  return null;
};

HomepageMetadata.displayName = 'HomepageMetadata';

const HeroFlattenImage = {
  dataModel: {
    id: string(),
    mobilePreviewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(
          shape({
            selectedImageUrl: string(),
          })
        ),
      }),
      damDownloadedContent: shape({
        url: string(),
      }),
    }),

    previewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(
          shape({
            selectedImageUrl: string(),
          })
        ),
      }),
      damDownloadedContent: shape({
        url: string(),
      }),
    }),

    title: string(),
    link: string(),
    componentName: string(),
    proAnalyticsCampaign: string(),
    proAnalyticsComponent: string(),

    __typename: string(),
  },
};

const Slot = {};

Slot.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['Slot']).isRequired(),
  }).shape({
    Slot: fragment().shape({
      id: string(),
      name: string(),
      slotNumber: number(),
      content: shape({
        ...Hero.dataModel.component,
        HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
      }),
    }),
  }),
};

const ComponentSelector = {};

ComponentSelector.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['ComponentSelector']).isRequired(),
  }).shape({
    ComponentSelector: fragment().shape({
      id: string(),
      location: string(),
      defaultComponent: arrayOf(
        shape({
          ...Slot.dataModel.component,
        })
      ),
      variations: arrayOf(
        shape({
          ...Slot.dataModel.component,
        })
      ),
    }),
    ...Slot.dataModel.component,
  }),
};

const heroImageDataModel = extend(
  {
    layouts: params({
      slug: string().isRequired(),
    }).shape({
      id: string(),
      content: shape({
        __typename: string(),
        PersonalizedHomePage: fragment().shape({
          id: string(),
          heroCarousel: shape({
            components: arrayOf(
              shape({
                ...ComponentSelector.dataModel.component,
                ...Hero.dataModel.component,
                HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
              })
            ),
          }),
        }),
      }),
    }),
  },
  Hero
);

HomepageMetadata.propTypes = {
  pageID: PropTypes.string.isRequired,
  preloadHeroImages: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  heroCarouselData: PropTypes.object
};

HomepageMetadata.defaultProps = {
  heroCarouselData: null
};

HomepageMetadata.dataModel = heroImageDataModel;

export { HomepageMetadata };
