/* eslint-disable max-params */
import {
  ABBREVIATED_FULFILLMENT_METHODS,
  FULFILLMENT_METHODS,
  MA,
  MULTI_SELECT_QUANTITY
} from './constants';
import {
  createPriorityOfFulfillments,
  getCurrentlyAvailable,
  getFulfillmentLocation,
  getFulfillmentLocationGraphQL,
  getFulfillmentMethodFromCart,
  updateAvailableFulfillments,
} from './promo-fulfillment-utils';

/**
 * @typedef {Object} CartRequest
 * @property {Array<object>} itemDetails - The itemDetails
 * @property<optional> {string} localStoreId - The localStoreId
 */

/**
 * @typedef {Object} CartRequestWrapper
 * @property {CartRequest} CartRequest - The CartRequest
 */

/**
 * @typedef {Object} CartRequestBuilderOptions
 * @property {Object} product - The product model
 * @property {Object} promoItemModel - The mcc-cart product itemModel
 * @property {number} desiredQuantity - The desired ***final*** quantity
 * @property {string} storeId - The store object
 * @property {string} selectedFulfillment - The preferred fulfillment method
 */

export const emptyPromoCart = {
  bogoItemInCart: null,
  currentPromoTier: 0,
  previousPromoTier: 0,
  isPromoAchieved: false,
  progressMade: 0,
  promoCartItemsObj: {},
  promoCartQuantity: 0,
  promoCartValue: 0,
  firstListCartItems: [],
  firstListCartItemIds: [],
  firstListCartValue: 0,
  firstListCartQuantity: 0,
  secondListCartItems: [],
  secondListCartItemIds: [],
  secondListCartValue: 0,
  secondListCartQuantity: 0,
  promoSuccessLevel: '',
  hasAnchorItemInCart: false,
  secondListItemInCart: false
};

const cartIsEmpty = (rawCartModel) => rawCartModel?.messagesModel?.[0]?.shortDescription === 'Empty Shopping Cart';
export const isValidRawCart = (CartModel) => {
  return !!CartModel && ((!!CartModel.itemModels || !!CartModel.items) || cartIsEmpty(CartModel));
};

/**
 *  TODO: need to differentiate fetch from update
 * fetch can successfully return itemModels alongside an errorModel
 * some of the itemModels are good, others may be associated with the cart error
 * errorModel messages will have a correlationId to associate it with an itemModel
 * what do we do with this info? so many edge cases. sigh...
 * Seems that any update request for a single item, if it has an error, it did not change quantity in cart
 * need to check above for BOGO multi add though
 */
const cartResponseHandler = (rawCartResponse) => {
  const CartModel = rawCartResponse?.CartModel;

  if (!isValidRawCart(CartModel)) {
    const cartError = CartModel?.errorModel?.[0] || {};
    const errorMessage = new Error(`cart response contains error: ${cartError.errorCode} ${cartError.description}`);

    return Promise.reject(errorMessage);
  }

  return Promise.resolve(CartModel);
};

export const cartFunctions = {
  fetchCartHelper: () => {
    if (typeof window !== 'undefined' && window.THDCart?.getCartData) {
      return window.THDCart.getCartData()
        .then(cartResponseHandler);
    }

    return Promise.reject();
  },
  updateCartHelper: (CartRequest) => {
    if (typeof window === 'undefined') return Promise.reject();

    return window.THDCart.updateCartData({ CartRequest })
      .then(cartResponseHandler);
  },
  addToCartHelper: (CartRequest) => {
    if (typeof window === 'undefined') return Promise.reject();

    return window.THDCart.addToCart(CartRequest)
      .then(cartResponseHandler);
  },
};

const getHost = () => {
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }
  return host;
};

const generateAddToCartItemDetail = ({
  itemId,
  type,
  isCartGraphQLEnabled,
  fulfillmentMethod,
  storeId,
  quantity,
  displayPosition,
  applianceDeliveryStore,
  deliveryZip
}) => {
// Common properties object to avoid repetition
  const commonProperties = { itemId, quantity: isCartGraphQLEnabled ? quantity.toString() : quantity };

  const conditionalProperties = isCartGraphQLEnabled
    ? {
      location: deliveryZip,
      type,
    }
    : {
      fulfillmentMethod,
      fulfillmentLocation: getFulfillmentLocation({ fulfillmentMethod, storeId, deliveryZip }),
      displayPosition,
      applianceDeliveryStore,
    };

  return { ...commonProperties, ...conditionalProperties };
};

export const buildAnalyticsCartEventObject = (cartRequest, cartResponse, isCartGraphQLEnabled) => {
  const cartRequestData = isCartGraphQLEnabled ? cartRequest.cartRequest : cartRequest.CartRequest;
  const { data, orderId, itemModels } = cartResponse;
  const storeId = isCartGraphQLEnabled ? cartRequestData.localization?.primaryStoreId : cartRequestData?.localStoreId;
  const cartId = isCartGraphQLEnabled ? data?.addToCart?.cartId || '' : orderId || '';
  const items = isCartGraphQLEnabled
    ? (cartRequestData.items.delivery || cartRequestData.items.pickup || [])
    : (cartRequestData?.itemDetails || []);
  return {
    cartId,
    items: items.map(({ itemId, quantity, fulfillmentMethod, type }) => {
      const basePrice = (
        isCartGraphQLEnabled ?
          data?.addToCart?.items?.find(
            (item) => item.product?.itemId === itemId
          )?.product.pricing.totalWithNoDiscount
          : itemModels?.find((item) => item.itemId === itemId)?.unitPrice
      ) || '';

      return {
        productInfo: { sku: itemId },
        quantity,
        price: { basePrice },
        fulfillment: {
          method: ABBREVIATED_FULFILLMENT_METHODS[fulfillmentMethod] || type,
          store: fulfillmentMethod === FULFILLMENT_METHODS.BOPIS ? storeId : '',
          zip: '',
        }
      };
    })
  };
};

const buildApplianceItemAddToCartRequest = ({
  itemId, displayPosition, applianceDeliveryStore, deliveryZip, isCartGraphQLEnabled
}) => {
  return isCartGraphQLEnabled
    ? {
      cartRequest: {
        items: {
          delivery: [generateAddToCartItemDetail({
            itemId,
            fulfillmentMethod: FULFILLMENT_METHODS.APPLIANCE,
            quantity: 1,
            displayPosition,
            applianceDeliveryStore,
            deliveryZip
          })]
        }
      }
    }
    : {
      CartRequest: {
        itemDetails: [generateAddToCartItemDetail({
          itemId,
          fulfillmentMethod: FULFILLMENT_METHODS.APPLIANCE,
          quantity: 1,
          displayPosition,
          applianceDeliveryStore,
          deliveryZip
        })],
      }
    };
};

/**
 * Builds a CartRequest for mcc-cart
 * @param {CartRequestBuilderOptions} options - CartRequest builder options
 * @returns {CartRequestWrapper} an object containing CartRequest
 */
export const buildAddToCartRequest = ({
  applianceDeliveryStore: applDeliveryStoreParam,
  deliveryZip,
  desiredQuantity = 1,
  product,
  promoItemModel,
  selectedFulfillment = '',
  storeId,
  isCartGraphQLEnabled
}) => {
  let itemsObj = {};
  const applianceDeliveryStore = applDeliveryStoreParam || storeId;
  const { itemId, displayPosition = 0 } = product;
  const fulfillmentPriorities = createPriorityOfFulfillments(selectedFulfillment);
  const currentlyAvailable = getCurrentlyAvailable(promoItemModel, product);
  let neededToFulfill = desiredQuantity;

  if (product.identifiers.productType === MA) {
    return buildApplianceItemAddToCartRequest({
      itemId,
      displayPosition,
      applianceDeliveryStore,
      deliveryZip,
      isCartGraphQLEnabled
    });
  }

  const itemDetails = fulfillmentPriorities
    .reduce((items, fulfillmentMethod) => {
      const availableQty = currentlyAvailable[fulfillmentMethod] || 0;
      const quantity = Math.min(neededToFulfill, availableQty);
      const type = ABBREVIATED_FULFILLMENT_METHODS[fulfillmentMethod];
      if (quantity > 0) {
        neededToFulfill -= quantity;
        items.push(generateAddToCartItemDetail({
          deliveryZip,
          displayPosition,
          fulfillmentMethod,
          itemId,
          quantity,
          storeId,
          type,
          isCartGraphQLEnabled
        }));
      }

      return items;
    }, []);

  const selectedFulfillmentType = itemDetails.map(
    (fulfillmentType) => {
      const isPickupType = fulfillmentType.type === ('bopis' || 'BOPIS' || 'boss' || 'ShipToStore');
      return isPickupType ? 'pickup' : 'delivery';
    }
  );
  itemsObj[selectedFulfillmentType] = [...itemDetails];

  if (isCartGraphQLEnabled) {
    return {
      cartRequest: {
        localization: { primaryStoreId: parseInt(storeId, 10) },
        cartAttr: { essentialAccessories: false },
        items: itemsObj
      }
    };
  }
  return { CartRequest: { itemDetails, localStoreId: storeId } };

};

export const buildAddToCartOverlayOptions = (channel, overrides = {}) => {
  return {
    channel,
    hidden: false,
    misship: false,
    paypal: false,
    host: getHost(),
    ...overrides
  };
};

export const buildUpdateCartRequest = ({
  deliveryZip,
  desiredQuantity,
  product,
  promoItemModel,
  selectedFulfillment = '',
  storeId,
}) => {
  let itemDetails = [];
  let neededToFulfill = desiredQuantity - (promoItemModel?.quantity || 0);
  const fulfillmentPriorities = createPriorityOfFulfillments(selectedFulfillment);

  if (neededToFulfill > 0) {
    let currentlyAvailable = getCurrentlyAvailable(promoItemModel, product);

    // Updating or adding quantities to cart
    itemDetails = fulfillmentPriorities.reduce((items, fulfillmentMethod) => {
      const availableQty = currentlyAvailable[fulfillmentMethod];
      if (!availableQty || neededToFulfill <= 0) return items;

      // Updating item
      const lineItem = promoItemModel.fulfillments[fulfillmentMethod];
      if (lineItem && lineItem.id && lineItem.quantity) {
        const qtyChange = Math.min(neededToFulfill, availableQty);
        neededToFulfill -= qtyChange;
        currentlyAvailable = updateAvailableFulfillments(fulfillmentMethod, currentlyAvailable, qtyChange);

        items.push({
          itemId: promoItemModel.itemId,
          quantity: qtyChange + lineItem.quantity,
          lineItemId: lineItem.id,
          fulfillmentMethod,
          fulfillmentLocation: getFulfillmentLocation({ fulfillmentMethod, storeId, deliveryZip })
        });
      }

      return items;
    }, []);

  } else if (neededToFulfill < 0) {

    if (product.identifiers.productType === MA) {
      itemDetails = [{
        itemId: promoItemModel.itemId,
        quantity: 0,
        lineItemId: promoItemModel.fulfillments.DirectDelivery.id,
        fulfillmentMethod: 'DirectDelivery',
        fulfillmentLocation: getFulfillmentLocation({ fulfillmentMethod: 'DirectDelivery', storeId, deliveryZip }),
        applianceDeliveryStore: promoItemModel.applianceDeliveryStore,
      }];
    } else {
      // reduceRight will subtract values from low-pri to high-pri fulfillments
      itemDetails = fulfillmentPriorities.reduceRight((items, fulfillmentMethod) => {
        if (neededToFulfill >= 0) return items;

        const lineItem = promoItemModel.fulfillments[fulfillmentMethod];
        if (lineItem && lineItem.id && lineItem.quantity) {
          const quantity = Math.max(neededToFulfill + lineItem.quantity, 0);

          if (quantity < lineItem.quantity) {
            neededToFulfill -= quantity - lineItem.quantity;
            items.push({
              itemId: promoItemModel.itemId,
              quantity,
              lineItemId: lineItem.id,
              fulfillmentMethod,
              fulfillmentLocation: getFulfillmentLocation({ fulfillmentMethod, storeId, deliveryZip }),
              applianceDeliveryStore: promoItemModel.applianceDeliveryStore
            });
          }
        }

        return items;
      }, []);
    }
  }

  return { CartRequest: { itemDetails, localStoreId: storeId } };
};

const getPromoSuccessLevel = ({
  isForwardsBogo,
  isBackwardsBogo,
  isForwardsB1gy,
  isForwardsBxg1,
  isMsb,
  isBmsm,
  bogoItemInCart,
  hasAnchorItemInCart,
  bogoSelectedProduct,
  firstListCartQuantity,
  secondListCartQuantity,
  src1EligibilityCriteria,
  tgt1EligibilityCriteria,
  promoCartValue,
  promoCartQuantity,
  progressMade,
  minPurchaseQuantity,
  minPurchaseAmount,
}) => {
  const qualifyingThresholdMet = firstListCartQuantity >= src1EligibilityCriteria.minPurchaseQuantity;
  const rewardThresholdMet = secondListCartQuantity >= tgt1EligibilityCriteria.minPurchaseQuantity;
  const almostQuantityMet = firstListCartQuantity > 0
    && firstListCartQuantity < src1EligibilityCriteria.minPurchaseQuantity;
  if (isForwardsBxg1) {
    if (firstListCartQuantity === 0 && secondListCartQuantity === 0) {
      return 'initial';
    } if ((almostQuantityMet) && !rewardThresholdMet) {
      return 'almost';
    }
    if (qualifyingThresholdMet && rewardThresholdMet) {
      return 'success';
    }
  }

  if (isForwardsB1gy) {
    if (!(qualifyingThresholdMet && rewardThresholdMet)) {
      return 'initial';
    } if (qualifyingThresholdMet && rewardThresholdMet) {
      return 'success';
    }
    return 'almost';

  }

  if (isForwardsBogo || isBackwardsBogo) {
    if (bogoItemInCart && hasAnchorItemInCart) return 'success';
    if (bogoSelectedProduct) return 'almost';
  }

  if (isMsb) {
    if (!promoCartQuantity || !promoCartValue) return '';
    let amountLeft;
    let percentLeft;
    if (minPurchaseQuantity) {
      amountLeft = Math.max(0, minPurchaseQuantity - promoCartQuantity);
      percentLeft = Math.max(0, Math.floor(100 - (promoCartQuantity / minPurchaseQuantity) * 100));
    } else { // minPurchaseAmount
      amountLeft = Math.max(0, minPurchaseAmount - promoCartValue);
      percentLeft = Math.max(0, Math.floor(100 - (promoCartValue / minPurchaseAmount) * 100));
    }
    if (amountLeft === 0 || percentLeft === 0) return 'success';
    if (amountLeft === 1 || percentLeft <= 10) return 'almost';

  }

  if (isBmsm) {
    if (progressMade >= 100) {
      return 'success';
    }
    if (promoCartQuantity) {
      return 'almost';
    }
  }

  return '';
};

const cartItemIsSanitary = (cartItem, isCartGraphQLEnabled) => {
  if (!isCartGraphQLEnabled) {
    return cartItem
  && cartItem.itemId
  && typeof cartItem.lineItemId === 'string'
  && cartItem.lineItemId.length
  && Number(cartItem.quantity)
  && Number(cartItem.totalItemPrice)
  && Array.isArray(cartItem.fulfillmentModel)
  && cartItem.fulfillmentModel[0]
  && cartItem.fulfillmentModel[0].fulfillmentMethod;
  }

  return cartItem
    && cartItem.product.identifiers.itemId
    && typeof cartItem.id === 'string'
    && cartItem.id.length
    && Number(cartItem.quantity)
    && Number(cartItem.product.pricing.totalWithNoDiscount)
    && Array.isArray(cartItem.product.fulfillment.fulfillmentOptions)
    && cartItem.product.fulfillment.fulfillmentOptions[0]
    && cartItem.product.fulfillment.fulfillmentOptions[0].services[0].type;

};

export const generatePromoCartItemsObj = ({
  itemModels, allListProductsAndAnchor, isCartGraphQLEnabled, storeId, deliveryZip
}) => {
  const promoCartItemsObj = {};

  if (!Array.isArray(itemModels)) return promoCartItemsObj;

  itemModels
    .forEach((promoCartItemEntry) => {
      const image = isCartGraphQLEnabled
        ? promoCartItemEntry.product.media.images[0].url
        : promoCartItemEntry.image;
      const itemId = isCartGraphQLEnabled
        ? promoCartItemEntry.product.identifiers.itemId
        : promoCartItemEntry.itemId;
      const quantity = Number(promoCartItemEntry.quantity);
      const actualItemPrice = Number(allListProductsAndAnchor.get(itemId)?.pricing?.value);
      // grab pricing from product
      // if pricing is unavailable, use default mechanism for calculation
      // TODO: eventually remove fallback
      const totalValue = isCartGraphQLEnabled
        ? Number(promoCartItemEntry.product.pricing.totalWithNoDiscount)
        : Number(promoCartItemEntry.totalItemPrice);
      const totalItemPrice = actualItemPrice
        ? Number(actualItemPrice * quantity)
        : totalValue;
      const id = isCartGraphQLEnabled ? promoCartItemEntry.id
        : promoCartItemEntry.lineItemId;
      const applianceDeliveryStore = promoCartItemEntry.applianceDeliveryStore || '';
      const newFulfillmentMethod = getFulfillmentMethodFromCart(
        promoCartItemEntry,
        isCartGraphQLEnabled
      );
      const type = isCartGraphQLEnabled ? promoCartItemEntry.selectedFulfillment : null;
      const itemDetails = {
        ...promoCartItemEntry,
        fulfillmentLocation: getFulfillmentLocationGraphQL(promoCartItemEntry, storeId, deliveryZip)
      };

      if (!promoCartItemsObj[itemId]) {
        promoCartItemsObj[itemId] = {
          itemId,
          image,
          type,
          quantity,
          totalItemPrice,
          applianceDeliveryStore,
          fulfillments: {
            [newFulfillmentMethod]: {
              quantity,
              id
            }
          },
          itemDetails
        };
      } else {
        promoCartItemsObj[itemId].quantity += quantity;
        promoCartItemsObj[itemId].totalItemPrice += totalItemPrice;
        promoCartItemsObj[itemId].fulfillments[newFulfillmentMethod] = { quantity, id };
        promoCartItemsObj[itemId].applianceDeliveryStore = applianceDeliveryStore;
      }
    });

  return promoCartItemsObj;
};

// Gets the first tier that hasn't been completed for bmsm. If all tiers have been completed returns the last tier.
// Returns 0 for non bmsm promotions
// Tiers are 0 based
const getCurrentPromoTier = (isBmsm, tiers, promoCartValue, promoCartQuantity) => {
  if (!isBmsm) return 0;

  const currentIndex = tiers.findIndex(({ minPurchaseAmount, minPurchaseQuantity }) => {
    if (minPurchaseAmount) return promoCartValue < minPurchaseAmount;
    if (minPurchaseQuantity) return promoCartQuantity < minPurchaseQuantity;
    return false;
  });

  return currentIndex === -1 ? tiers.length - 1 : currentIndex;
};

const getProgressMade = ({
  firstListCartValue,
  firstListCartQuantity,
  isDollarThresholdBogo,
  promoCartQuantity,
  promoCartValue,
  minPurchaseAmount,
  minPurchaseQuantity,
}) => {
  let cartQuantity = isDollarThresholdBogo ? firstListCartQuantity : promoCartQuantity;
  let cartValue = isDollarThresholdBogo ? firstListCartValue : promoCartValue;
  const progressMadeQuantity = Math.floor((cartQuantity / minPurchaseQuantity) * 100) || 0;
  const progressMadeAmount = Math.floor((cartValue / minPurchaseAmount) * 100) || 0;
  return Math.min(100, minPurchaseQuantity ? progressMadeQuantity : progressMadeAmount);
};

// Returns progress made into the current tier.
const getMultiTierPromoProgress = ({ promoCartValue, promoCartQuantity, rewardTiers, currentPromoTier }) => {
  const {
    minPurchaseAmount: currentTierMinAmount,
    minPurchaseQuantity: currentTierMinQuantity
  } = rewardTiers[currentPromoTier];

  if (currentPromoTier === 0) {
    return getProgressMade({
      promoCartValue,
      promoCartQuantity,
      minPurchaseAmount: currentTierMinAmount,
      minPurchaseQuantity: currentTierMinQuantity
    });
  }

  const {
    minPurchaseAmount: previousTierMinAmount,
    minPurchaseQuantity: previousTierMinQuantity
  } = rewardTiers[currentPromoTier - 1];

  // Progress made from previous tiers need to be removed in order to calculate current tier progress
  const minPurchaseAmount = currentTierMinAmount - previousTierMinAmount;
  const minPurchaseQuantity = currentTierMinQuantity - previousTierMinQuantity;

  const currentTierQuantity = promoCartQuantity - previousTierMinQuantity;
  const currentTierValue = promoCartValue - previousTierMinAmount;

  return getProgressMade({
    promoCartQuantity: currentTierQuantity,
    promoCartValue: currentTierValue,
    minPurchaseQuantity,
    minPurchaseAmount,
  });
};

const getPromotionProgress = ({
  currentPromoTier,
  minPurchaseAmount,
  minPurchaseQuantity,
  isDollarThresholdBogo,
  firstListCartValue,
  firstListCartQuantity,
  promoCartQuantity,
  promoCartValue,
  rewardTiers,
}) => {
  const progressMade = rewardTiers.length > 1
    ? getMultiTierPromoProgress({ promoCartValue, promoCartQuantity, rewardTiers, currentPromoTier })
    : getProgressMade({
      firstListCartValue,
      firstListCartQuantity,
      isDollarThresholdBogo,
      promoCartValue,
      promoCartQuantity,
      minPurchaseAmount,
      minPurchaseQuantity,
    });

  const isPromoAchieved = progressMade === 100;
  return [progressMade, isPromoAchieved];
};

// returns list of storeSkus that are in cart but not known to be in the promotion
export const getCartStoreSkusWithoutPromoItems = (cartModel, allListProductsAndAnchor, isCartGraphQLEnabled) => {
  return (cartModel?.itemModels || cartModel?.items || [])
    .filter((cartItem) => {
      const itemId = isCartGraphQLEnabled ? cartItem?.product?.itemId : cartItem?.itemId;
      return !allListProductsAndAnchor.has(itemId)
    }).map((cartItem) => {
      return isCartGraphQLEnabled ?
        cartItem?.product.identifiers?.storeSkuNumber : cartItem?.storeSku;
    });
};

// returns list of cartItems that are eligible for promotion
const getCartItemsInPromotion = ({ cartItemsPromotionValidity,
  CartModel, allListProductsAndAnchor, isCartGraphQLEnabled }) => {
  let itemModels;
  let itemsInCartAndDrawer;
  let itemsInCartAndNotInDrawer;
  if (!isCartGraphQLEnabled) {
    //   // Items in cart that are part of promotion and included in batch call
    itemModels = Array.isArray(CartModel.itemModels) ? CartModel.itemModels : [];
    itemsInCartAndDrawer = itemModels.filter((cartItem) => allListProductsAndAnchor.has(cartItem.itemId));

    // Items in cart that are part of promotion, but not included in batch call
    itemsInCartAndNotInDrawer = cartItemsPromotionValidity
      .filter(({ eligible }) => eligible)
      .map(({ storeSkuId }) => {
        return CartModel.itemModels.find((item) => item.storeSku === storeSkuId);
      });
  } else {
    // Items in cart that are part of promotion and included in batch call
    itemModels = Array.isArray(CartModel?.items) ? CartModel.items : [];
    itemsInCartAndDrawer = itemModels.filter((cartItem) => {
      return allListProductsAndAnchor.has(cartItem.product.identifiers.itemId);
    });

    // Items in cart that are part of promotion, but not included in batch call
    itemsInCartAndNotInDrawer = cartItemsPromotionValidity
      .filter(({ eligible }) => eligible)
      .map(({ storeSkuId }) => {
        return CartModel?.items.find((item) => item.product.identifiers.storeSkuNumber === storeSkuId);
      });
  }

  return [...itemsInCartAndDrawer, ...itemsInCartAndNotInDrawer].filter((item) => {
    return cartItemIsSanitary(item, isCartGraphQLEnabled);
  });

};

export const transformCartModelToPromoCart = ({
  rawCartModel = {},
  selectedItemsModel = [],
  isForwardsB1gy = false,
  isForwardsBxg1 = false,
  isBackwardsBogo = false,
  isForwardsBogo = false,
  isMsb = false,
  isBmsm = false,
  isDollarThresholdBogo = false,
  displayableFirstListItemIds = [],
  displayableSecondListItemIds = [],
  anchorItemId = '',
  bogoSelectedProduct = '',
  rewardTiers = [],
  src1EligibilityCriteria,
  tgt1EligibilityCriteria,
  src1MinPurchaseAmount = 0,
  allListProductsAndAnchor,
  cartItemsPromotionValidity = [],
  isCartGraphQLEnabled = false
} = {}) => {

  if (
    (isCartGraphQLEnabled ? !rawCartModel?.items?.length : !Array.isArray(rawCartModel.itemModels))
      && !selectedItemsModel.length
  ) {
    return emptyPromoCart;
  }

  // All cart items that are part of the promotion regardless if the item is part of batch call or not
  const cartItemsInPromo = getCartItemsInPromotion({
    cartItemsPromotionValidity,
    CartModel: rawCartModel,
    allListProductsAndAnchor,
    isCartGraphQLEnabled
  });

  const promoCartItemsObj = generatePromoCartItemsObj({ itemModels: cartItemsInPromo,
    allListProductsAndAnchor,
    isCartGraphQLEnabled });

  const hasAnchorItemInCart = !!promoCartItemsObj?.[anchorItemId]?.quantity;

  let firstListCartItems = [];
  let firstListCartItemIds = [];
  let firstListCartValue = 0;
  let firstListCartQuantity = 0;
  let secondListCartItems = [];
  let secondListCartItemIds = [];
  let secondListCartValue = 0;
  let secondListCartQuantity = 0;
  let promoCartValue = 0;
  let promoCartQuantity = 0;
  Object.values(promoCartItemsObj)
    .forEach((promoItem) => {
      if (displayableFirstListItemIds.includes(promoItem.itemId)) {
        firstListCartItems.push(promoItem);
        firstListCartItemIds.push(promoItem.itemId);
        firstListCartValue += promoItem.totalItemPrice;
        firstListCartQuantity += promoItem.quantity;
      } else if (displayableSecondListItemIds.includes(promoItem.itemId)) {
        secondListCartItems.push(promoItem);
        secondListCartItemIds.push(promoItem.itemId);
        secondListCartValue += promoItem.totalItemPrice;
        secondListCartQuantity += promoItem.quantity;
      }
      promoCartValue += promoItem.totalItemPrice;
      promoCartQuantity += promoItem.quantity;
    });

  // Items selected but not added to cart yet
  selectedItemsModel.forEach(({ pricing }) => {
    firstListCartValue += (MULTI_SELECT_QUANTITY * Number(pricing.value));
    firstListCartQuantity += MULTI_SELECT_QUANTITY;
    promoCartValue += (MULTI_SELECT_QUANTITY * Number(pricing.value));
    promoCartQuantity += MULTI_SELECT_QUANTITY;
  });

  const currentPromoTier = getCurrentPromoTier(isBmsm, rewardTiers, promoCartValue, promoCartQuantity);
  const previousPromoTier = currentPromoTier - 1 < 0 ? 0 : currentPromoTier - 1;

  const { minPurchaseAmount: tieredMinPurchaseAmount, minPurchaseQuantity } = rewardTiers[currentPromoTier] || {};
  const minPurchaseAmount = isDollarThresholdBogo ? src1MinPurchaseAmount : tieredMinPurchaseAmount;

  const [progressMade, isPromoAchieved] = getPromotionProgress({
    rewardTiers,
    currentPromoTier,
    firstListCartValue,
    firstListCartQuantity,
    isDollarThresholdBogo,
    promoCartValue,
    promoCartQuantity,
    minPurchaseAmount,
    minPurchaseQuantity,
    isCartGraphQLEnabled
  });

  const nonAnchorIdInCart = displayableFirstListItemIds.find((itemId) => promoCartItemsObj[itemId]);
  const bogoItemInCart = ((isForwardsBogo || isBackwardsBogo) && nonAnchorIdInCart)
    ? promoCartItemsObj[nonAnchorIdInCart]
    : null;

  const promoSuccessLevel = getPromoSuccessLevel({
    isMsb,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isForwardsBogo,
    isBmsm,
    bogoItemInCart,
    hasAnchorItemInCart,
    bogoSelectedProduct,
    promoCartValue,
    promoCartQuantity,
    firstListCartValue,
    firstListCartItemIds,
    secondListCartItemIds,
    firstListCartQuantity,
    secondListCartQuantity,
    progressMade,
    rewardTiers,
    minPurchaseAmount,
    minPurchaseQuantity,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    isCartGraphQLEnabled
  });

  const cartItems = isCartGraphQLEnabled ? rawCartModel?.items : rawCartModel?.itemModels;
  const secondListItemInCart = cartItems?.some(
    (item) => displayableSecondListItemIds.includes(isCartGraphQLEnabled
      ? item?.product?.identifiers?.itemId : item?.itemId)
  );

  return {
    promoCartItemsObj,
    promoCartValue,
    promoCartQuantity,
    firstListCartItems,
    firstListCartItemIds,
    firstListCartValue,
    firstListCartQuantity,
    secondListCartItems,
    secondListCartItemIds,
    secondListCartValue,
    secondListCartQuantity,
    progressMade,
    promoSuccessLevel,
    isPromoAchieved,
    bogoItemInCart: promoSuccessLevel === 'success' ? bogoItemInCart : null,
    currentPromoTier,
    previousPromoTier,
    secondListItemInCart,
    hasAnchorItemInCart
  };
};

// TODO: check if this is still needed with new header footer
export const updateCartCountInHeader = (newCartModel) => {
  // Need to emit event for cart count in header.
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS?.lifeCycle?.events?.hfs?.cartUpdated) {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hfs.cartUpdated');
  }

  const cartEvent = document.createEvent('CustomEvent');
  cartEvent.initCustomEvent('cartUpdated', true, false, { detail: { CartModel: newCartModel } });
  document.dispatchEvent(cartEvent);
};

export const calculateFulfillmentTypeOption = (cartRequest, CartRequest) => {
  return cartRequest?.items?.delivery || cartRequest?.items?.pickup || CartRequest?.itemDetails;
};
