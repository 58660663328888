/* eslint-disable max-len */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';

export const financingDetails = (isTieredPromo, isAppliancePromo, financingMonths, promoStartDate, promoEndDate, saleAmount) => {
  const boldText = isTieredPromo ? 'PROMOTIONAL PERIOD: ' : (financingMonths + ' MONTHS**');
  let promoDetailsText = `on ${isAppliancePromo ? 'appliance ' : ''}purchases of $299 or more \
    made on The Home Depot Consumer Credit Card.`;
  if (isTieredPromo) {
    promoDetailsText = '12 Months** on Purchases of $299–$998, 18 Months** on Purchases of'
      + ' $999–$1,998 or 24 Months** on Purchases of $1,999 or More.';
  }
  const endText = isTieredPromo ? 'the promotional period' : financingMonths + ' months';
  const promoDateRange = promoStartDate && promoEndDate
    && saleAmount >= 300 ? `Valid ${promoStartDate} - ${promoEndDate}` : '';
  return (
    <Typography variant="body-base">
      <span className="sui-font-bold">NO INTEREST IF PAID IN FULL WITHIN {boldText} </span>
      {promoDetailsText} Interest will be charged to your account from the purchase date if the
      purchase balance (including premiums for optional credit insurance) is not paid in full
      within {endText}. {promoDateRange}
    </Typography>
  );
};

export const suggestedPaymentDetails = (suggestedAmount, saleAmount, financingMonths) => {

  return (
    <div>
      <Typography variant="body-lg" align="center">
        <span className="sui-font-bold">Suggested Payments of ${suggestedAmount}.00 per month**</span>
      </Typography>
      <Typography variant="body-base" align="center">
        <span className="sui-text-subtle sui-text-center"> Making {financingMonths} suggested
          payments of ${suggestedAmount}.00 would pay the purchase
          of ${Math.ceil(saleAmount).toLocaleString()}.00 by the end of the promotion**.
        </span>
      </Typography>
    </div>
  );
};

export const financingTerms = (interestRate, saleAmount, showFinancing) => {
  const interestRateText = interestRate ? ` APR: ${interestRate}. ` : '';
  const suggestedText = showFinancing && saleAmount >= 300
    ? ' The suggested monthly payment stated is only an estimate based on listed purchase '
    + 'amount excluding sales tax, is rounded to the next highest dollar amount and '
    + 'does not include additional payments that may be required for an existing '
    + 'balance or future purchases. In order to pay your promotional balance by '
    + 'the end of the period, you may have to make a payment each month that '
    + 'is higher than your required minimum payment.' : '';

  return (
    <div className="sui-border-button-inactive sui-border-solid sui-border-1 sui-p-2 sui-mb-4">
      <Typography variant="body-xs">
        **With credit approval for qualifying purchases made on The Home Depot Consumer Credit
        Card. {interestRateText} Minimum interest charge: $2. See card agreement
        for details including APR applicable to you. Offer is only valid for consumer accounts;
        6 months everyday credit offer is subject to change without notice; see store for details.
        {suggestedText}
      </Typography>
    </div>
  );
};