import React from 'react';
import { element, string } from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';

import { PromoWarningMessage } from './subcomponents/PromoWarningMessage/PromoWarningMessage';

export const CallToActionSection = ({
  children,
  warningMessage,
}) => (
  <div className="sui-flex sui-mb-3" data-testid="promo-pod__cta">
    <Col fallback={3} nopadding>
      {warningMessage && <PromoWarningMessage message={warningMessage} />}
    </Col>
    <Col fallback={8} nopadding className="sui-ml-3">
      {children}
    </Col>
  </div>
);

CallToActionSection.displayName = 'CallToActionSection';

CallToActionSection.propTypes = {
  children: element.isRequired,
  warningMessage: string,
};

CallToActionSection.defaultProps = {
  warningMessage: null
};
