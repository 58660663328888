/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import {
  arrayOf,
  bool,
  string,
  number,
  shape
} from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';
import { HomepageContext } from './context/HomepageContext';
import { CUSTOMER_TYPES } from '../utils';
import { selectPreferredImage } from '../utils/publicLinks';

export function HeroFlattenImage({
  id,
  layoutRendererPrerenderedChildIndex,
  link,
  mobilePreviewImage,
  previewImage,
  proAnalyticsCampaign,
  proAnalyticsComponent,
  title,
}) {
  const { customerType, proCustomerSegment } = useContext(HomepageContext);

  const impressionContextData = useImpression({
    data: {
      id,
      name: 'HeroFlattenImage',
      component: 'Hero',
      position: layoutRendererPrerenderedChildIndex + 1,
      type: 'content',
    },
  });

  const largeViewportImageSrcUrl = selectPreferredImage({
    damImage: previewImage,
  });
  const smallViewportImageSrcUrl = selectPreferredImage({
    damImage: mobilePreviewImage,
  });

  if (!largeViewportImageSrcUrl && !smallViewportImageSrcUrl) {
    return null;
  }

  const isB2B = customerType === CUSTOMER_TYPES.B2B;

  const mobileBreakpoint = isB2B ? '640px' : '768px';

  function handleClickAnalytics() {
    if (isB2B) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('hero-flatten.content-click', {
        widgetId: id,
        widgetTemplates: proCustomerSegment,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  }

  const altText = title;
  const imageClasses = 'sui-w-full sui-h-auto sui-top-0 sui-left-0';

  return (

    <a
      href={link}
      key={id}
      onClick={handleClickAnalytics}
      ref={impressionContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionContextData.clickID}
    >
      <picture>
        <source
          className={imageClasses}
          alt={`Image for ${altText}`}
          srcSet={largeViewportImageSrcUrl}
          media={`(min-width: ${mobileBreakpoint})`}
        />
        {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
        <img
          className={imageClasses}
          alt={altText}
          src={smallViewportImageSrcUrl}
          loading={layoutRendererPrerenderedChildIndex > 1 ? 'lazy' : 'eager'}
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority={layoutRendererPrerenderedChildIndex < 2 ? 'high' : 'low'}
        />
      </picture>
    </a>
  );
}

HeroFlattenImage.propTypes = {
  link: string,
  id: string,
  mobilePreviewImage: shape({
    damContentSelector: shape({
      assetData: arrayOf(
        shape({
          selectedImageUrl: string,
        })
      ),
    }),
    damDownloadedContent: shape({
      url: string,
    }),
  }),
  previewImage: shape({
    damContentSelector: shape({
      assetData: arrayOf(
        shape({
          selectedImageUrl: string,
        })
      ),
    }),
    damDownloadedContent: shape({
      url: string,
    }),
  }),

  title: string,
  layoutRendererPrerenderedChildIndex: number,
  proAnalyticsCampaign: string,
  proAnalyticsComponent: string,
};

HeroFlattenImage.defaultProps = {
  link: '',
  id: '',
  layoutRendererPrerenderedChildIndex: undefined,
  proAnalyticsCampaign: undefined,
  proAnalyticsComponent: undefined,
  title: undefined,
  mobilePreviewImage: undefined,
  previewImage: undefined,
};
