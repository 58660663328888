import {
  alias,
  arrayOf,
  extend,
  shape as shapeType,
  string as stringType,
  number as numberType,
  bool as boolType,
} from '@thd-nucleus/data-sources';

const Message = shapeType({
  type: stringType(),
  messageCategoryType: stringType(),
  correlationId: stringType(),
  correlationType: stringType(),
  longDesc: stringType(),
  shortDesc: stringType()
});

const Addon = shapeType({
  installation: boolType(),
  termLength: stringType(),
  type: stringType(),
  price: numberType(),
  totalPrice: numberType(),
  quantity: numberType(),
  itemId: stringType(),
  id: stringType(),
  category: stringType(),
  description: stringType(),
  detailsUrl: stringType(),
  selected: boolType(),
  storeId: stringType(),
  protectionPlanParentId: stringType(),
  brandName: stringType(),
  configAttr: stringType(),
  descriptiveAttributes: arrayOf(stringType())
});

const Info = shapeType({
  returnable: stringType(),
  quantityLimit: numberType(),
  minimumOrderQuantity: numberType(),
});

const Discount = shapeType({
  percentOff: stringType(),
  dollarOff: stringType(),
});

const Pricing = shapeType({
  value: numberType(),
  original: numberType(),
  total: numberType(),
  totalWithNoDiscount: numberType(),
  valueStartDate: stringType(),
  valueEndDate: stringType(),
  type: stringType(),
  discount: Discount
});

const Image = shapeType({
  url: stringType(),
  type: stringType(),
  subType: stringType(),
  sizes: arrayOf(stringType()),
  hotspots: stringType(),
  altText: stringType(),
});

const Media = shapeType({
  images: arrayOf(Image)
});

const Identifiers = shapeType({
  configId: stringType(),
  editUrl: stringType(),
  copyUrl: stringType(),
  productCategory: stringType(),
  leadTime: stringType(),
  canonicalUrl: stringType(),
  brandName: stringType(),
  itemId: stringType(),
  modelNumber: stringType(),
  productLabel: stringType(),
  storeSkuNumber: stringType(),
  skuClassification: stringType(),
  productType: stringType(),
  isSuperSku: boolType(),
  shipType: numberType(),
});

const Attribute = shapeType({
  name: stringType(),
  value: stringType(),
  sequenceNumber: stringType(),
});

const Location = shapeType({
  locationId: stringType(),
  zipCode: stringType(),
});

const Service = shapeType({
  type: stringType(),
  selected: boolType(),
  locations: arrayOf(Location)

});

const FulfillmentOption = shapeType({
  type: stringType(),
  services: arrayOf(Service),
});

const Fulfillment = shapeType({
  fulfillmentOptions: arrayOf(FulfillmentOption)
});

const Product = shapeType({
  itemId: stringType(),
  addons: arrayOf(Addon),
  info: Info,
  pricing: Pricing,
  media: Media,
  fulfillment: Fulfillment,
  identifiers: Identifiers,
  dataSources: stringType(),
  attributes: arrayOf(Attribute),
  essentialAccessories: boolType()
});

const Item = shapeType({
  id: stringType(),
  quantity: stringType(),
  product: Product,
  selectedFulfillment: stringType()
});

const DescriptiveAttr = shapeType({
  cartType: stringType(),
  paypalExpress: boolType(),
  paymentOnHold: boolType(),
  isIcEnabled: boolType(),
  displayIcOption: stringType(),
  poJobName: stringType(),
  hasSubscriptionItems: boolType(),
  maxCartPriceContributor: stringType()
});

const Restriction = shapeType({
  paymentType: stringType(),
});

const PromoItem = shapeType({
  appliedDiscount: stringType(),
  appliedOn: stringType(),
});

const Promo = shapeType({
  desc: stringType(),
  longDesc: stringType(),
  type: stringType(),
  tag: stringType(),
  appliedDisc: numberType(),
  promoCode: stringType(),
  restrictions: arrayOf(Restriction),
  message: Message,
  attached: boolType(),
  promoItems: arrayOf(PromoItem)
});

const Localization = shapeType({
  primaryStoreId: stringType(),
  deliveryZip: stringType(),
  deliveryStateCode: stringType()
});

const Totals = shapeType({
  total: numberType(),
  totalDiscount: numberType(),
  totalWithNoDiscount: numberType()
});

const CartInfoLite = shapeType({
  cartId: stringType(),
  itemCount: numberType(),
  messages: arrayOf(Message),
  items: arrayOf(Item),
  descriptiveAttr: DescriptiveAttr,
  promos: arrayOf(Promo),
  localization: Localization,
  totals: Totals
});

export const cartInfoLiteDataModel = extend({ getCart:
  alias('cartInfoLite').params({}).shape(CartInfoLite) });
