import { useCallback, useContext, useMemo } from 'react';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';

import {
  FULFILLMENT_METHODS,
  MA,
  PRODUCT_CTA_TYPE,
  FEATURE_SWITCH_KEYS
} from '../utils/constants';
import {
  buildAddToCartOverlayOptions,
  buildAddToCartRequest,
  buildUpdateCartRequest,
  generatePromoCartItemsObj,
  calculateFulfillmentTypeOption
} from '../utils/promo-cart-utils';
import { usePromoCart } from './usePromoCart';
import { usePromoModel } from './usePromoModel';

export const useProduct = (itemId, displayPosition = null) => {
  const {
    addToCart,
    bogoItemInCart,
    bogoSelectedProduct,
    CartModel,
    freezeCart,
    promoCartItemIds,
    promoCartItemsObj,
    selectedItemsModel,
    setBogoSelectedProduct,
    setSelectedItemsModel,
    updateCart,
    deleteCart,
  } = usePromoCart();
  const {
    allListProductsAndAnchor,
    analyticsSharedSection,
    anchorItem,
    applianceDeliveryStore,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isBmsm,
    isDollarThresholdBogo,
    isForwardsBogo,
    isMsb,
    isMultiSelect,
  } = usePromoModel();
  const { channel, deliveryZip } = useContext(ExperienceContext);
  const storeId = useStoreId();
  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;
  const product = allListProductsAndAnchor.get(itemId);
  const isAppliance = product?.identifiers?.productType === MA;
  const isSoldOut = product?.isSoldOut;
  const bogoSelectedItemId = (bogoSelectedProduct || bogoItemInCart)?.itemId;

  let selected = false;
  if (isForwardsBogo || isBackwardsBogo) {
    selected = bogoSelectedItemId === itemId;
  }
  if (isMultiSelect) {
    selected = (
      selectedItemsModel.some((selectedItem) => selectedItem?.itemId === itemId)
      || !!promoCartItemsObj[itemId]
    );
  }

  const onSelect = useCallback(() => {
    if (bogoItemInCart && bogoItemInCart.itemId !== product.itemId) {
      const CartRequest = isCartGraphQLEnabled
        ? promoCartItemsObj[bogoItemInCart.itemId]?.itemDetails?.id
        : buildUpdateCartRequest({
          product,
          promoItemModel: promoCartItemsObj[bogoItemInCart.itemId],
          desiredQuantity: 0,
          storeId,
          deliveryZip,
        });

      const unFreezeCart = freezeCart();
      const cartCallRequest = isCartGraphQLEnabled ? deleteCart(CartRequest) : updateCart(CartRequest?.CartRequest);
      const dataCheck = isCartGraphQLEnabled
        ? promoCartItemsObj[bogoItemInCart.itemId]?.itemDetails?.id : CartRequest?.CartRequest?.itemDetails?.length;
      const deletePromise = dataCheck ? cartCallRequest : Promise.resolve(CartModel);

      deletePromise
        .then((newCartModel) => {
          const promoCartItems = generatePromoCartItemsObj({
            itemModels: isCartGraphQLEnabled ? newCartModel?.items : newCartModel?.itemModels,
            allListProductsAndAnchor,
            isCartGraphQLEnabled,
            deliveryZip,
            storeId
          });

          const cartRequest = buildAddToCartRequest({
            desiredQuantity: 1,
            promoItemModel: promoCartItems[product.itemId],
            channel,
            product,
            storeId,
            applianceDeliveryStore,
            deliveryZip,
            isCartGraphQLEnabled
          });
          const fulfillmentOptionCount = calculateFulfillmentTypeOption(
            cartRequest?.cartRequest,
            cartRequest?.CartRequest
          );
          if (product.identifiers.productType === MA) {
            window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
              cartReqParams: fulfillmentOptionCount,
              cartOptions: buildAddToCartOverlayOptions(channel, {
                sharedSection: analyticsSharedSection,
                ...(isCartGraphQLEnabled && { filterItem: false })
              }),
            });
          } else if (fulfillmentOptionCount.length) {
            return addToCart(cartRequest);
          }

          return Promise.resolve();
        })
        .finally(() => {
          unFreezeCart();
        });
    } else {
      setBogoSelectedProduct(!selected ? { ...product, displayPosition } : null);
    }
  }, [
    isCartGraphQLEnabled,
    analyticsSharedSection,
    applianceDeliveryStore,
    bogoItemInCart,
    product,
    promoCartItemsObj,
    storeId,
    deliveryZip,
    updateCart,
    deleteCart,
    allListProductsAndAnchor,
    channel,
    addToCart,
    setBogoSelectedProduct,
    selected,
    displayPosition,
    CartModel,
    freezeCart
  ]);

  const onMultiSelect = useCallback(() => {
    const isItemInCart = !!promoCartItemsObj[itemId];
    const selectedProduct = allListProductsAndAnchor.get(itemId);
    const CartRequest = isCartGraphQLEnabled
      ? promoCartItemsObj[selectedProduct.itemId]?.itemDetails?.id
      : buildUpdateCartRequest({
        product: selectedProduct,
        promoItemModel: promoCartItemsObj[selectedProduct.itemId],
        desiredQuantity: 0,
        storeId,
        selectedFulfillment: FULFILLMENT_METHODS.APPLIANCE,
        deliveryZip
      });
    if (isItemInCart) {
      if (isCartGraphQLEnabled) {
        deleteCart(CartRequest);
      } else {
        updateCart(CartRequest.CartRequest);
      }

    } else if (!selectedItemsModel.some((selectedItem) => { return (selectedItem?.itemId === itemId); })) {
      setSelectedItemsModel([...selectedItemsModel, selectedProduct]);
    } else {
      setSelectedItemsModel(selectedItemsModel.filter((selectedItem) => selectedItem.itemId !== itemId));
    }
  },
  [
    isCartGraphQLEnabled,
    allListProductsAndAnchor,
    itemId,
    selectedItemsModel,
    setSelectedItemsModel,
    promoCartItemsObj,
    storeId,
    deliveryZip,
    updateCart,
    deleteCart
  ]);

  return useMemo(() => {
    const anchorIsAppliance = anchorItem?.identifiers?.productType === MA;

    let productCtaType = PRODUCT_CTA_TYPE.seeFullDetails;
    if (!isMultiSelect && (isMsb || isBmsm || isDollarThresholdBogo || isForwardsB1gy || isForwardsBxg1)) {
      productCtaType = PRODUCT_CTA_TYPE.addToCart;
    } else if (isMultiSelect || isForwardsBogo || (isBackwardsBogo && (!isAppliance || anchorIsAppliance))) {
      productCtaType = PRODUCT_CTA_TYPE.select;
    }

    return Object.freeze({
      inCart: promoCartItemIds?.includes(itemId),
      isAppliance,
      isSoldOut,
      product,
      productCtaType,
      selected,
      selectProduct: isMultiSelect ? onMultiSelect : onSelect
    });
  }, [
    anchorItem,
    isAppliance,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isBmsm,
    isDollarThresholdBogo,
    isForwardsBogo,
    isMsb,
    isMultiSelect,
    isSoldOut,
    itemId,
    onMultiSelect,
    onSelect,
    product,
    promoCartItemIds,
    selected,
  ]);
};
