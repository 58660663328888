import React, { useContext } from 'react';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { RecentOrders as RecentOrdersComponent } from '@thd-olt-component-react/recent-order-details';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useNavigate } from '@thd-olt-component-react/router';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { HomepageContext } from './context/HomepageContext';

export function RecentOrders({ id }) {
  const { isConsumerApp } = useContext(ExperienceContext);
  const { urlTmxId } = useContext(HomepageContext);
  const navigate = useNavigate();

  const data = {
    id,
    name: 'RecentOrderDetails',
    component: 'RecentOrderDetails',
    type: 'content',
    position: 1,
  };

  const onError = (error = {}) => {
    if (!isConsumerApp) return;

    const isSignInPath = window.location?.pathname?.includes('/auth/view/signin');
    const isUnauthorized = error?.graphQLErrors?.some((gqlError) => gqlError?.extensions['X-Http-Status-Code'] === 401);
    if (isUnauthorized && !isSignInPath) navigate('/auth/view/signin?redirect=/');
  };
  return (
    <ErrorBoundary name="recent-orders">
      <QueryProvider cachekey="recent-orders">
        <ImpressionProvider data={data}>
          <RecentOrdersComponent onError={onError} urlTmxId={urlTmxId} componentId={id} />
        </ImpressionProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

RecentOrders.propTypes = {
  id: string,
};

RecentOrders.defaultProps = {
  id: undefined,
};