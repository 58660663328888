import React from 'react';
import {
  func, arrayOf, string, bool, shape
} from 'prop-types';
import {
  Tile,
  TileContent,
  TileGroup,
  TileText
} from '@one-thd/sui-atomic-components';
import { Checkmark, Subtract } from '@one-thd/sui-icons';
import classNames from 'classnames';

const Aspect = ({
  aspect, sentiment, onClick, selected
}) => {
  const availableCls = classNames('sui-bg-primary sui-border-solid sui-rounded-base sui-text-primary hover:sui-outline '
      + 'hover:sui-outline-2 hover:-sui-outline-offset-2 focus-visible:sui-outline '
      + 'focus-visible:sui-outline-input-focus focus-visible:sui-outline-4 focus-visible:-sui-outline-offset-4', {
    'sui-p-[6px] sui-bg-subtle': selected,
    'sui-border-input-active focus-visible:sui-bg-subtle': selected,
    'sui-border-input-primary sui-border-1 sui-p-[7px] ': !selected,
    'hover:sui-outline-success-strong': !selected && sentiment === 'positive',
    'hover:sui-outline-warning-strong': !selected && sentiment === 'neutral',
    'sui-bg-success-subtle sui-border-2 sui-border-success-strong': selected && sentiment === 'positive',
    'sui-bg-warning-subtle sui-border-2 sui-border-warning-strong': selected && sentiment === 'neutral',
  });

  return (
    <Tile
      value={aspect}
      aria-label={aspect}
      onClick={onClick}
      className={availableCls}
      id="tnt_magic_apron_aspect"
    >
      <TileContent alignItems="center" grow disableShrink>
        <div className="sui-flex sui-flex-row sui-gap-2 sui-items-center">
          {sentiment === 'positive' && (
            <Checkmark size="small" color="success-strong" />
          )}
          {sentiment === 'neutral' && (
            <Subtract size="small" color="warning-strong" />
          )}
          <TileText>{aspect}</TileText>
        </div>
      </TileContent>
    </Tile>
  );
};

Aspect.propTypes = {
  aspect: string.isRequired,
  sentiment: string.isRequired,
  onClick: func.isRequired,
  selected: bool
};

Aspect.defaultProps = {
  selected: false
};

export const SummaryAspects = ({ aspects, onAspectClick, selectedAspect }) => {

  const onClick = (event, aspect) => {

    onAspectClick(event, aspect);
  };

  return (
    <TileGroup
      value={selectedAspect}
      aria-label="Aspects"
      className="sui-flex sui-flex-wrap sui-justify-start sui-gap-4"
    >
      {aspects.map(({ aspect, sentiment }, i) => {
        return (
          <Aspect
            aspect={aspect}
            sentiment={sentiment}
            onClick={onClick}
            selected={selectedAspect === aspect}
            key={i}
          />
        );
      })}
    </TileGroup>
  );
};

SummaryAspects.propTypes = {
  aspects: arrayOf(shape({
    aspect: string,
    sentiment: string
  })).isRequired,
  onAspectClick: func.isRequired,
  selectedAspect: string
};

SummaryAspects.defaultProps = {
  selectedAspect: null
};