import React from 'react';
import classnames from 'classnames';
import { Placeholder } from '@thd-olt-component-react/core-ui';

// Determines the # of pixels below the "fold" before starting hydration
export const SCROLL_BUFFER_DESKTOP = 1000;
export const SCROLL_BUFFER_MOBILE = 2000;

// Determines a delay for the hydrator in milliseconds
export const HOMEPAGE_DELAY_STANDARD = 250;

// Averages were taken of all card components to determine height for mobile/desktop
export const HOMEPAGE_PLACEHOLDER_DESKTOP = (
  <Placeholder type="rect" height="400px" width="100%" />
);
export const HOMEPAGE_PLACEHOLDER_MOBILE = (
  <Placeholder type="rect" height="360px" width="100%" />
);

// Section needs it's own placeholders to address its own CWV's
export const SECTION_PLACEHOLDER_DESKTOP = (
  <Placeholder type="rect" height="390px" width="100%" />
);
export const SECTION_PLACEHOLDER_MOBILE = (
  <Placeholder type="rect" height="225px" width="100%" />
);

export function getHydrationOptions(channel) {
  const PLACEHOLDER = channel === 'mobile'
    ? HOMEPAGE_PLACEHOLDER_MOBILE
    : HOMEPAGE_PLACEHOLDER_DESKTOP;
  const PLACEHOLDER_SECTION = channel === 'mobile'
    ? SECTION_PLACEHOLDER_MOBILE
    : SECTION_PLACEHOLDER_DESKTOP;
  const SCROLL_BUFFER = channel === 'mobile' ? SCROLL_BUFFER_MOBILE : SCROLL_BUFFER_DESKTOP;

  return {
    SCROLL_BUFFER,
    HOMEPAGE_DELAY_STANDARD,
    PLACEHOLDER,
    PLACEHOLDER_SECTION,
  };
}

export function generateLayoutComponentClasses(minWidthMedium, maxWidthMedium, minWidthLarge) {
  const maxWidthMediumSpan = classnames({
    'sui-col-span-1': maxWidthMedium === 1,
    'sui-col-span-2': maxWidthMedium === 2,
    'sui-col-span-3': maxWidthMedium === 3,
    'sui-col-span-4': maxWidthMedium === 4,
    'sui-col-span-5': maxWidthMedium === 5,
    'sui-col-span-6': maxWidthMedium === 6,
    'sui-col-span-7': maxWidthMedium === 7,
    'sui-col-span-8': maxWidthMedium === 8,
    'sui-col-span-9': maxWidthMedium === 9,
    'sui-col-span-10': maxWidthMedium === 10,
    'sui-col-span-11': maxWidthMedium === 11,
    'sui-col-span-12': maxWidthMedium === 12,
  });

  const minWidthMediumSpan = classnames({
    'md:sui-col-span-1': minWidthMedium === 1,
    'md:sui-col-span-2': minWidthMedium === 2,
    'md:sui-col-span-3': minWidthMedium === 3,
    'md:sui-col-span-4': minWidthMedium === 4,
    'md:sui-col-span-5': minWidthMedium === 5,
    'md:sui-col-span-6': minWidthMedium === 6,
    'md:sui-col-span-7': minWidthMedium === 7,
    'md:sui-col-span-8': minWidthMedium === 8,
    'md:sui-col-span-9': minWidthMedium === 9,
    'md:sui-col-span-10': minWidthMedium === 10,
    'md:sui-col-span-11': minWidthMedium === 11,
    'md:sui-col-span-12': minWidthMedium === 12,
  });

  const minWidthLargeSpan = classnames({
    'lg:sui-col-span-1': minWidthLarge === 1,
    'lg:sui-col-span-2': minWidthLarge === 2,
    'lg:sui-col-span-3': minWidthLarge === 3,
    'lg:sui-col-span-4': minWidthLarge === 4,
    'lg:sui-col-span-5': minWidthLarge === 5,
    'lg:sui-col-span-6': minWidthLarge === 6,
    'lg:sui-col-span-7': minWidthLarge === 7,
    'lg:sui-col-span-8': minWidthLarge === 8,
    'lg:sui-col-span-9': minWidthLarge === 9,
    'lg:sui-col-span-10': minWidthLarge === 10,
    'lg:sui-col-span-11': minWidthLarge === 11,
    'lg:sui-col-span-12': minWidthLarge === 12,
  });

  return `${maxWidthMediumSpan} ${minWidthMediumSpan} ${minWidthLargeSpan}`;
}