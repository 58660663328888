import React, { useEffect, useReducer, useRef } from 'react';
import { Button } from '@thd-olt-component-react/button';
import { Col, Row, Heading } from '@thd-olt-component-react/core-ui';
import { useConfigService } from '@thd-nucleus/experience-context';
import { CustomerImageCarousel } from './CustomerImageCarousel/CustomerImageCarousel.component';
import { Filter } from './Filter/Filter.component';
import { FirstReview } from './FirstReview/FirstReview.component';
import { Review } from './Review/Review.component';
import { ReviewPager } from './Paging/ReviewPager.component';
import { ReviewSentiments } from './Filter/components/ReviewSentiments/ReviewSentiments.component';
import { WriteAReview } from './WriteAReview/WriteAReview.component';
import { OverviewDesktop } from './Overview/OverviewDesktop';
import { Search, Sort } from './Filter/components';
import { PagerDisplayText } from './PagerDisplayText/PagerDisplayText';
import { useProductReviews } from './useProductReviews';
import { dataModel } from './ReviewDataModel';
import { initialState } from '../constants';
import { stateReducer } from '../reducer';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from './RatingsAndReviewsPropTypes';
import { RatingsAndReviewsPlaceholder } from './RatingsAndReviewsPlaceholder';
import { getProductReviews, onFilterChange } from '../helpers';
import './ratings-and-reviews.style.scss';
import { PresentationProvider } from '../context/PresentationProvider';
import { MagicApron } from './MagicApron/MagicApron';

export const RatingsAndReviewsDesktop = (props) => {
  const {
    apiKey = '', itemId, prosAndCons,
    category = 'product', seoPageNumber,
    disableOverlayOnClick, isUsingAccordion, pprPageReturn,
    searchAndSortRef, selectedReviewSummaryAspect, setSelectedAspect
  } = props;
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();
  const carouselRef = useRef();

  const magicApronEnabled = useConfigService('fs-prop:magicapron-reviewFormat');

  const onAspectClick = (__, aspect) => {
    setSelectedAspect(aspect);
    if (window) {
      searchAndSortRef.current.scrollIntoView();
    }

    dispatch({ type: 'search', value: aspect });
  };

  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ type: 'sort', value: 'photoreview' });
      dispatch({ type: 'pageLoad', value: seoPageNumber });
    }
  }, [seoPageNumber]);

  const {
    averageRating, FilterSelected, loading, reviewModels = [], statistics, totalReviews, totalReviewPages
  } = useProductReviews({ itemId, seoPageNumber, ...state });

  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
      if (carouselRef.current) {
        carouselRef.current.scrollIntoView({
          // do not use smooth. It'll cause the page to scroll to the bottom while it updates the results.
          behavior: 'auto'
        });
      }
    }

  }, [state.pageContext]);

  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;

  const hasProductData = averageRating || totalReviews;
  const sentimentChange = (sentiment) => dispatch({ type: 'sentiment', value: sentiment });
  if (loading && !persistedStatistics.current[itemId]) {
    return (
      <RatingsAndReviewsPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  let productReviews = getProductReviews({
    reviewModels, initialReviews, itemId, searchText, selected
  });
  const noFallbacks = !(hasProductData || productReviews || persistedStatistics.current[itemId]);
  if (noFallbacks) {
    return null;
  }
  const updatedTotalpage = selectedSentiment
    ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1
    : statistics?.totalPages || 1;
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[itemId]) {
    persistedStatistics.current[itemId] = statistics;
    initialReviews.current[itemId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;
  const noResults = state.searchText && reviewModels.length === 0 && !loading;

  if (pprPageReturn) {
    pprPageReturn(pageContext?.currentPage || seoPageNumber);
  }
  if (hasProductData && !(searchText || productReviews.length || selected.length)) {
    return (
      <div className="ratings-reviews" id="ratings-and-reviews" data-component="RatingsAndReviewsDesktop">
        <Heading title="Customer Reviews" underline className="overview-heading" />
        <OverviewDesktop apiKey={apiKey} reviewStatistics={{}} itemId={itemId} category={category} />
        <WriteAReview
          apiKey={apiKey}
          itemId={itemId}
          totalReviews={totalReviews}
          disableOverlayOnClick={disableOverlayOnClick}
        />
      </div>
    );
  }

  if (!(productReviews.length || persistedStatistics.current[itemId]?.length || searchText || selected.length)) {
    return (
      <FirstReview
        apiKey={apiKey}
        itemId={itemId}
        reviewStatistics={reviewStatistics}
        disableOverlayOnClick={disableOverlayOnClick}
      />
    );
  }

  return (
    <div className="ratings-reviews" id="ratings-and-reviews" data-component="RatingsAndReviewsDesktop">
      {!isUsingAccordion && <Heading title="Customer Reviews" underline className="overview-heading" />}
      <OverviewDesktop
        apiKey={apiKey}
        clickableFilters
        itemId={itemId}
        reviewStatistics={reviewStatistics}
        filterCheckboxes={filters}
        onFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch })}
        selectedSentiment={selectedSentiment}
        category={category}
        disableOverlayOnClick={disableOverlayOnClick}
      />
      {magicApronEnabled && (
        <div id="magic-apron_wrapper">
          <MagicApron
            itemId={itemId}
            selectedAspect={selectedReviewSummaryAspect}
            onAspectClick={onAspectClick}
          />
        </div>
      )}
      <div className="customer-image-carousel__wrapper" ref={carouselRef}>
        <CustomerImageCarousel itemId={itemId} />
      </div>
      {prosAndCons && (
        <ReviewSentiments
          itemId={itemId}
          selectedSentiment={selectedSentiment}
          onSentimentChange={sentimentChange}
        />
      )}

      <>
        <Row className="ratings-reviews__search-and-sort" id="search-and-sort">
          <Col
            xs={3}
            sm={3}
            md={3}
            lg={3}
            fallback={3}
            className="ratings-and-reviews__search-and-filter"
          >
            <Search
              onSearch={(searchTerm) => {
                if (searchTerm) {
                  dispatch({ type: 'search', value: searchTerm });
                  dispatch({ type: 'sort', value: 'photoreview' });
                }
              }}
              searchTerm={state.searchText}
              noResults={noResults}
              ref={searchAndSortRef}
            />
          </Col>
          <Col
            xs={9}
            sm={9}
            md={9}
            lg={9}
            fallback={9}
            className="ratings-and-reviews__search-and-filter"
          >
            <Filter
              onChange={(updatedFilters) => dispatch({ type: 'filter', value: updatedFilters })}
              ratingFilters={filters}
              reviewStatistics={reviewStatistics}
              selectedSentiment={selectedSentiment}
            />
          </Col>
        </Row>
        <Row className="ratings-reviews__sort-and-filter">
          <Col sm={3} md={3} lg={3} fallback={3}>
            <PagerDisplayText
              topPager
              pageContext={pageContext}
              loading={loading}
              statistics={statistics}
              showReviewsText
              seoPageNumber={seoPageNumber}
            />
          </Col>
          <Col xs={6} sm={7} md={7} lg={7} fallback={7} className="ratings-reviews__pills-wrapper">
            {selected.length > 0
              && (
                <>
                  {selected
                    .map((checkbox, checkboxIndex) => {
                      const pillText = checkbox.value === 'verified' ? 'Verified Purchases' : `${checkbox.value} Star`;
                      return (
                        <Button
                          key={checkboxIndex}
                          id={checkbox.id}
                          onClick={() => dispatch({ type: 'removeFilter', value: checkbox })}
                          filter
                        >
                          {selectedSentiment ? selectedSentiment.Feature : pillText}
                        </Button>
                      );
                    }
                    )}
                </>
              )}
            {
              SearchText
              && (
                <Button
                  key="search"
                  id="search"
                  onClick={() => dispatch({ type: 'search', value: null })}
                  filter
                >
                  {noResults && SearchText ? 'Clear Search Term' : SearchText}
                </Button>
              )
            }
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} fallback={2}>
            <Sort
              onChange={
                (newSort) => {
                  dispatch({ type: 'sort', value: newSort });
                  dispatch({ type: 'search', value: null });
                }
              }
              sortBy={sortBy}
            />
          </Col>
        </Row>
      </>
      <PresentationProvider useCondensedLayout={false} itemId={itemId}>
        {(productReviews).map((review, reviewIndex) => {
          return (
            <Review
              review={review}
              id={reviewIndex}
              key={`review-${reviewIndex}`}
              searchTerm={SearchText}
              apiKey={apiKey}
              category={category}
            />
          );
        })}
      </PresentationProvider>
      <Row>
        <Col xs="12" sm="12" md="12" className="pager-section">
          <div className="ratings-reviews__review-pager">
            <ReviewPager
              itemId={itemId}
              reviewsPageContext={pageContext}
              onPageChanged={(newPageContext) => {
                dispatch({ type: 'pageChange', value: newPageContext });
              }}
              category={category}
              totalReviewPages={totalReviewPages}
            />
          </div>
          <Row className="ratings-reviews__review-pager__summary">
            <PagerDisplayText
              pageContext={pageContext}
              statistics={statistics}
              loading={loading}
              showReviewsText
              seoPageNumber={seoPageNumber}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

RatingsAndReviewsDesktop.displayName = 'RatingsAndReviewsDesktop';

RatingsAndReviewsDesktop.propTypes = RatingsAndReviewsPropTypes;

RatingsAndReviewsDesktop.defaultProps = RatingsAndReviewsDefaultProps;

RatingsAndReviewsDesktop.dataModel = dataModel;
