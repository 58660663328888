import { useLazyDataModel } from '@thd-nucleus/data-sources';
import { cartInfoLiteDataModel } from '../models/GetCartDataModel';

export const useGetCart = () => {
  const [getCartItems, cartItemsResponse] = useLazyDataModel('getCart', {
    ssr: false,
    fetchPolicy: 'no-cache',
    dataModel: cartInfoLiteDataModel,
    variables: {}
  });

  return {
    getCartItems,
    cartItemsResponse,
  };
};
