import React, { useCallback, useContext, useRef } from 'react';
import { bool as boolType, string as stringType, number as numberType } from 'prop-types';
import {
  arrayOf,
  string,
  params,
  shape,
  useDataModel,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { GridView } from '@one-thd/sui-icons';
import { getCustomerData } from '../../utils/helpers';
import { CategoryCarousel } from '../category-carousel/CategoryCarousel';
import { FallbackCard } from '../../core/FallbackCard';

const TopCategories = (props) => {
  const {
    customerID, customerType, entryId, hideTitle, requestKey, showLoading, slidesPer, showFallback, hideControls
  } = props;

  const { isLocalized, storeId, storeZip: zipCode } = useStore();
  const { channel } = useContext(ExperienceContext) || {};
  const innerQueryPrerequisites = useRef({ storeId, zipCode });

  const isMounted = useCallback(() => {
    return innerQueryPrerequisites.current.mounted;
  }, [innerQueryPrerequisites.current.mounted]);

  const { mcvisID, isB2BCustomer } = getCustomerData();

  const custID = customerID || mcvisID;

  const isB2B = customerType === 'B2B' || isB2BCustomer;
  const customerTypeOptions = isB2B
    ? { apiName: 'categories_b2b', columnFamily: 'b2b' }
    : { apiName: 'categories', columnFamily: 'b2c' };

  const udsQueryOptions = {
    variables: {
      ...customerTypeOptions,
      appId: channel === 'mobile' ? 'mobileweb' : 'desktop',
      schema: 'categories',
      key: requestKey,
      storeId,
      tableName: 'category_that_may_interest_you',
      userId: custID || null,
    },
    skip: !isLocalized || storeId === '8119',
    ssr: false,
  };

  const { loading, data, error, variables } = useDataModel('uds', udsQueryOptions);

  if (data && isLocalized) {
    innerQueryPrerequisites.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true,
    };
  }

  if (error || (!data?.uds?.categories?.length && !loading)) {
    if (!showFallback) {
      return (
        <FallbackCard
          header="No Top Categories For You Right Now"
          subheader="Check back later to see what categories are relevant to you"
          componentName="TopCategories"
          icon={GridView}
        />
      );
    }
    return null;
  }

  return (
    <div
      className="sui-grid sui-grid-cols-1"
      id={customerTypeOptions?.apiName}
      data-type="container"
      data-component="TopCategories"
    >
      <meta data-prop="name" content={customerTypeOptions?.apiName} />
      <QueryProvider
        cacheKey="user-data-services-top-categories"
        mounted={isMounted}
        defaultVariables={{
          storeId: innerQueryPrerequisites.current.storeId,
          zipCode: innerQueryPrerequisites.current.zipCode,
        }}
      >
        <ImpressionProvider
          data={{
            id: entryId,
            name: 'TopCategories',
            component: 'TopCategories',
            type: 'product',
            position: '',
          }}
        >
          <CategoryCarousel
            loading={loading}
            categories={data?.uds?.categories}
            entryId={entryId}
            hideTitle={hideTitle}
            title={data?.uds?.metadata?.title}
            showLoading={showLoading}
            componentName="top-categories"
            breakpoints={{
              sm: {
                slidesPerView: slidesPer || 3,
                slidesPerGroup: slidesPer || 3
              },
              md: {
                slidesPerView: slidesPer || 3,
                slidesPerGroup: slidesPer || 3
              },
              lg: {
                slidesPerView: slidesPer || 6,
                slidesPerGroup: slidesPer || 6
              },
              xl: {
                slidesPerView: slidesPer || 6,
                slidesPerGroup: slidesPer || 6
              }
            }}
            placeholderSize={48}
            hideControls={hideControls}
          />
        </ImpressionProvider>
      </QueryProvider>
    </div>
  );
};

const wrappedCategory = {
  catImage: string(),
  catUrl: string(),
  id: string(),
  nValue: string(),
  rank: string(),
  strategy: string(),
  title: string(),
};

TopCategories.dataModel = {
  uds: params({
    apiName: string().isRequired(),
    appId: string(),
    columnFamily: string(),
    key: string().isRequired(),
    schema: string(),
    storeId: string(),
    tableName: string(),
    userId: string(),
  }).shape({
    categories: arrayOf(wrappedCategory),
    metadata: shape({
      apiName: string(),
      title: string(),
    }),
  }),
};

TopCategories.displayName = 'TopCategories';

TopCategories.propTypes = {
  // mcvisid
  customerID: stringType,
  customerType: stringType,
  entryId: stringType,
  hideTitle: boolType,
  requestKey: stringType.isRequired,
  showLoading: boolType,
  slidesPer: numberType,
  showFallback: boolType,
  hideControls: boolType
};

TopCategories.defaultProps = {
  customerID: null,
  customerType: 'B2C',
  entryId: '',
  hideTitle: false,
  showLoading: false,
  slidesPer: null,
  showFallback: false,
  hideControls: false
};

export { TopCategories };