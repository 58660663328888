import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSection,
  ProductPod,
  ProductImage,
  ProductRating,
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType,
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

export const VerticalRecsProductPodV7 = (props) => {
  const {
    itemId,
    storeId,
    position,
    preferredPriceFlag,
    parent,
    scheme,
    anchorProduct,
    strategy,
    brandTitleMaxLine,
    hideRating
  } = props;

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{
        parent, position, scheme, anchorProduct, strategy
      }}
      render={(pod) => (
        <PodSection>
          <div className="sui-grid sui-grid-cols-3 sui-gap-2">
            <div className="sui-grid-cols-1">
              <ProductImage
                itemId={pod.itemId}
                fetchPriority="high"
              />
            </div>
            <div className="sui-col-span-2">
              <PodSection noPadding columnAlign>
                <ProductHeader
                  brand="above"
                  itemId={pod.itemId}
                  brandTitleMaxLine={brandTitleMaxLine}
                  disableShopThisCollection
                />
                {!hideRating && <ProductRating itemId={pod.itemId} />}
                <Price
                  itemId={pod.itemId}
                  large={false}
                  storeId={storeId}
                  displayEachUom={false}
                  hideSavingsText={!preferredPriceFlag}
                  showPreferredPricingBadge
                />
              </PodSection>
            </div>
          </div>
        </PodSection>
      )}
    />
  );
};

VerticalRecsProductPodV7.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductRating,
  Price,
  ProductHeader,
);

VerticalRecsProductPodV7.propTypes = {
  itemId: stringType.isRequired,
  storeId: stringType,
  position: numType,
  parent: stringType,
  preferredPriceFlag: boolType,
  scheme: stringType.isRequired,
  anchorProduct: stringType,
  strategy: stringType,
  brandTitleMaxLine: numType,
  hideRating: boolType,
};

VerticalRecsProductPodV7.defaultProps = {
  storeId: '',
  position: null,
  brandTitleMaxLine: 4,
  preferredPriceFlag: false,
  parent: '',
  strategy: 'project-based',
  anchorProduct: '',
  hideRating: false,
};

VerticalRecsProductPodV7.displayName = 'VerticalRecsProductPodV7';