import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { Drawer } from '@one-thd/sui-atomic-components';
import { useStoreId } from '@thd-nucleus/experience-context';
import { PromoDrawerModelProvider } from '../../contexts/PromoDrawerModelProvider';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { usePromoCart } from '../../hooks/usePromoCart';
import { PromoDrawerDataModel } from '../../models/PromoDrawerDataModel';
import { QuickviewOverviewDrawerDataModel } from '../../models/QuickviewOverviewDrawerDataModel';
import { QuickviewReviewsDrawerDataModel } from '../../models/QuickviewReviewsDrawerDataModel';
import { QuickviewSpecsDrawerDataModel } from '../../models/QuickviewSpecsDrawerDataModel';
import { QuickviewDrawerDataModel } from '../../models/QuickviewDrawerDataModel';
import { DRAWER_TYPES } from '../../utils/constants';
import { PromoList1Drawer } from './subcomponents/PromoList1Drawer';
import { PromoList2Drawer } from './subcomponents/PromoList2Drawer';
import { PromoSummaryDrawer } from './subcomponents/PromoSummaryDrawer';
import { QuickviewDrawer } from './subcomponents/QuickviewDrawer';
import { QuickviewOverviewDrawer } from './subcomponents/QuickviewOverviewDrawer';
import { QuickviewReviewsDrawer } from './subcomponents/QuickviewReviewsDrawer';
import { QuickviewSpecsDrawer } from './subcomponents/QuickviewSpecsDrawer';
import { WarningDrawer } from './subcomponents/WarningDrawer';

const INITIAL_DRAWER_STACK = Object.freeze([DRAWER_TYPES.PROMO_LIST_1_DRAWER]);
const SUMMARY_DRAWER_STACK = Object.freeze([DRAWER_TYPES.PROMO_LIST_1_DRAWER, DRAWER_TYPES.PROMO_SUMMARY_DRAWER]);
export const DRAWER_MAP = Object.freeze({
  [DRAWER_TYPES.PROMO_LIST_1_DRAWER]: PromoList1Drawer,
  [DRAWER_TYPES.PROMO_LIST_2_DRAWER]: PromoList2Drawer,
  [DRAWER_TYPES.PROMO_SUMMARY_DRAWER]: PromoSummaryDrawer,
  [DRAWER_TYPES.QUICKVIEW_DRAWER]: QuickviewDrawer,
  [DRAWER_TYPES.QUICKVIEW_OVERVIEW_DRAWER]: QuickviewOverviewDrawer,
  [DRAWER_TYPES.QUICKVIEW_REVIEWS_DRAWER]: QuickviewReviewsDrawer,
  [DRAWER_TYPES.QUICKVIEW_SPECS_DRAWER]: QuickviewSpecsDrawer,
  [DRAWER_TYPES.WARNING_DRAWER]: WarningDrawer
});

export const CatalogHoistingWrapper = ({ children }) => <>{children}</>;
CatalogHoistingWrapper.propTypes = { children: PropTypes.node };
CatalogHoistingWrapper.defaultProps = { children: null };
CatalogHoistingWrapper.dataModel = extend(
  QuickviewOverviewDrawerDataModel,
  QuickviewReviewsDrawerDataModel,
  QuickviewSpecsDrawerDataModel,
  QuickviewDrawerDataModel
);

export const PromoDrawer = () => {
  const storeId = useStoreId();
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    showWarning,
    isSummaryDrawerOpen,
    setIsSummaryDrawerOpen,
  } = usePromoPresentation();
  const drawerContainerRef = useRef();
  const firstWarning = useRef(true);

  const [drawerViewStack, dispatch] = useReducer((state, action) => {
    // reset the drawer scroll position regardless of which action is done
    if (drawerContainerRef.current) {
      drawerContainerRef.current.scrollTop = 0;
    }

    switch (action.type) {
    case 'back':
      return Object.freeze(state.slice(0, -1));
    case 'forward':
      return Object.freeze([...state, action.newDrawerView]);

    default:
      return isSummaryDrawerOpen ? SUMMARY_DRAWER_STACK : INITIAL_DRAWER_STACK;
    }
  }, [INITIAL_DRAWER_STACK, SUMMARY_DRAWER_STACK]);

  const {
    selectedItemsModel,
    setSelectedItemsModel,
  } = usePromoCart();

  const drawerView = drawerViewStack[drawerViewStack.length - 1];

  const onBack = useCallback(() => {
    dispatch({ type: 'back' });
  }, [dispatch]);

  const onDrawerViewChange = useCallback((newDrawerView) => {
    dispatch({ newDrawerView, type: 'forward' });
  }, [dispatch]);

  const onClose = useCallback(() => {
    if (showWarning && firstWarning.current) {
      onDrawerViewChange(DRAWER_TYPES.WARNING_DRAWER);
      firstWarning.current = false;
    } else {
      setIsDrawerOpen(false);
      setIsSummaryDrawerOpen(false);
      firstWarning.current = true;
      if (selectedItemsModel.length) {
        setSelectedItemsModel([]);
      }
    }
  }, [
    onDrawerViewChange,
    setIsDrawerOpen,
    setIsSummaryDrawerOpen,
    showWarning,
    selectedItemsModel,
    setSelectedItemsModel
  ]);

  useEffect(() => {
    if (isDrawerOpen) {
      dispatch({ type: 'reset' });
    }
  }, [isDrawerOpen]);

  const shouldUseCatalog = [
    DRAWER_TYPES.QUICKVIEW_DRAWER,
    DRAWER_TYPES.QUICKVIEW_OVERVIEW_DRAWER,
    DRAWER_TYPES.QUICKVIEW_REVIEWS_DRAWER,
    DRAWER_TYPES.QUICKVIEW_SPECS_DRAWER,
  ].includes(drawerView);

  const drawerContentsJSX = useMemo(() => {
    const DrawerTag = DRAWER_MAP[drawerView];

    return (
      <DrawerTag
        onBack={onBack}
        onClose={onClose}
        onDrawerViewChange={onDrawerViewChange}
      />
    );
  }, [drawerView, onBack, onClose, onDrawerViewChange]);

  return (
    <Drawer
      DrawerContainerProps={{ ref: drawerContainerRef }}
      open={isDrawerOpen}
      onClose={onClose}
    >
      <PromoDrawerModelProvider drawerView={drawerView} drawerViewStack={drawerViewStack} open={isDrawerOpen}>
        <QueryProvider
          cacheKey="promotion-products-quickview-details"
          defaultVariables={{
            storeId
          }}
        >
          <CatalogHoistingWrapper>
            {shouldUseCatalog ? drawerContentsJSX : null}
          </CatalogHoistingWrapper>
        </QueryProvider>
        {!shouldUseCatalog ? drawerContentsJSX : null}
      </PromoDrawerModelProvider>
    </Drawer>
  );
};

PromoDrawer.displayName = 'PromoDrawer';

PromoDrawer.dataModel = PromoDrawerDataModel;
