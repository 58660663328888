import {
  bool, node, number, string, func, shape
} from 'prop-types';

export const RatingsAndReviewsPropTypes = {
  /*
    This is a bad practice.
    APIs should not be called directly.
    There is a story to refactor this to use a graphql mutation:
    https://www.pivotaltracker.com/story/show/173363061
  */
  apiKey: string,
  children: node,
  collectionId: string,
  itemId: string,
  prosAndCons: bool,
  forceCondensedLayout: bool,
  target: string,
  seoPageNumber: number,
  disableOverlayOnClick: bool,
  isUsingAccordion: bool,
  pprPageReturn: func,
  searchAndSortRef: shape({ current: shape({ scrollIntoView: func }) }),
  selectedReviewSummaryAspect: string,
  onAspectClick: func
};

export const RatingsAndReviewsDefaultProps = {
  apiKey: 'x5w9jA8tWVGcqRhujrHTvjRwQfH8MkFc',
  children: null,
  collectionId: null,
  prosAndCons: false,
  forceCondensedLayout: false,
  target: '',
  pageNumber: 1,
  itemId: null,
  storeId: null,
  seoPageNumber: null,
  seoStoreLink: null,
  disableOverlayOnClick: false,
  isUsingAccordion: false,
  pprPageReturn: undefined,
  searchAndSortRef: null,
  selectedReviewSummaryAspect: null,
  onAspectClick: () => {}
};

export const StoreRatingsAndReviewsPropTypes = {
  apiKey: string,
  children: node,
  collectionId: string,
  storeId: string,
  prosAndCons: bool,
  forceCondensedLayout: bool,
  target: string,
  seoPageNumber: number,
  seoStoreLink: string
};
