/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardTitle,
  CardBody,
  CardActions,
  Typography,
  Link,
  Button,
  Alert,
  ProgressBar,
  IconButton
} from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { isCommercial } from '../../utils/general';
import { CARD_NAME, ANALYTICS_ACCOUNT_SNAPSHOT, CARD_IMAGE } from '../../utils/constants';

const makeAPayment = 'payments';
const viewStatements = 'statements';
const manageInvoices = 'invoices';
const viewSpecialOffers = 'specialoffers';

const AccountSnapshot = ({
  redirectToCiti,
  accountNumber,
  balance,
  minimumDue,
  dueDate,
  currentBalance,
  availableCredit,
  creditLimit,
  accountType,
  totalOpenInvoiceCount,
  redirectToThdMyAccount,
}) => {
  useEffect(() => {
    impressionEvent(ANALYTICS_ACCOUNT_SNAPSHOT[accountType]);
  }, []);

  // Progress bar percentage calculated
  const currentBalanceValue = parseFloat(currentBalance.replace(',', ''));
  const creditLimitValue = parseFloat(creditLimit.replace(',', ''));
  const availableCreditValue = parseFloat(availableCredit.replace(',', ''));

  const percentCreditUsed = (currentBalanceValue / creditLimitValue) * 100;

  // if Credit Limit exceeded
  const creditLimitReached = currentBalance && creditLimit
    ? parseFloat(currentBalance.replace(',', ''))
    >= parseFloat(creditLimit.replace(',', ''))
    : false;

  // if PaymentOverdue or PaymentDueSoon
  let CurrentDate = new Date();
  let GivenDueDate = new Date(dueDate);

  const paymentOverdue = (CurrentDate > GivenDueDate) && minimumDue > 0;

  let startDate = new Date(dueDate);
  startDate.setDate(startDate.getDate() - 10);

  const paymentDueSoon = CurrentDate > startDate && CurrentDate < GivenDueDate;
  const [isClose, setIsClose] = useState(true);

  const currentBalanceNotUsed = () => {
    return (
      <>
        <CardBody>
          <Alert status="info">
            You haven’t used current balance on your {CARD_NAME[accountType]}.
          </Alert>
          <div className="sui-flex sui-place-content-center sui-bg-subtle sui-px-10 sui-py-6">
            <div>
              <Typography variant="body-base" color="primary" align="center">Available Credit</Typography>
              <div className="sui-flex sui-align-top sui-gap-1px">
                <Typography
                  variant="h3"
                  component="h3"
                  weight="display"
                  color="primary"
                  height="none"
                >
                  ${availableCredit}
                </Typography>
              </div>
            </div>
          </div>
        </CardBody>
        <CardActions>
          {accountType === 'PLCN_HOMEDEPOT' && (
            <Button
              variant="secondary"
              fullWidth
              onClick={() => {
                clickEvent('plcc-account snapshot view special offers');
                redirectToCiti(viewSpecialOffers, accountType);
              }}
            >
              View Special Offers
            </Button>
          )}
          {accountType !== 'PLCN_HOMEDEPOT' && (
            <Button
              variant="secondary"
              fullWidth
              onClick={() => {
                clickEvent('plcc-account snapshot view statements');
                redirectToCiti(viewStatements, accountType);
              }}
            >
              View Previous Statements
            </Button>
          )}
        </CardActions>
      </>
    );
  };

  const currentBalanceUI = () => {
    return (
      <div className="sui-pt-2 sui-pb-2">
        <div className="sui-flex sui-justify-between sui-gap-1">
          <div className="sui-flex sui-align-top sui-gap-1px sui-pb-2">
            <Typography
              variant="h4"
              component="h3"
              weight="bold"
              color="info"
              height="none"
            >
              ${currentBalanceValue.toFixed(2)}
            </Typography>
          </div>
          <div className="sui-flex sui-align-top sui-gap-1px sui-pb-2">
            <Typography
              variant="h4"
              component="h3"
              weight="bold"
              color="primary"
              height="none"
            >
              ${availableCreditValue.toFixed(2)}
            </Typography>
          </div>
        </div>
        <ProgressBar
          value={percentCreditUsed}
          startLabel={<Typography variant="body-base" color="info">Current Balance</Typography>}
          endLabel={<Typography variant="body-base" color="primary">Available Credit</Typography>}
          labelPlacement="bottom"
          size="large"
        />
      </div>
    );
  };

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
      data-component="AccountSnapshot"
    >
      <CardTitle
        disableTypography
        header={(
          <div className="sui-flex sui-justify-between">
            <Typography variant="h3">Home Depot Credit Cards</Typography>
            <IconButton
              icon={ArrowForward}
              aria-label={isCommercial(accountType)
                ? 'Manage Accounts'
                : 'View Account Details'}
              onClick={() => {
                clickEvent('plcc-account snapshot view details');
                redirectToThdMyAccount();
              }}
              iconSize="regular"
            />
          </div>
        )}
      />
      <div>
        <div className="sui-flex">
          <img
            src={CARD_IMAGE[accountType]}
            alt="Credit Card Icon"
            className="sui-mr-3 sui-self-start"
            height="50"
            width="80"
          />
          <div>
            <Typography weight="bold">{CARD_NAME[accountType]}</Typography>
            <Typography>**** {accountNumber || '---'}</Typography>
          </div>
        </div>
      </div>
      <div className="empty:sui-hidden">
        {currentBalanceValue === 0 ? null : (
          <div>
            {creditLimitReached && (
              <Alert status="warning">
                <span>Credit Limit Reached</span>
                <br />
                You&apos;ve reached your credit limit of ${creditLimit}
              </Alert>
            )}

            {paymentOverdue && (
              <Alert status="warning">Payment Overdue</Alert>
            )}

            {paymentDueSoon && isClose && (
              <Alert status="info" onClose={() => setIsClose(!isClose)}>Payment Due Soon</Alert>
            )}
          </div>
        )}

        {currentBalanceValue > 0 && (currentBalanceValue < creditLimitValue) && currentBalanceUI()}
      </div>
      {currentBalanceValue === 0 ? currentBalanceNotUsed() : (
        <>
          <CardBody>
            <div className="sui-flex sui-flex-col sui-gap-2">
              <ul className="list-none sui-flex sui-flex-col sui-gap-2">
                <li className="sui-flex sui-justify-between sui-gap-1">
                  <Typography variant="body-base" weight="bold">Statement Balance</Typography>
                  <Typography variant="body-base">${balance}</Typography>
                </li>
                {accountType !== 'PLNP_HOMEDEPOT' && (
                  <>
                    <li className="sui-flex sui-justify-between sui-gap-1">
                      <Typography variant="body-base" weight="bold">Minimum Payment Due</Typography>
                      <Typography variant="body-base">${minimumDue}</Typography>
                    </li>
                    <li className="sui-flex sui-justify-between sui-gap-1">
                      <Typography variant="body-base" weight="bold">Payment Due Date</Typography>
                      <Typography variant="body-base">{dueDate}</Typography>
                    </li>
                  </>
                )}
                {accountType === 'PLNP_HOMEDEPOT' && (
                  <li className="sui-flex sui-justify-between sui-gap-1">
                    <Typography variant="body-base" weight="bold">Open Invoices</Typography>
                    <Typography variant="body-base">{totalOpenInvoiceCount}</Typography>
                  </li>
                )}
              </ul>
            </div>
          </CardBody>
          <CardActions>
            {accountType !== 'PLNP_HOMEDEPOT' && (
              <Button
                variant="secondary"
                fullWidth
                data-testid="makeAPayment"
                onClick={() => {
                  clickEvent('plcc-account snapshot make payment');
                  redirectToCiti(makeAPayment, accountType);
                }}
              >
                Make A Payment
              </Button>
            )}
            {accountType === 'PLNP_HOMEDEPOT' && (
              <Button
                variant="secondary"
                fullWidth
                data-testid="manageInvoices"
                onClick={() => {
                  redirectToCiti(manageInvoices, accountType);
                }}
              >
                Manage Invoices
              </Button>
            )}
          </CardActions>
        </>
      )}
    </CardContent>
  );
};

AccountSnapshot.propTypes = {
  redirectToCiti: PropTypes.func.isRequired,
  accountNumber: PropTypes.string,
  balance: PropTypes.string,
  minimumDue: PropTypes.string,
  dueDate: PropTypes.string,
  currentBalance: PropTypes.string.isRequired,
  availableCredit: PropTypes.string.isRequired,
  creditLimit: PropTypes.string.isRequired,
  accountType: PropTypes.string,
  totalOpenInvoiceCount: PropTypes.string,
  redirectToThdMyAccount: PropTypes.func.isRequired,
};
AccountSnapshot.defaultProps = {
  accountNumber: null,
  accountType: 'PLCN_HOMEDEPOT',
  totalOpenInvoiceCount: '0',
  balance: null,
  minimumDue: null,
  dueDate: '',
};

export default AccountSnapshot;
